<template>
  <div class="login-container">
    <img class="logo-ticket-prop" alt="" :src="logoTicketProp">
    <b-row>
      <b-col>
        <img class="mb-4" width="250" :src="logo" alt="">
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-alert variant="warning" :show="showAlert">Identifiant incorrect</b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="d-flex justify-content-center">
        <b-form-group
            id="input-identifiant-label"
            label="Identifiant"
            label-for="input-identifiant"
            label-class="form-label"
            label-align="center"
        >
          <b-form-input
              id="input-identifiant"
              v-model="login.login"
              type="text"
              placeholder=""
              class="ticket-form-control login-form-control"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="d-flex justify-content-center">
        <b-form-group
            id="input-password-label"
            label="Mot de passe"
            label-for="input-password"
            label-class="form-label"
            label-align="center"
        >
          <b-form-input
              id="input-password"
              v-model="login.password"
              type="password"
              placeholder=""
              class="ticket-form-control login-form-control"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="d-flex flex-column justify-content-center">
        <b-button class="mb-2" :disabled="loading" @click="doLogin('normal')" size="sm" type="primary">Connexion</b-button>
        <!-- <b-button v-if="(premiereSyncOk && isInStandaloneMode) || !isInStandaloneMode" class="mb-2" :disabled="loading" @click="doLogin('normal')" size="sm" type="primary">Connexion</b-button> -->
        <!-- <b-button v-if="!premiereSyncOk && isInStandaloneMode && isOnline && this.internetOk" class="mb-2" :disabled="loading" @click="firstSync" size="sm" type="primary">Première connexion</b-button> -->
      </b-col>
    </b-row>

    <b-row v-if="isInStandaloneMode">
        <b-col cols="2">
            <font-awesome-icon v-if="isOnline && this.internetOk" icon="wifi" />
            <font-awesome-icon v-if="!isOnline || !this.internetOk" icon="times-circle" />
        </b-col>
        <b-col class="text-right">
            <span>Etat synchro : </span>
            <font-awesome-icon v-if="$store.state.syncStatus === 'ok'" icon="check" />
            <font-awesome-icon v-if="$store.state.syncStatus === 'error'" icon="circle-exclamation" />
            <font-awesome-icon v-if="$store.state.syncStatus === 'in_progress'" icon="sync" spin />
        </b-col>
    </b-row>
    <b-row class="mt-2" v-if="!premiereSyncOk && isInStandaloneMode && loading">
      <b-col>
        <b-progress :value="currentDownload" :max="maxDownloadFile" show-progress animated></b-progress>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import dbMixin from "@/dbMixin";

const logo = require('@/assets/identifiant.png')
const logoTicketProp = require('@/assets/logo-ticket-prop.png')
const sha1 = require('js-sha1');
import {DB} from '@/db/db'
import Cookies from 'js-cookie'

import axios from "axios";
import mixin from "@/mixin";
export default {
  name: "Login",
  mixins: [mixin, dbMixin],
  data() {
    return {
      interval: null,
      logo,
      logoTicketProp,
      showAlert: false,
      loading: false,
      login: {
        login: null,
        password: null
      }
    }
  },
  methods: {
    firstSync() {
      this.loading = true;
      this.loadImageFromAPI().then(() => {
        this.loading = false;
        Cookies.set('premiereSyncOk', true)
        console.log('premiereSyncOk')
        this.doLogin()
      })
    },
    doLogin(type) {
      this.showAlert = false;
      this.loading = true;
      let isMobile = Math.min(window.screen.width, window.screen.height) < 768 || navigator.userAgent.indexOf("Mobi") > -1;
      if (this.isInStandaloneMode) {
        let passwordhashed = sha1(this.login.password)
        DB.find({
          selector: {
            identifiant: {$eq: this.login.login},
            password: {$eq: passwordhashed}
          },
        }).then(res => {
          if (res.docs.length === 1) {
            // login OK
            Cookies.set('login', res.docs[0].identifiant);
            if (res.docs[0].groupname === 'PAME') {
              Cookies.set('isPAME', true);
            }

            this.$store.dispatch('setCurrentUser', res.docs[0])

            if (res.docs[0].groupname === 'PRESTATAIRE_HORS_RATP') {
              if (isMobile) {
                this.$router.push({name: 'mobile-station-list'})
              } else {
                this.$router.push({name: 'tickets-list'})
              }
            } else {
              this.$router.push({name: 'tickets-list'})
            }
          } else {
            this.showAlert = true;
            this.loading = false;
            console.warn('login failed')
          }
        }).catch(err => {
          console.warn('Error fetch user in DB', err)
        })
      } else {
        axios.post(`${process.env.VUE_APP_API_BASE_URL}/login`, {
          identifiant: this.login.login,
          password: this.login.password
        })
            .then((res) => {
              console.debug('login success')
              Cookies.set('login', res.data.identifiant);

              if (res.data.groupname === 'PAME') {
                Cookies.set('isPAME', true);
              }

              this.$store.dispatch('setCurrentUser', res.data)

              if (res.data.groupname === 'PRESTATAIRE_HORS_RATP') {
                if (isMobile) {
                  this.$router.push({name: 'mobile-station-list'})
                } else {
                  this.$router.push({name: 'tickets-list'})
                }
              } else {
                this.$router.push({name: 'tickets-list'})
              }
            }).catch(err => {
              this.showAlert = true;
              this.loading = false;
              console.warn('login failed')
        })
      }
    }
  },
  mounted() {
    if (this.isInStandaloneMode) {
      this.interval = setInterval(async () => {
        this.internetOk = await this.checkOnlineStatus()
      }, 3000)
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
}
</script>

<style scoped>
.login-container {
  border-radius: 8px;
  background-color: var(--blanc);
  padding: 30px;
  position: relative;
}

.login-form-control {
  width: 150px;
}

.logo-ticket-prop {
  position: absolute;
  top: -167px;
  left: -30px;
}
</style>
