<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-tabs justified v-model="tabIndex">
          <b-tab title="DÉTAIL DU TICKET" active-nav-item-class="custom-active-nav-item" active>
            <!-- Block Identifiant -->
            <b-row v-if="isEdition && ($store.getters.userIsAdmin || $store.getters.userIsAgentProprete || $store.getters.userIsAgentStation || $store.getters.userIsCoordinateurIdfm || $store.getters.userIsAgentTechnique || $store.getters.userIsManager || $store.getters.userIsAgentPame || $store.getters.userIsPrestataireRatp || $store.getters.userIsPrestataireHorsRatp)">
              <b-col>
                <div class="form-card mt-3 form-card-title">
                    <b-row v-b-toggle:identifiant>
                      <b-col>
                        <h1>identifiant</h1>
                      </b-col>
                    </b-row>
                </div>
                  <b-collapse id="identifiant" :visible="collapseOpen">
                    <div class="form-card">
                      <b-row>
                        <b-col>
                          <p class="form-label">Numéro référence</p>
                          <p>{{ form.numeroReference }}</p>
                        </b-col>
                        <b-col>
                          <p class="form-label">Date de creation</p>
                          <p>{{ form.dateCreation }}</p>
                        </b-col>
                        <b-col>
                          <p class="form-label">Chargé d'affaire</p>
                          <p>{{ form.chargeAffaireMatricul }}</p>
                        </b-col>
                        <!--<b-col>
                          <p class="form-label">Chargé d'affaire Nom du groupe</p>
                          <p>{{ form.chargeAffaireGroupName }}</p>
                        </b-col>
                        <b-col>
                          <p class="form-label">Utilisateur Nom du groupe</p>
                          <p>{{ $store.state.currentUser.groupname }}</p>
                        </b-col>-->
                        <b-col>
                          <p class="form-label">Origin</p>
                          <p>{{ form.origin }}</p>
                        </b-col>
                        <b-col>
                          <b-form-group
                              id="input-status-label"
                              label="Statut"
                              label-for="input-status"
                              label-class="form-label"
                          >
                            <b-form-select :disabled="$store.getters.userIsPrestataireRatp || $store.getters.userIsPrestataireHorsRatp || (isEdition && (form.chargeAffaireGroupName != $store.state.currentUser.groupname && $store.getters.userIsAgentStation))" id="input-status" class="ticket-custom-select" v-model="form.status" :options="status"></b-form-select>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>
                  </b-collapse>
              </b-col>
            </b-row>
            <!-- FIN Block Identifiant -->

            <!-- Block Détail ticket -->
            <b-row v-if="$store.getters.userIsAdmin || $store.getters.userIsAgentProprete || $store.getters.userIsAgentStation || $store.getters.userIsCoordinateurIdfm || $store.getters.userIsAgentTechnique || $store.getters.userIsManager || $store.getters.userIsAgentPame || $store.getters.userIsPrestataireRatp || $store.getters.userIsPrestataireHorsRatp">
              <b-col>
                <div class="form-card mt-3 form-card-title">
                  <b-row v-b-toggle:detail-ticket>
                    <b-col>
                      <h1>Détail ticket</h1>
                    </b-col>
                  </b-row>
                </div>
                <b-collapse id="detail-ticket" :visible="collapseOpen">
                  <div class="form-card">
                    <b-row>
                      <b-col>
                        <b-row>
                          <b-col>
                            <b-form-row>
                              <b-col>
                                <b-form-group
                                    id="input-station-label"
                                    label="Station"
                                    label-for="input-station"
                                    label-class="form-label"
                                >
                                  <b-form-select :disabled="$store.getters.userIsPrestataireRatp || $store.getters.userIsPrestataireHorsRatp || (isEdition && (form.chargeAffaireGroupName != $store.state.currentUser.groupname && $store.getters.userIsAgentStation))" id="input-station" class="ticket-custom-select" v-model="form.station" :options="stations"></b-form-select>
                                </b-form-group>
                              </b-col>

                              <b-col>
                                <b-form-group
                                    id="input-nature-label"
                                    label="Nature demande"
                                    label-for="input-nature"
                                    label-class="form-label"
                                >
                                  <b-form-select :disabled="$store.getters.userIsPrestataireRatp || $store.getters.userIsPrestataireHorsRatp || (isEdition && (form.chargeAffaireGroupName != $store.state.currentUser.groupname && $store.getters.userIsAgentStation))" id="input-nature" class="ticket-custom-select" v-model="form.nature" :options="natureDemande"></b-form-select>
                                </b-form-group>
                              </b-col>
                            </b-form-row>

                            <b-form-row>
                              <b-col>
                                <b-form-group
                                    id="input-itemidfm-label"
                                    label="Item IDFM"
                                    label-for="input-itemidfm"
                                    label-class="form-label"
                                >
                                  <b-form-select :disabled="$store.getters.userIsPrestataireRatp || $store.getters.userIsPrestataireHorsRatp || (isEdition && (form.chargeAffaireGroupName != $store.state.currentUser.groupname && $store.getters.userIsAgentStation))" id="input-itemidfm" class="ticket-custom-select" v-model="form.itemidfm" :options="itemsIdfm"></b-form-select>
                                </b-form-group>
                              </b-col>
                            </b-form-row>

                            <b-form-row>
                              <b-col>
                                <p class="form-label">Penalité : <span class="text-color-grey">{{ getPenalite }}</span></p>
                              </b-col>

                              <b-col>
                                <p class="form-label">Catégorie IDFM : <span class="text-color-grey">{{ getCategoryIdfm }}</span></p>
                              </b-col>
                            </b-form-row>

                            <b-form-row>
                              <b-col>
                                <b-form-group
                                    id="input-intervenant-label"
                                    label="Intervenant"
                                    label-for="input-intervenant"
                                    label-class="form-label"
                                >
                                  <b-form-select :disabled="$store.getters.userIsPrestataireRatp || $store.getters.userIsPrestataireHorsRatp || (isEdition && (form.chargeAffaireGroupName != $store.state.currentUser.groupname && $store.getters.userIsAgentStation))" id="input-intervenant" class="ticket-custom-select" v-model="form.intervenant" :options="intervenants"></b-form-select>
                                </b-form-group>
                              </b-col>
                            </b-form-row>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </div>
                </b-collapse>
              </b-col>
            </b-row>
            <!-- FIN Block Détail ticket -->

            <!-- Block SAGAI -->
            <b-row v-if="$store.getters.userIsAdmin || $store.getters.userIsAgentProprete || $store.getters.userIsAgentStation || $store.getters.userIsCoordinateurIdfm || $store.getters.userIsAgentTechnique || $store.getters.userIsManager || $store.getters.userIsAgentPame || $store.getters.userIsPrestataireRatp || $store.getters.userIsPrestataireHorsRatp">
              <b-col>
                <div class="form-card mt-3 form-card-title">
                  <b-row v-b-toggle:sagai>
                    <b-col>
                      <h1>SAGAI</h1>
                    </b-col>
                  </b-row>
                </div>
                <b-collapse id="sagai" :visible="collapseOpen">
                  <div class="form-card">
                    <b-row>
                      <b-col>
                        <b-row>
                          <b-col>
                            <b-form-row>
                              <b-col>
                                <b-form-group
                                    id="input-nsagai-label"
                                    label="N°SAGAI"
                                    label-for="input-nsagai"
                                    label-class="form-label"
                                >
                                  <b-form-input
                                      id="input-nsagai"
                                      v-model="form.sagai"
                                      type="text"
                                      placeholder=""
                                      class="ticket-form-control"
                                      :disabled="$store.getters.userIsPrestataireRatp || $store.getters.userIsPrestataireHorsRatp || (isEdition && (form.chargeAffaireGroupName != $store.state.currentUser.groupname && $store.getters.userIsAgentStation))"
                                  ></b-form-input>
                                </b-form-group>
                              </b-col>

                              <b-col>
                                <b-form-group
                                    id="input-ot-label"
                                    label="N°OT"
                                    label-for="input-ot"
                                    label-class="form-label"
                                >
                                  <b-form-input
                                      id="input-ot"
                                      v-model="form.ot"
                                      type="text"
                                      placeholder=""
                                      class="ticket-form-control"
                                      :disabled="$store.getters.userIsPrestataireRatp || $store.getters.userIsPrestataireHorsRatp || (isEdition && (form.chargeAffaireGroupName != $store.state.currentUser.groupname && $store.getters.userIsAgentStation))"
                                  ></b-form-input>
                                </b-form-group>
                              </b-col>
                            </b-form-row>

                            <b-form-row>
                              <b-col>
                                <b-form-group
                                    id="input-datesagai-label"
                                    label="Date SAGAI"
                                    label-for="input-datesagai"
                                    label-class="form-label"
                                >
                                  <b-form-datepicker :disabled="$store.getters.userIsPrestataireRatp || $store.getters.userIsPrestataireHorsRatp || (isEdition && (form.chargeAffaireGroupName != $store.state.currentUser.groupname && $store.getters.userIsAgentStation))" id="input-datesagai" class="ticket-form-control" placeholder="Sélectionner une date" locale="fr" v-model="form.dateSagai"></b-form-datepicker>
                                </b-form-group>
                              </b-col>

                              <b-col>
                                <b-form-group
                                    id="input-codemirelieu-label"
                                    label="Code Mire lieu"
                                    label-for="input-codemirelieu"
                                    label-class="form-label"
                                >
                                  <b-form-input
                                      id="input-codemirelieu"
                                      v-model="form.codemirelieu"
                                      type="text"
                                      placeholder=""
                                      class="ticket-form-control"
                                      :disabled="$store.getters.userIsPrestataireRatp || $store.getters.userIsPrestataireHorsRatp || (isEdition && (form.chargeAffaireGroupName != $store.state.currentUser.groupname && $store.getters.userIsAgentStation))"
                                  ></b-form-input>
                                </b-form-group>
                              </b-col>
                            </b-form-row>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </div>
                </b-collapse>
              </b-col>
            </b-row>
            <!-- FIN Block SAGAI -->

          </b-tab>

          <b-tab title="INTERVENTION">

            <!-- block Intervention validation -->
            <b-row v-if="$store.getters.userIsAdmin || $store.getters.userIsAgentProprete || $store.getters.userIsAgentStation || $store.getters.userIsCoordinateurIdfm || $store.getters.userIsAgentTechnique || $store.getters.userIsManager || $store.getters.userIsAgentPame || $store.getters.userIsPrestataireRatp || $store.getters.userIsPrestataireHorsRatp">
              <b-col>
                <div :class="['form-card', 'mt-3', 'form-card-title', {'form-card-title-checked-green': form.validationInterventionValide}]">
                  <b-row>
                    <b-col v-b-toggle:intervention-validation>
                      <h1>Validation intervention</h1>
                    </b-col>
                    <b-col lg="4" sm="4" xl="4" md="4" class="d-flex justify-content-end">
                      <b-form-checkbox class="ticket-checkbox-label" :disabled="$store.getters.userIsPrestataireRatp || $store.getters.userIsPrestataireHorsRatp || (isEdition && (form.chargeAffaireGroupName != $store.state.currentUser.groupname && $store.getters.userIsAgentStation))" @change="setUpValidationIntervention" v-model="form.validationInterventionValide" size="lg" switch>
                        Validée
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                </div>
                <b-collapse id="intervention-validation" :visible="collapseOpen">
                  <div class="form-card">
                    <b-row>
                      <b-col>
                        <b-form-row>
                          <b-col>
                            <b-form-checkbox class="ticket-checkbox-label" :disabled="$store.getters.userIsPrestataireHorsRatp || (isEdition && (form.chargeAffaireGroupName != $store.state.currentUser.groupname && $store.getters.userIsAgentStation))" v-model="form.validationInterventionPrestationHorsForfait" size="lg" switch>
                              Prestation hors forfait
                            </b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-group
                                id="input-internvetion-validation-agent-label"
                                label="Agent"
                                label-for="input-internvetion-validation-agent"
                                label-class="form-label"
                            >
                              {{ form.validationInterventionAgent }}
                            </b-form-group>
                          </b-col>

                          <b-col>
                            <b-form-group
                                id="input-internvetion-validation-date-visa-label"
                                label="Date visa"
                                label-for="input-internvetion-validation-date-visa"
                                label-class="form-label"
                            >
                              {{ formatValidationInterventionDateVisa }}
                            </b-form-group>
                          </b-col>

                        </b-form-row>
                      </b-col>
                    </b-row>
                  </div>
                </b-collapse>
              </b-col>
            </b-row>
            <!-- FIN block Intervention validation -->

            <!-- block Intervention -->
            <b-row v-if="$store.getters.userIsAdmin || $store.getters.userIsAgentProprete || $store.getters.userIsAgentStation || $store.getters.userIsCoordinateurIdfm || $store.getters.userIsAgentTechnique || $store.getters.userIsManager || $store.getters.userIsPrestataireRatp || $store.getters.userIsPrestataireHorsRatp" class="mt-3 mb-3">
              <b-col>
                <div class="form-card form-card-title">
                  <b-row v-b-toggle:intervention>
                    <b-col>
                      <h1>Intervention</h1>
                    </b-col>
                  </b-row>
                </div>
                <b-collapse id="intervention" :visible="collapseOpen">
                  <div :key="`intervention${index}-container`" class="form-card mb-5" v-for="(intervention, index) in form.interventions">
                    <b-row>
                      <b-col>
                        <b-row>
                          <b-col>
                            <b-form-row>
                              <b-col>
                                <b-form-group
                                    :id="`input-localisation-label-${index}`"
                                    label="Localisation"
                                    :label-for="`input-localisation-${index}`"
                                    label-class="form-label"
                                >
                                  <b-form-input
                                      :id="`input-localisation-${index}`"
                                      v-model="form.interventions[index].interventionLocalisation"
                                      type="text"
                                      placeholder=""
                                      class="ticket-form-control"
                                      :disabled="$store.getters.userIsPrestataireRatp || $store.getters.userIsPrestataireHorsRatp || (isEdition && (form.chargeAffaireGroupName != $store.state.currentUser.groupname && $store.getters.userIsAgentStation))"
                                  ></b-form-input>
                                </b-form-group>
                              </b-col>
                            </b-form-row>
                            <b-form-row>
                              <b-col>
                                <b-form-group
                                    :id="`input-detail-label-${index}`"
                                    label="Détail"
                                    :label-for="`input-detail-${index}`"
                                    label-class="form-label"
                                >
                                  <b-form-textarea
                                      :id="`input-detail-${index}`"
                                      v-model="form.interventions[index].interventionDetail"
                                      placeholder="Detail..."
                                      rows="3"
                                      max-rows="6"
                                      class="ticket-form-control"
                                      :disabled="$store.getters.userIsPrestataireRatp || $store.getters.userIsPrestataireHorsRatp || (isEdition && (form.chargeAffaireGroupName != $store.state.currentUser.groupname && $store.getters.userIsAgentStation))"
                                  ></b-form-textarea>
                                </b-form-group>
                              </b-col>
                            </b-form-row>
                          </b-col>
                        </b-row>

                        <b-row v-if="form.validationInterventionPrestationHorsForfait">
                          <b-col>
                            <h1>Prestation Hors Forfait</h1>
                          </b-col>
                        </b-row>

                        <b-row v-if="form.validationInterventionPrestationHorsForfait">
                          <b-col>
                            <b-form-row>
                              <b-col>
                                <b-form-row>
                                  <b-col>
                                    <b-form-group
                                        :id="`input-det-label-${index}`"
                                        label="DET"
                                        :label-for="`input-det-${index}`"
                                        label-class="form-label"
                                    >
                                      <b-form-input
                                          :id="`input-det-${index}`"
                                          v-model="form.interventions[index].prestationDet"
                                          type="text"
                                          placeholder=""
                                          class="ticket-form-control"
                                          :disabled="$store.getters.userIsPrestataireRatp || $store.getters.userIsPrestataireHorsRatp || (isEdition && (form.chargeAffaireGroupName != $store.state.currentUser.groupname && $store.getters.userIsAgentStation))"
                                      ></b-form-input>
                                    </b-form-group>
                                  </b-col>

                                  <b-col>
                                    <b-form-group
                                        :id="`input-prestationhf-label-${index}`"
                                        label="Prestation HF"
                                        :label-for="`input-prestationhf-${index}`"
                                        label-class="form-label"
                                    >
                                      <b-form-select :disabled="$store.getters.userIsPrestataireRatp || $store.getters.userIsPrestataireHorsRatp || (isEdition && (form.chargeAffaireGroupName != $store.state.currentUser.groupname && $store.getters.userIsAgentStation))" @change="getDevisFinancement" :id="`input-prestationhf-label-${index}`" class="ticket-custom-select" v-model="form.interventions[index].prestationhf" :options="prestationshf"></b-form-select>
                                    </b-form-group>
                                  </b-col>
                                </b-form-row>
                              </b-col>
                            </b-form-row>
                          </b-col>
                        </b-row>

                        <b-row v-if="form.validationInterventionPrestationHorsForfait">
                          <b-col>
                            <b-form-row>
                              <b-col>
                                <b-form-group
                                    :id="`input-surfacem2-label-${index}`"
                                    label="Surface (m2)"
                                    :label-for="`input-surfacem2-${index}`"
                                    label-class="form-label"
                                >
                                  <b-form-input
                                      :id="`input-surfacem2-${index}`"
                                      v-model="form.interventions[index].prestationSurfacem2"
                                      type="text"
                                      placeholder=""
                                      class="ticket-form-control"
                                      :disabled="$store.getters.userIsPrestataireRatp || $store.getters.userIsPrestataireHorsRatp || (isEdition && (form.chargeAffaireGroupName != $store.state.currentUser.groupname && $store.getters.userIsAgentStation))"
                                  ></b-form-input>
                                </b-form-group>
                              </b-col>

                              <b-col>
                                <b-form-group
                                    :id="`input-hauteurm-label-${index}`"
                                    label="Hauteur (m)"
                                    :label-for="`input-hauteurm-${index}`"
                                    label-class="form-label"
                                >
                                  <b-form-input
                                      :id="`input-hauteurm-${index}`"
                                      v-model="form.interventions[index].prestationHauteurm"
                                      type="text"
                                      placeholder=""
                                      class="ticket-form-control"
                                      :disabled="$store.getters.userIsPrestataireRatp || $store.getters.userIsPrestataireHorsRatp || (isEdition && (form.chargeAffaireGroupName != $store.state.currentUser.groupname && $store.getters.userIsAgentStation))"
                                  ></b-form-input>
                                </b-form-group>
                              </b-col>
                            </b-form-row>
                          </b-col>
                        </b-row>
                      </b-col>

                      <b-col>
                        <b-row>
                          <b-col>
                            <h1>Photo de l'invervention</h1>
                          </b-col>
                        </b-row>

                        <b-row>
                          <b-col>
                            <p class="form-label">Avant intervention</p>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <img :key="forceRender" width="120" @click="takePictureIntervention(index, 'photo1_pre_intervention')" class="img-fluid" :src="form.interventions[index].photo1_pre_intervention_base64 ? form.interventions[index].photo1_pre_intervention_base64 : imgPlaceholder" alt="">
                            <b-form-file :disabled="$store.getters.userIsPrestataireRatp || $store.getters.userIsPrestataireHorsRatp || (isEdition && (form.chargeAffaireGroupName != $store.state.currentUser.groupname && $store.getters.userIsAgentStation))" @input="uploadImgIntervention(index, 'photo1_pre_intervention')" :ref="`photo1_pre_intervention${index}`" v-show="false" v-model="form.interventions[index].photo1_pre_intervention" class="mt-3" accept="image/*" :capture="true" plain></b-form-file>
                            <font-awesome-icon v-if="form.interventions[index].photo1_pre_intervention_base64" icon="eye" @click="showFullScreenPicture(form.interventions[index].photo1_pre_intervention_base64)" />
                          </b-col>
                          <b-col>
                            <img :key="forceRender" width="120" @click="takePictureIntervention(index, 'photo2_pre_intervention')" class="img-fluid" :src="form.interventions[index].photo2_pre_intervention_base64 ? form.interventions[index].photo2_pre_intervention_base64 : imgPlaceholder" alt="">
                            <b-form-file :disabled="$store.getters.userIsPrestataireRatp || $store.getters.userIsPrestataireHorsRatp || (isEdition && (form.chargeAffaireGroupName != $store.state.currentUser.groupname && $store.getters.userIsAgentStation))" @input="uploadImgIntervention(index, 'photo2_pre_intervention')" :ref="`photo2_pre_intervention${index}`" v-show="false" v-model="form.interventions[index].photo2_pre_intervention" class="mt-3" accept="image/*" :capture="true" plain></b-form-file>
                            <font-awesome-icon v-if="form.interventions[index].photo2_pre_intervention_base64" icon="eye" @click="showFullScreenPicture(form.interventions[index].photo2_pre_intervention_base64)" />
                          </b-col>
                        </b-row>

                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col v-if="(index > 0) && ($store.getters.userIsAdmin || $store.getters.userIsAgentProprete || $store.getters.userIsAgentStation || $store.getters.userIsCoordinateurIdfm || $store.getters.userIsAgentTechnique || $store.getters.userIsManager || $store.getters.userIsAgentPame)" class="d-flex justify-content-start">
                        <b-button size="sm" variant="danger" @click="deleteIntervention(index)" >
                          <font-awesome-icon class="icon" size="2x" icon="xmark"  />
                        </b-button>
                      </b-col>
                      <b-col v-if="(form.interventions.length === index +1) && ($store.getters.userIsAdmin || $store.getters.userIsAgentProprete || $store.getters.userIsAgentStation || $store.getters.userIsCoordinateurIdfm || $store.getters.userIsAgentTechnique || $store.getters.userIsManager || $store.getters.userIsAgentPame )" class="d-flex justify-content-end">
                        <b-button :disabled="(form.interventions.length === 4) || (isEdition && (form.chargeAffaireGroupName != $store.state.currentUser.groupname && $store.getters.userIsAgentStation))" @click="addIntervention" class="btn-add-intervention">
                          <font-awesome-icon class="icon" icon="plus"  /> Ajouter une nouvelle intervention
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>
                </b-collapse>
              </b-col>
            </b-row>
            <!-- FIN block Intervention -->
          </b-tab>

          <b-tab title="DEVIS ET VALIDATION HF">
            <!-- block Devis -->
            <b-row v-if="form.validationInterventionPrestationHorsForfait && ($store.getters.userIsAdmin || $store.getters.userIsCoordinateurIdfm || $store.getters.userIsAgentTechnique || $store.getters.userIsManager)">
              <b-col>
                <div class="form-card mt-3 form-card-title">
                  <b-row v-b-toggle:devis>
                    <b-col>
                      <h1>Devis</h1>
                    </b-col>
                  </b-row>
                </div>
                <b-collapse id="devis" :visible="collapseOpen">
                  <div class="form-card">
                    <b-row>
                      <b-col>
                        <b-form-row>
                          <b-col>
                            <b-form-group
                                id="input-financement-label"
                                label="Financement"
                                label-for="input-financement"
                                label-class="form-label"
                            >
                              <b-form-input
                                  id="input-financement"
                                  v-model="form.devisFinancement"
                                  type="text"
                                  placeholder=""
                                  class="ticket-form-control"
                                  :disabled="$store.getters.userIsAgentProprete"
                              ></b-form-input>
                            </b-form-group>
                          </b-col>

                          <b-col>
                            <b-form-group
                                id="input-datenftdemande-label"
                                label="Date NFT demandée"
                                label-for="input-datenftdemande"
                                label-class="form-label"
                            >
                              <b-form-datepicker :disabled="$store.getters.userIsAgentProprete" id="input-datenftdemand" placeholder="Sélectionner une date" locale="fr" v-model="form.devisDateNftDemande"></b-form-datepicker>
                            </b-form-group>
                          </b-col>
                        </b-form-row>

                        <b-form-row>
                          <b-col>
                            <b-form-group
                                id="input-referencedevis-label"
                                label="Référence devis"
                                label-for="input-referencedevis"
                                label-class="form-label"
                            >
                              <b-form-input
                                  id="input-referencedevis"
                                  v-model="form.referenceDevis"
                                  type="text"
                                  placeholder=""
                                  class="ticket-form-control"
                                  :disabled="$store.getters.userIsAgentProprete"
                              ></b-form-input>
                            </b-form-group>
                          </b-col>

                          <b-col>
                            <b-form-group
                                id="input-datedevis-label"
                                label="Date NFT devis"
                                label-for="input-datedevis"
                                label-class="form-label"
                            >
                              <b-form-datepicker :disabled="$store.getters.userIsAgentProprete" id="input-datedevis" placeholder="Sélectionner une date" locale="fr" v-model="form.dateDevis"></b-form-datepicker>
                            </b-form-group>
                          </b-col>

                          <b-col>
                            <b-form-group
                                id="input-nomemetteurdevis-label"
                                label="Nom émetteur devis"
                                label-for="input-nomemetteurdevis"
                                label-class="form-label"
                            >
                              <b-form-input
                                  id="input-nomemetteurdevis"
                                  v-model="form.nomEmetteurDevis"
                                  type="text"
                                  placeholder=""
                                  class="ticket-form-control"
                                  :disabled="$store.getters.userIsAgentProprete"
                              ></b-form-input>
                            </b-form-group>
                          </b-col>
                        </b-form-row>

                        <b-form-row>
                          <b-col>
                            <b-form-group
                                id="input-montantdevis-label"
                                label="Montant devis"
                                label-for="input-montantdevis"
                                label-class="form-label"
                            >
                              <b-input-group append="€">
                                <b-form-input
                                    id="input-montantdevis"
                                    v-model="form.montantDevis"
                                    type="text"
                                    class="ticket-form-control"
                                    placeholder=""
                                    :disabled="$store.getters.userIsAgentProprete"
                                />
                              </b-input-group>
                            </b-form-group>
                          </b-col>

                          <b-col>
                            <b-form-group
                                id="input-dateprevusnftdevis-label"
                                label="Date prévue NFT devis"
                                label-for="input-dateprevusnftdevis"
                                label-class="form-label"
                            >
                              <b-form-datepicker :disabled="$store.getters.userIsAgentProprete" id="input-dateprevusnftdevis"  placeholder="Sélectionner une date" locale="fr" v-model="form.datePrevueNftDevis"></b-form-datepicker>
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                      </b-col>
                    </b-row>
                  </div>
                </b-collapse>
              </b-col>
            </b-row>
            <!-- FIN block Devis -->

            <!-- block Validation Manager Financement -->
            <b-row v-if="form.validationInterventionPrestationHorsForfait && ($store.getters.userIsAdmin || $store.getters.userIsAgentProprete || $store.getters.userIsCoordinateurIdfm || $store.getters.userIsAgentTechnique || $store.getters.userIsManager)">
              <b-col>
                <div :class="['form-card', 'mt-3', 'form-card-title', {'form-card-title-checked-green': form.validationFinancementValide}]">
                  <b-row>
                    <b-col v-b-toggle:validation-manager>
                      <h1>Validation Manager Financement</h1>
                    </b-col>
                    <b-col cols="4" class="d-flex justify-content-end">
                      <b-form-checkbox class="ticket-checkbox-label" :disabled="$store.getters.userIsAgentProprete || $store.getters.userIsCoordinateurIdfm || $store.getters.userIsAgentTechnique" @change="setUpValidationManagerFinancement" v-model="form.validationFinancementValide" size="lg" switch>
                        Validée
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                </div>
                <b-collapse id="validation-manager" :visible="collapseOpen">
                  <div class="form-card">
                    <b-row>
                      <b-col>
                        <b-form-row>
                          <b-col>
                            <b-form-group
                                id="input-manager-label"
                                label="Manager"
                                label-for="input-manager"
                                label-class="form-label"
                            >
                              {{ form.manager }}
                            </b-form-group>
                          </b-col>

                          <b-col>
                            <b-form-group
                                id="input-datevisamanager-label"
                                label="Date visa manager"
                                label-for="input-datevisamanager"
                                label-class="form-label"
                            >
                              {{ formatDateVisaManager }}
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                      </b-col>
                    </b-row>
                  </div>
                </b-collapse>
              </b-col>
            </b-row>
            <!-- FIN block Validation Manager Financement -->

            <b-row>
              <b-col>
                <div class="form-card mt-3" v-if="!form.validationInterventionPrestationHorsForfait">
                  Ce ticket dispose de prestations incluses dans le forfait : Il n’est besoin ni de devis ni de validation financière
                </div>
              </b-col>
            </b-row>

          </b-tab>

          <b-tab title="CONFORMITÉ ET CLÔTURE">
            <!-- Block Retour NFT -->
            <b-row v-if="$store.getters.userIsAdmin || $store.getters.userIsAgentProprete || $store.getters.userIsAgentStation || $store.getters.userIsCoordinateurIdfm || $store.getters.userIsAgentTechnique || $store.getters.userIsManager || $store.getters.userIsAgentPame || $store.getters.userIsPrestataireRatp || $store.getters.userIsPrestataireHorsRatp">
              <b-col>
                <div :class="['form-card', 'mt-3', 'form-card-title', {'form-card-title-checked-green': form.visiteClotureRealise}]">
                  <b-row>
                    <b-col v-b-toggle:retour-nft>
                      <h1>Retour NFT</h1>
                    </b-col>
                    <b-col cols="4" class="d-flex justify-content-end">
                      <b-form-checkbox :disabled="$store.getters.userIsPrestataireRatp || $store.getters.userIsPrestataireHorsRatp || (isEdition && (form.chargeAffaireGroupName != $store.state.currentUser.groupname && $store.getters.userIsAgentStation))" class="ticket-checkbox-label" @change="setUpVisiteNft"  v-model="form.visiteClotureRealise" switch size="lg">Visite réalisée</b-form-checkbox>
                    </b-col>
                  </b-row>
                </div>
                <b-collapse id="retour-nft" :visible="collapseOpen">
                  <div class="form-card">
                    <b-row>
                      <b-col>
                        <b-form-row>
                          <b-col>
                            <b-form-group
                                id="input-visitecloturedatevisiteeffectue-label"
                                label="Date visite effectuée"
                                label-for="input-visitecloturedatevisiteeffectue"
                                label-class="form-label"
                            >
                              {{ formatVisiteClotureDateVisiteEffectue }}
                            </b-form-group>
                          </b-col>

                          <b-col>
                            <b-form-group
                                id="input-visiteclotureagent-label"
                                label="Agent"
                                label-for="input-visiteclotureagent"
                                label-class="form-label"
                            >
                             {{ form.visiteClotureAgent }}
                            </b-form-group>
                          </b-col>

                          <b-col>
                            <b-form-group
                                id="input-visitecloturecommentaire-label"
                                label="Commentaire visite préalable"
                                label-for="input-visitecloturecommentaire"
                                label-class="form-label"
                            >
                              <b-form-textarea
                                  id="input-visitecloturecommentaire"
                                  v-model="form.visiteClotureCommentaire"
                                  placeholder="Commentaire..."
                                  rows="3"
                                  max-rows="6"
                                  class="ticket-form-control"
                                  :disabled="$store.getters.userIsPrestataireRatp || $store.getters.userIsPrestataireHorsRatp || (isEdition && (form.chargeAffaireGroupName != $store.state.currentUser.groupname && $store.getters.userIsAgentStation))"
                              ></b-form-textarea>
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col :key="`intervention${index}-photo-apres`" v-for="(intervention, index) in this.form.interventions">
                        <b-row>
                          <b-col>
                            <span class="form-label">Photo intervention {{ index + 1}}</span>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <img width="120" @click="takePictureIntervention(index, 'photo1_post_intervention')" class="img-fluid" :src="form.interventions[index].photo1_post_intervention_base64 ? form.interventions[index].photo1_post_intervention_base64 : imgPlaceholder" alt="">
                            <b-form-file :disabled="$store.getters.userIsPrestataireRatp || $store.getters.userIsPrestataireHorsRatp || (isEdition && (form.chargeAffaireGroupName != $store.state.currentUser.groupname && $store.getters.userIsAgentStation))" @input="uploadImgIntervention(index, 'photo1_post_intervention')" :ref="`photo1_post_intervention${index}`" v-show="false" v-model="form.interventions[index].photo1_post_intervention" class="mt-3" accept="image/*" :capture="true" plain></b-form-file>
                          </b-col>
                          <b-col>
                            <img width="120" @click="takePictureIntervention(index, 'photo2_post_intervention')" class="img-fluid" :src="form.interventions[index].photo2_post_intervention_base64 ? form.interventions[index].photo2_post_intervention_base64 : imgPlaceholder" alt="">
                            <b-form-file :disabled="$store.getters.userIsPrestataireRatp || $store.getters.userIsPrestataireHorsRatp || (isEdition && (form.chargeAffaireGroupName != $store.state.currentUser.groupname && $store.getters.userIsAgentStation))" @input="uploadImgIntervention(index, 'photo2_post_intervention')" :ref="`photo2_post_intervention${index}`" v-show="false" v-model="form.interventions[index].photo2_post_intervention" class="mt-3" accept="image/*" :capture="true" plain></b-form-file>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col>
                        <b-form-row>
                          <b-col lg="3" sm="3" xl="3" md="3">
                            <b-form-group
                                id="input-nft-label"
                                label="Code NFT"
                                label-for="input-nft"
                                label-class="form-label"
                            >
                              <b-form-datepicker :disabled="$store.getters.userIsPrestataireRatp || $store.getters.userIsPrestataireHorsRatp || (isEdition && (form.chargeAffaireGroupName != $store.state.currentUser.groupname && $store.getters.userIsAgentStation))" :date-format-options="{ year: '2-digit', month: 'numeric', day: 'numeric' }" local="fr" id="input-nft" class="ticket-form-control" placeholder="" locale="fr" v-model="form.dateNft"></b-form-datepicker>
                            </b-form-group>
                          </b-col>

                          <b-col lg="3" sm="3" xl="3" md="3">
                            <b-form-group
                                id="input-agent-label"
                                label="Agent"
                                label-for="input-agent"
                                label-class="form-label"
                            >
                              <b-form-input
                                  id="input-agent"
                                  v-model="form.agentNft"
                                  type="text"
                                  placeholder=""
                                  class="ticket-form-control"
                                  :disabled="$store.getters.userIsPrestataireRatp || $store.getters.userIsPrestataireHorsRatp || (isEdition && (form.chargeAffaireGroupName != $store.state.currentUser.groupname && $store.getters.userIsAgentStation))"
                              ></b-form-input>
                            </b-form-group>
                          </b-col>

                          <b-col lg="6" sm="6" xl="6" md="6">
                            <b-form-group
                                id="input-commentaire-label"
                                label="Commentaire"
                                label-for="input-commentaire"
                                label-class="form-label"
                            >
                              <b-form-textarea
                                  id="input-commentaire"
                                  v-model="form.commentaireNft"
                                  placeholder="Commentaire..."
                                  rows="3"
                                  max-rows="6"
                                  class="ticket-form-control"
                                  :disabled="$store.getters.userIsPrestataireRatp || $store.getters.userIsPrestataireHorsRatp || (isEdition && (form.chargeAffaireGroupName != $store.state.currentUser.groupname && $store.getters.userIsAgentStation))"
                              ></b-form-textarea>
                            </b-form-group>
                          </b-col>

                        </b-form-row>
                      </b-col>
                    </b-row>

                  </div>
                </b-collapse>
              </b-col>
            </b-row>
            <!-- FIN block retour NFT -->

            <!-- Block Résultat conformité -->
            <b-row v-if="$store.getters.userIsAdmin || $store.getters.userIsAgentProprete || $store.getters.userIsAgentStation || $store.getters.userIsCoordinateurIdfm || $store.getters.userIsAgentTechnique || $store.getters.userIsManager || $store.getters.userIsAgentPame || $store.getters.userIsPrestataireRatp || $store.getters.userIsPrestataireHorsRatp">
              <b-col>
                <div :class="['form-card', 'mt-3', 'form-card-title', {'form-card-title-checked-green': form.visiteClotureConforme}, {'form-card-title-checked-grey': form.abandonValide}]">
                <b-row>
                    <b-col v-b-toggle:cloture-conformite>
                      <h1>Cloture et conformité</h1>
                    </b-col>
                    <b-col cols="4" class="d-flex justify-content-end">
                      <b-form-checkbox class="ticket-checkbox-label mr-3" :disabled="$store.getters.userIsPrestataireRatp || $store.getters.userIsPrestataireHorsRatp || (isEdition && (form.chargeAffaireGroupName != $store.state.currentUser.groupname && $store.getters.userIsAgentStation))" @change="setUpConforme"  v-model="form.visiteClotureConforme" switch size="lg">Conforme</b-form-checkbox>
                      <b-form-checkbox class="ticket-checkbox-label" :disabled="$store.getters.userIsPrestataireRatp || $store.getters.userIsPrestataireHorsRatp || (isEdition && (form.chargeAffaireGroupName != $store.state.currentUser.groupname && $store.getters.userIsAgentStation))" @change="setUpAbandon" v-model="form.abandonValide" size="lg" switch>
                        Abandon
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                </div>
                <b-collapse id="cloture-conformite" :visible="collapseOpen">
                  <div class="form-card">
                    <b-row>
                      <b-col>
                        <b-form-row>
                          <b-col>
                            <b-form-group
                                id="input-resultatconformitedatecloturelabel"
                                label="Date clôture"
                                label-for="input-resultatconformitedatecloturelabel"
                                label-class="form-label"
                            >
                              {{ formatResultatConformiteDateCloture }}
                            </b-form-group>
                          </b-col>

                          <b-col>
                            <b-form-group
                                id="input-resultatconformiteagentvalidationcontrole-label"
                                label="Agent validation contrôle"
                                label-for="input-resultatconformiteagentvalidationcontrole"
                                label-class="form-label"
                            >
                             {{ form.resultatConformiteAgentValidationControle }}
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group
                                id="input-commentairecloture-label"
                                label="Commentaire cloture"
                                label-for="input-commentairecloture"
                                label-class="form-label"
                            >
                              <b-form-textarea
                                  id="input-commentairecloture"
                                  v-model="form.commentaireCloture"
                                  placeholder="Commentaire..."
                                  rows="3"
                                  max-rows="6"
                                  class="ticket-form-control"
                                  :disabled="$store.getters.userIsPrestataireRatp || $store.getters.userIsPrestataireHorsRatp || (isEdition && (form.chargeAffaireGroupName != $store.state.currentUser.groupname && $store.getters.userIsAgentStation))"
                              ></b-form-textarea>
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group
                                id="input-resultatconformitedateabandon-label"
                                label="Date abandon"
                                label-for="input-resultatconformitedateabandon"
                                label-class="form-label"
                            >
                              {{ formatResultatConformiteDateAbandon }}
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                      </b-col>
                    </b-row>
                  </div>
                </b-collapse>
              </b-col>
            </b-row>
            <!-- FIN block Résultat conformité -->
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="form-card d-flex justify-content-end mt-5">
          <b-button @click="$router.push({name: 'tickets-list'})" class="mr-3">Annuler</b-button>
          <b-button @click="nextTab">{{ isFinalStep ? (isEdition ? 'Mettre à jour' : 'Enregistrer') : 'Suivant'}} <font-awesome-icon :icon="isFinalStep ? 'floppy-disk' : 'arrow-right'" /></b-button>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {getCustomfieldBySlug, DB} from '@/db/db'
const itemIDFM = require('@/db/item_idfm.json');
import mixin from '@/mixin'
import { v4 as uuidv4 } from 'uuid';
import moment from "moment";
import axios from 'axios';
import imgPlaceholder from '@/assets/place-holder.png'

export default {
  name: "TicketForm",
  mixins: [mixin],
  data() {
    return {
      imgPlaceholder,

      forceRender: 0,
      tabIndex: 0,
      mode: 'edition',
      ticketId: null,
      form: {
        id: null,
        revision: null,
        numeroReference: this.ticketId ?? '',
        dateCreation: moment().format('DD/MM/YYYY'),
        chargeAffaireMatricul: this.$store.getters.getCurrentIdentifiantUser,
        chargeAffaireGroupName: '',
        origin: null,
        status: 'Ouvert',

        station: '',
        nature: '',
        itemidfm: '',
        penalite: null,
        intervenant: null,
        sagai: null,
        ot: null,
        codemirelieu: null,
        dateSagai: null,
        dateNft: null,
        agentNft: null,
        commentaireNft: null,

        validationInterventionValide: null,
        validationInterventionPrestationHorsForfait: null,
        validationInterventionAgent: null,
        validationInterventionDateVisa: null,

        interventions: [
          {
            id: null,
            interventionLocalisation: null,
            interventionDetail: null,
            prestationDet: null,
            prestationhf: null,
            prestationSurfacem2: null,
            prestationHauteurm: null,
            photo1_pre_intervention: null,
            photo2_pre_intervention: null,
            photo1_post_intervention: null,
            photo2_post_intervention: null,

            photo1_pre_intervention_base64: null,
            photo2_pre_intervention_base64: null,
            photo1_post_intervention_base64: null,
            photo2_post_intervention_base64: null,
          }
        ],


        devisFinancement: null,
        devisDateNftDemande: null,
        referenceDevis: null,
        dateDevis: null,
        nomEmetteurDevis: null,
        montantDevis: null,
        datePrevueNftDevis: null,
        dateVisaManager: null,
        manager: null,
        validationFinancementValide: null,

        visitePrealableValide: null,

        visiteClotureAgent: null,
        visiteClotureDateVisiteEffectue: null,
        visiteClotureCommentaire: null,
        visiteClotureConforme: null,
        visiteClotureRealise: null,

        resultatConformiteDateCloture: null,
        resultatConformiteAgentValidationControle: null,
        resultatConformiteDateAbandon: null,
        commentaireCloture: null,
        abandonValide: null,
      },

      itemsIdfm: [
        { value: '', text: 'Item IDFM' },
      ],

      rawDataFromDb: {},
      attachement: {},

      collapseOpen: false
    }
  },
  computed: {
    isFinalStep() {
      return this.tabIndex + 1 === 4
    },
    getPenalite() {
      if (this.form.itemidfm !== '') {
        return itemIDFM.find(item => {
          return item.item === this.form.itemidfm
        }).penalite
      } else {
        return ''
      }
    },
    getCategoryIdfm() {
      if (this.form.itemidfm !== '') {
        return itemIDFM.find(item => {
          return item.item === this.form.itemidfm
        }).categorie
      } else {
        return ''
      }
    },
    isEdition() {
      return this.mode === 'edition'
    },
    formatVisiteClotureDateVisiteEffectue() {
      if (this.form.visiteClotureDateVisiteEffectue) {
        return this.formatDate(this.form.visiteClotureDateVisiteEffectue)
      }
      return ''
    },
    formatValidationInterventionDateVisa() {
      if (this.form.validationInterventionDateVisa) {
        return this.formatDate(this.form.validationInterventionDateVisa)
      }
      return ''
    },
    formatDateVisaManager() {
      if (this.form.dateVisaManager) {
        return this.formatDate(this.form.dateVisaManager)
      }
      return ''
    },
    formatResultatConformiteDateCloture() {
      if (this.form.resultatConformiteDateCloture) {
        return this.formatDate(this.form.resultatConformiteDateCloture)
      }
      return ''
    },
    formatResultatConformiteDateAbandon() {
      if (this.form.resultatConformiteDateAbandon) {
        return this.formatDate(this.form.resultatConformiteDateAbandon)
      }
      return ''
    }
  },
  watch: {
    ['form.status'](newVal, oldVal) {
      this.$store.dispatch('setTicketStatus', newVal)
    }
  },
  mounted() {
    if (this.isInStandaloneMode) {
      this.collapseOpen = false
    } else {
      this.collapseOpen = true
    }
    if (this.$route.params.id) {
      this.mode = 'edition'
      this.ticketId = this.$route.params.id;
      this.loadTicket()
    } else {
      this.mode = 'creation'
      this.form.dateCreation = moment().format('YYYY-MM-DD hh:mm:ss')
      this.$store.dispatch('setTicketCreationDate', moment().format('DD/MM/YYYY'))
      if (this.isInStandaloneMode) {
        this.form.origin = 'Préventif'
      } else {
        this.form.origin = 'Correctif'
      }

      this.$store.dispatch('setTicketStatus', this.form.status)
    }

    this.loadStation();
    this.loadNatureDemande();
    this.loadItemIDFM();
    this.loadIntervenantTicket();
    this.loadStatus();
    this.loadPrestationHf();
  },
  methods: {
    deleteIntervention(index) {
      this.$bvModal.msgBoxConfirm('Êtes vous sûr de vouloir supprimer cette intervention ?', {
        title: 'Suppression d\'une intervention',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Supprimer',
        cancelTitle: 'Annuler',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
          .then(value => {
            if (value) {
              this.form.interventions.splice(index, 1)
            }
          })
          .catch(err => {
            // An error occurred
          })
    },
    nextTab() {
      if (this.tabIndex + 1 === 4) {
        this.save()
      } else {
        this.tabIndex++;
      }
    },
    addIntervention() {
      this.form.interventions.push({
        intervention_position: this.form.interventions.length + 1,
        internal_id: uuidv4(),
        interventionLocalisation: null,
        id: null,
        interventionDetail: null,
        prestationDet: null,
        prestationhf: null,
        prestationSurfacem2: null,
        prestationHauteurm: null,
        photo1_post_intervention: null,
        photo2_post_intervention: null,
        photo1_pre_intervention: null,
        photo2_pre_intervention: null
      })
    },
    loadItemIDFM() {
      const groupedItems = {};

      itemIDFM.forEach(item => {
        if (!groupedItems[item.categorie]) {
          groupedItems[item.categorie] = [];
        }
        groupedItems[item.categorie].push({ value: item.item, text: item.item });
      });

      this.itemsIdfm = Object.keys(groupedItems).map(category => {
        return {
          label: category,
          options: groupedItems[category]
        };
      });
    },
    async save() {
      if (this.isInStandaloneMode) {
        if (this.isEdition) {
          DB.put({
            type: 'ticket',
            _id: this.ticketId,
            _rev: this.revision,
            ...this.rawDataFromDb,
            //_attachments: this.attachement,
            ...await this.serializeFrom(),
          }).then(async res => {
            const request = indexedDB.open("ticketprop", 1);

            request.onsuccess = async (event) => {
              const db = event.target.result;

              for (let key in this.attachement) {
                if (this.attachement.hasOwnProperty(key)) {
                  const element = this.attachement[key];

                  await Promise.all([
                    this.storeImage(element.data, key, db, "images", element.content_type),
                    this.storeImage(element.data, key, db, "imagesToUpload", element.content_type)
                  ])
                }
              }
              this.$router.push({name: 'tickets-list'})
            }
          })
        } else {
          DB.put({
            type: 'ticket',
            _id: `ticket_${uuidv4()}`,
            //_attachments: this.attachement,
            ...await this.serializeFrom(),
          }).then(async res => {
            const request = indexedDB.open("ticketprop", 1);

            request.onsuccess = async (event) => {
              const db = event.target.result;

              for (let key in this.attachement) {
                if (this.attachement.hasOwnProperty(key)) {
                  const element = this.attachement[key];

                  await Promise.all([
                    this.storeImage(element.data, key, db, "images", element.content_type),
                    this.storeImage(element.data, key, db, "imagesToUpload", element.content_type)
                  ])
                }
              }
            this.$router.push({name: 'tickets-list'})
          }
        })
        }
      } else {
        // make HTTP call
        if (this.isEdition) {
          axios.put(`${process.env.VUE_APP_API_BASE_URL}/tickets/${this.ticketId}`, {...await this.serializeFrom()})
              .then((res) => {
                this.$router.push({name: 'tickets-list'})
              })
        } else {
          axios.post(`${process.env.VUE_APP_API_BASE_URL}/tickets`, await this.serializeFrom())
              .then((res) => {
                this.$router.push({name: 'tickets-list'})
              })
        }
      }
    },
    loadTicket() {
      if (this.isInStandaloneMode) {
        DB.get(this.ticketId).then(res => {
          console.log('Load ticket DB', res)
          this.rawDataFromDb = res;
          this.attachement = res['_attachments'] ?? {};
          this.deserializeForm(res)
          this.revision = res._rev
        })
      } else {
        axios.get(`${process.env.VUE_APP_API_BASE_URL}/tickets/${this.ticketId}`)
          .then((res) => {
            this.deserializeForm(res.data)
          })
      }
    },
    getDevisFinancement() {
      if (this.form.interventions[0].prestationhf) {
        this.form.devisFinancement = this.prestationshf.find(item => item.value === this.form.interventions[0].prestationhf).financement
      }

      return '';
    },
    async serializeFrom() {
      let interventions = [];

      for (let i in this.form.interventions) {
        let intervention = this.form.interventions[i]
        let inverventionFinal = {
          localisation: intervention.interventionLocalisation,
          detail: intervention.interventionDetail,
          det: intervention.prestationDet,
          prestation_hf: intervention.prestationhf,
          surface: intervention.prestationSurfacem2,
          hauteur: intervention.prestationHauteurm,
          photo1_pre_intervention: intervention.photo1_pre_intervention_uuid ?? null,
          photo2_pre_intervention: intervention.photo2_pre_intervention_uuid ?? null,
          photo1_post_intervention: intervention.photo1_post_intervention_uuid ?? null,
          photo2_post_intervention: intervention.photo2_post_intervention_uuid ?? null,
        }

        if (!this.isInStandaloneMode) {
          if (intervention.photo1_pre_intervention instanceof File) {
            let formData = new FormData();
            formData.append("file", intervention.photo1_pre_intervention);
            formData.append("type", intervention.photo1_pre_intervention.type);
            let imageUpload = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/images`, formData)
            inverventionFinal['photo1_pre_intervention'] = imageUpload.data.file
          }

          if (intervention.photo2_pre_intervention instanceof File) {
            let formData = new FormData();
            formData.append("file", intervention.photo2_pre_intervention);
            formData.append("type", intervention.photo2_pre_intervention.type);
            let imageUpload = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/images`, formData)
            inverventionFinal['photo2_pre_intervention'] = imageUpload.data.file
          }

          if (intervention.photo1_post_intervention instanceof File) {
            let formData = new FormData();
            formData.append("file", intervention.photo1_post_intervention);
            formData.append("type", intervention.photo1_post_intervention.type);
            let imageUpload = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/images`, formData)
            inverventionFinal['photo1_post_intervention'] = imageUpload.data.file
          }

          if (intervention.photo2_post_intervention instanceof File) {
            let formData = new FormData();
            formData.append("file", intervention.photo2_post_intervention);
            formData.append("type", intervention.photo2_post_intervention.type);
            let imageUpload = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/images`, formData)
            inverventionFinal['photo2_post_intervention'] = imageUpload.data.file
          }
        }

        interventions.push(inverventionFinal)
      }
      return {
        type: 'ticket',
        'internal-ticket-id': this.ticketId ?? uuidv4(),
        'date-creation': this.form.dateCreation,
        'date-creation-ts': moment(this.form.dateCreation).unix(),
        'tickets-status': this.form.status,
        'tickets-charge-d-affaire': this.form.chargeAffaireMatricul,
        'tickets-charge-d-affaire-groupname': this.form.chargeAffaireGroupName,
        'tickets-origine': this.form.origin,
        'tickets-station': this.form.station,
        'tickets-item-idfm': this.form.itemidfm,
        'tickets-categorie-idfm': this.getCategoryIdfm,
        'tickets-penalite': this.getPenalite,
        'tickets-nature-demande': this.form.nature,
        'tickets-intervenant': this.form.intervenant,
        'tickets-n-sagai': this.form.sagai,
        'tickets-n-ot': this.form.ot,
        'tickets-date-sagai': this.form.dateSagai,
        'tickets-code-mire-lieu': this.form.codemirelieu,
        'tickets-financement': this.form.devisFinancement,
        'tickets-date-ntf-demande': this.form.devisDateNftDemande,
        'tickets-reference-devis': this.form.referenceDevis,
        'tickets-date-devis': this.form.dateDevis,
        'tickets-nom-emetteur-devis': this.form.nomEmetteurDevis,
        'tickets-montant-devis': this.form.montantDevis,
        'tickets-date-prevue-ntf-devis': this.form.datePrevueNftDevis,
        'tickets-manager-devis': this.form.manager,
        'tickets-date-visa-manager': this.form.dateVisaManager,
        'tickets-date-ntf': this.form.dateNft,
        'tickets-agent-ntf': this.form.agentNft,
        'tickets-date-nft-realise': this.form.visiteClotureDateVisiteEffectue,
        'tickets-commentaire-ntf': this.form.commentaireNft,
        'tickets-visite-cloture-agent': this.form.visiteClotureAgent,
        'tickets-conforme': this.form.visiteClotureConforme,
        'tickets-visite-cloture-commentaire': this.form.visiteClotureCommentaire,
        'tickets-date-cloture': this.form.resultatConformiteDateCloture,
        'tickets-date-abandon': this.form.resultatConformiteDateAbandon,
        'tickets-abandon-valide': this.form.abandonValide,
        'tickets-agent-validation': this.form.resultatConformiteAgentValidationControle,
        'tickets-commentaire-cloture-abandon': this.form.commentaireCloture,
        'tickets-intervenation-validation' : this.form.validationInterventionValide,
        'tickets-prestation-hors-forfait': this.form.validationInterventionPrestationHorsForfait,
        'tickets-intervenation-date-visa' : this.form.validationInterventionDateVisa,
        'tickets-intervenation-agent' : this.form.validationInterventionAgent,
        'interventions': interventions,
        'tickets-financement-valide': this.form.validationFinancementValide,
        'tickets-visite-cloture-valide': this.form.visiteClotureRealise
      }
    },
    takePictureIntervention(index, ref) {
      if ((this.$store.getters.userIsPrestataireRatp || this.$store.getters.userIsPrestataireHorsRatp || (this.isEdition && (this.form.chargeAffaireGroupName != this.$store.state.currentUser.groupname && this.$store.getters.userIsAgentStation))) && ref.includes('pre_intervention')) {
        return;
      }
      this.$refs[`${ref}${index}`][0].$el.click()
    },
    async uploadImgIntervention(index, field) {
      let reader = new FileReader();
      let extension = 'jpeg';
      // let extension = this.form.interventions[index][field].name.split('.').pop();
      const newImage = await resizeAndConvertToJPEG(this.form.interventions[index][field], 1024*1024, 0.7);
      this.form.interventions[index][field] = newImage;
      reader.readAsDataURL(newImage);
      // reader.readAsDataURL(this.form.interventions[index][field]);
      reader.onload = () => {
        console.log('read file success', index, field)
        let interventions = this.form.interventions;
        interventions[index][`${field}_base64`] = reader.result;
        let imgUuid = uuidv4();
        interventions[index][`${field}_uuid`] = imgUuid + '.' + extension;
        //this.form.interventions[index][field] = null;
        this.$set(this.attachement, `${imgUuid}.${extension}`, {
          content_type: 'image/jpeg',
          // content_type: interventions[index][field].type,
          data: reader.result,
          uuid: imgUuid
        })
        this.$set(this.form, 'interventions', interventions)
        this.forceRender++;
      }
    },
    deserializeForm(data) {
      console.log('date to deserialize', data)
      this.form.id = data['id'];
      this.form.dateCreation = data['date-creation'];
      this.form.status = data['tickets-status'];
      this.form.chargeAffaireMatricul = data['tickets-charge-d-affaire'];
      this.form.chargeAffaireGroupName = data['tickets-charge-d-affaire-groupname'];
      this.form.origin = data['tickets-origine'];
      this.form.station = data['tickets-station'];
      this.form.itemidfm = data['tickets-item-idfm'];
      this.form.penalite = data['tickets-penalite'];
      this.form.nature = data['tickets-nature-demande'];
      this.form.intervenant = data['tickets-intervenant'];
      this.form.sagai = data['tickets-n-sagai'];
      this.form.ot = data['tickets-n-ot'];
      this.form.dateSagai = data['tickets-date-sagai'];
      this.form.codemirelieu = data['tickets-code-mire-lieu'];
      this.form.devisFinancement = data['tickets-financement'];
      this.form.devisDateNftDemande = data['tickets-date-ntf-demande'];
      this.form.referenceDevis = data['tickets-reference-devis'];
      this.form.dateDevis = data['tickets-date-devis'];
      this.form.nomEmetteurDevis = data['tickets-nom-emetteur-devis'];
      this.form.montantDevis = data['tickets-montant-devis'];
      this.form.datePrevueNftDevis = data['tickets-date-prevue-ntf-devis'];
      this.form.manager = data['tickets-manager-devis'];
      this.form.dateVisaManager = data['tickets-date-visa-manager'];
      this.form.validationFinancementValide = data['tickets-financement-valide'] === "1" ? true : data['tickets-financement-valide'];
      this.form.dateNft = data['tickets-date-ntf'];
      this.form.agentNft = data['tickets-agent-ntf'];
      this.form.commentaireNft = data['tickets-commentaire-ntf'];
      this.form.visiteClotureAgent = data['tickets-visite-cloture-agent'];
      this.form.visiteClotureDateVisiteEffectue = data['tickets-date-nft-realise'];
      this.form.visiteClotureConforme = data['tickets-conforme'] === "1" ? true : data['tickets-conforme'];
      this.form.visiteClotureCommentaire = data['tickets-visite-cloture-commentaire'];
      this.form.resultatConformiteDateCloture = data['tickets-date-cloture']
      this.form.resultatConformiteDateAbandon = data['tickets-date-abandon']
      this.form.resultatConformiteAgentValidationControle = data['tickets-agent-validation']
      this.form.commentaireCloture = data['tickets-commentaire-cloture-abandon'];
      this.form.abandonValide = data['tickets-abandon-valide'] === "1" ? true : data['tickets-abandon-valide']
      this.form.validationInterventionValide = data['tickets-intervenation-validation'] === "1";
      this.form.validationInterventionAgent = data['tickets-intervenation-agent'];
      this.form.validationInterventionDateVisa = data['tickets-intervenation-date-visa'];
      this.form.validationInterventionPrestationHorsForfait = data['tickets-prestation-hors-forfait'] === "1";
      this.form.visiteClotureRealise = data['tickets-visite-cloture-valide'] === "1" ? true : data['tickets-visite-cloture-valide'];

      if (data['interventions'] && data['interventions'].length > 0) {
        this.form.interventions = data['interventions'].reverse().map((intervention, index) => {
          let obj = {
            interventionLocalisation: intervention['localisation'],
            interventionDetail: intervention['detail'],
            prestationDet: intervention['det'],
            prestationhf: intervention['prestation_hf'],
            prestationSurfacem2: intervention['surface'],
            prestationHauteurm: intervention['hauteur'],
            photo1_pre_intervention: intervention['photo1_pre_intervention'] ?? null,
            photo1_pre_intervention_uuid: intervention['photo1_pre_intervention'] ?? null,
            photo2_pre_intervention: intervention['photo2_pre_intervention'] ?? null,
            photo2_pre_intervention_uuid: intervention['photo2_pre_intervention'] ?? null,
            photo1_post_intervention: intervention['photo1_post_intervention'] ?? null,
            photo1_post_intervention_uuid: intervention['photo1_post_intervention'] ?? null,
            photo2_post_intervention: intervention['photo2_post_intervention'] ?? null,
            photo2_post_intervention_uuid: intervention['photo2_post_intervention'] ?? null,
          }

          if (this.isInStandaloneMode) {
            const request = indexedDB.open("ticketprop", 1);

            request.onsuccess = async (event) => {
              const db = event.target.result;
              obj['photo1_pre_intervention_base64'] = intervention['photo1_pre_intervention'] ? await this.readImage(intervention['photo1_pre_intervention'], db, "images") : null;
              obj['photo2_pre_intervention_base64'] = intervention['photo2_pre_intervention'] ? await this.readImage(intervention['photo2_pre_intervention'], db, "images") : null;
              obj['photo1_post_intervention_base64'] = intervention['photo1_post_intervention'] ? await this.readImage(intervention['photo1_post_intervention'], db, "images") : null;
              obj['photo2_post_intervention_base64'] = intervention['photo2_post_intervention'] ? await this.readImage(intervention['photo2_post_intervention'], db, "images") : null;
            }
          } else {
            if (intervention['photo1_pre_intervention']) {
              obj['photo1_pre_intervention_base64'] = `${process.env.VUE_APP_IMAGE_BASE_URL}/${intervention['photo1_pre_intervention']}`
            }
            if (intervention['photo2_pre_intervention']) {
              obj['photo2_pre_intervention_base64'] = `${process.env.VUE_APP_IMAGE_BASE_URL}/${intervention['photo2_pre_intervention']}`
            }
            if (intervention['photo1_post_intervention']) {
              obj['photo1_post_intervention_base64'] = `${process.env.VUE_APP_IMAGE_BASE_URL}/${intervention['photo1_post_intervention']}`
            }
            if (intervention['photo2_post_intervention']) {
              obj['photo2_post_intervention_base64'] = `${process.env.VUE_APP_IMAGE_BASE_URL}/${intervention['photo2_post_intervention']}`
            }
          }

          return obj;
        })
      }

      this.$store.dispatch('setTicketStatus', this.form.status)
      this.$store.dispatch('setTicketCreationDate', moment(this.form.dateCreation).format('DD/MM/YYYY'))
    },
    setUpValidationIntervention(value) {
      if (value) {
        if (!this.form.validationInterventionAgent) {
          this.form.validationInterventionAgent = this.$store.getters.getCurrentIdentifiantUser
        }

        if (!this.form.validationInterventionDateVisa) {
          this.form.validationInterventionDateVisa = new Date()
        }

        if (this.form.validationInterventionPrestationHorsForfait) {
          this.form.status = 'Manager'
        } else {
          this.form.status = 'En cours'
        }
      } else {
        this.form.validationInterventionAgent = null;
        this.form.validationInterventionDateVisa = null;
      }
    },
    setUpValidationManagerFinancement(value) {
      if (value) {
        if (!this.form.manager) {
          this.form.manager = this.$store.getters.getCurrentIdentifiantUser
        }

        if (!this.form.dateVisaManager) {
          this.form.dateVisaManager = new Date()
        }

        this.form.status = 'En cours';
      } else {
        this.form.manager = null;
        this.form.dateVisaManager = null;
      }
    },
    setUpConforme(value) {
      if (value) {
        if (!this.form.resultatConformiteAgentValidationControle) {
          this.form.resultatConformiteAgentValidationControle = this.$store.getters.getCurrentIdentifiantUser
        }

        if (!this.form.resultatConformiteDateCloture) {
          this.form.resultatConformiteDateCloture = new Date()
        }

        this.form.status = 'Cloturé'
      } else {
        this.form.resultatConformiteAgentValidationControle = null
        this.form.resultatConformiteDateCloture = null;
      }
    },
    setUpAbandon(value) {
      if (value) {
        if (!this.form.resultatConformiteAgentValidationControle) {
          this.form.resultatConformiteAgentValidationControle = this.$store.getters.getCurrentIdentifiantUser
        }

        if (!this.form.resultatConformiteDateAbandon) {
          this.form.resultatConformiteDateAbandon = new Date()
        }

        this.form.status = 'Abandon'
      } else {
        this.form.resultatConformiteAgentValidationControle = null
        this.form.resultatConformiteDateAbandon = null
      }
    },
    setUpVisiteNft(value) {
      if (value) {
        if (!this.form.visiteClotureAgent) {
          this.form.visiteClotureDateVisiteEffectue = new Date()
          this.form.visiteClotureAgent = this.$store.getters.getCurrentIdentifiantUser
        }
        this.form.status = 'NFT'
      } else {
        this.form.visiteClotureDateVisiteEffectue = null
        this.form.visiteClotureAgent = null
      }
    },
    showFullScreenPicture(img) {
      this.$store.dispatch('setImgFullScreen', img);
      this.$store.dispatch('toggleImgFullScreen');
    }
  }
}

// Define a function to resize and convert the image to JPEG
function resizeAndConvertToJPEG(file, maxSizeInBytes, quality) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      const img = new Image();
      img.src = reader.result;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const maxSize = Math.sqrt(maxSizeInBytes);

        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxSize) {
            height *= maxSize / width;
            width = maxSize;
          }
        } else {
          if (height > maxSize) {
            width *= maxSize / height;
            height = maxSize;
          }
        }

        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const fileName = file.name;
            const fileType = 'image/jpeg'; // You can change this if needed
            const modifiedFile = new File([blob], fileName, { type: fileType });
            resolve(modifiedFile);
          },
          'image/jpeg',
          quality
        );
      };

      img.onerror = (error) => {
        reject(error);
      };
    };

    reader.onerror = (error) => {
      reject(error);
    };
  });
}
</script>

<style lang="scss">
.form-card {
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.05);
}
.form-card-title {
  margin-bottom: 2px;
}
p {
  font-size: 12px;
}
.form-card h1 {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: 0;
  color: var(--vert);
  text-transform: uppercase;
}
.form-card-title-checked-green {
  background-color: var(--vert);
  h1 {
    color: var(--blanc);
  }
}

.form-card-title-checked-grey {
  background-color: #6c757d;
  h1 {
    color: var(--blanc);
  }
}
.form-label {
  font-weight: 800;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--couleur-texte);
}
.text-color-grey {
  color: #b4b4b4;
}
.ticket-custom-select, .ticket-form-control {
  border-radius: 3px !important;
  border: solid 1px var(--contours) !important;
}

.ticket-form-control, .ticket-custom-select {
  font-size: 12px !important;
}

.ticket-form-control::placeholder {
  font-size: 12px !important;
}

.custom-active-nav-item {
  border: solid 1px var(--fond-clair);
  background-color: var(--blanc);
}

.custom-nav-item {
  border: solid 1px var(--fond-clair);
  background-color: var(--contours);
  padding: 9px;
  font-size: 14px;
  font-weight: 500;
  color: var(--couleur-texte);
}

.btn-add-intervention {
  border-radius: 3px;
  background-image: linear-gradient(94deg, var(--orange) 0%, var(--rouge) 98%);
  color: white;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  border: none !important;
}

.custom-control-label {
  font-size: 12px !important;
}

.ticket-checkbox-label label {
  padding-top: 6px;
  color: #14489E;
}
</style>
