import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from "js-cookie";
import {DB} from "@/db/db";
import axios from "axios";
import router from "@/router";
Vue.use(Vuex)

const isInStandaloneMode = () => {
  return (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://');
}
export default new Vuex.Store({
  state: {
    currentTicket: {},
    ticketStatus: null,
    ticketDateCreation: null,
    currentUser: {},
    imgFullScreen: null,
    showImgFullScreen: false,
    logs: [],
    ticketFilter: {},
    showModalDevisEtValidation: false,
    syncStatus: null,
    version: null
  },
  getters: {
    userIsAdmin: (state) => {
      return state.currentUser.groupname === 'ADMIN'
    },
    userIsConsultant: (state) => {
      return state.currentUser.groupname === 'CONSULTANT'
    },
    userIsAgentStation: (state) => {
      return state.currentUser.groupname === 'AGENT_STATION'
    },
    userIsAgentProprete: (state) => {
      return state.currentUser.groupname === 'AGENT_PROPRETE'
    },
    userIsAgentPame: (state) => {
      return state.currentUser.groupname === 'AGENT_PAME'
    },
    userIsCoordinateurIdfm: (state) => {
      return state.currentUser.groupname === 'COORDINATEUR_IDFM'
    },
    userIsAgentTechnique: (state) => {
      return state.currentUser.groupname === 'AGENT_TECHNIQUE'
    },
    userIsManager: (state) => {
      return state.currentUser.groupname === 'MANAGER'
    },
    userIsPrestataireRatp: (state) => {
      return state.currentUser.groupname === 'PRESTATAIRE_RATP'
    },
    userIsPrestataireHorsRatp: (state) => {
      return state.currentUser.groupname === 'PRESTATAIRE_HORS_RATP'
    },
    getCurrentIdentifiantUser: (state) => {
      return state.currentUser.identifiant
    }
  },
  mutations: {
  },
  actions: {
    setCurrentTicket: ({state}, ticket) => {
      state.currentTicket = ticket
    },
    setTicketStatus: ({state}, status) => {
      state.ticketStatus = status
    },
    setTicketCreationDate: ({state}, date) => {
      state.ticketDateCreation = date
    },
    setCurrentUser: ({state}, user) => {
      state.currentUser = user
    },
    setImgFullScreen: ({state}, image) => {
      state.imgFullScreen = image
    },
    toggleImgFullScreen: ({state}) => {
      state.showImgFullScreen = !state.showImgFullScreen;
    },
    addLog: ({state}, message) => {
      state.logs.push(message);
    },
    setTicketFilter: ({state}, filter) => {
      state.ticketFilter = filter;
    },
    setSyncStatus: ({state}, status) => {
      state.syncStatus = status;
    },
    checkLogin: async ({dispatch, state}) => {
      console.info('Check login start...')
        if (!Cookies.get('login')) {
          if (router.currentRoute.name === 'mobile-login') {
            await router.push({name: 'mobile-login'})
          } else {
            await router.push({name: 'login'})
          }

          return;
        }

        if (Cookies.get('login') && !state.currentUser.identifiant) {
          if (isInStandaloneMode()) {
            try {
              console.debug('Start try to find user in DB...')

              const res = await DB.find({
                selector: {type: 'user', identifiant: {$eq: Cookies.get('login')}}
              })

              console.debug('checkLogin DB response', res)

              if (res.docs.length === 1) {
                // login OK
                console.info('Re log user from cookie')
                await dispatch('setCurrentUser', res.docs[0])
                if (res.docs.groupname === 'PRESTATAIRE_HORS_RATP' && router.currentRoute.name === 'tickets-list') {
                  await router.push({name: 'mobile-station-list'})
                }
              } else {
                // login KO
                console.warn('Re log user from cookie KO')
                Cookies.remove('login')
                await router.push({name: 'login'})
              }
            } catch (err) {
              console.warn('Error fetch user in DB from cookie', err)
            }
          } else {
            try {
              const res = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/users/identifiant/${Cookies.get('login')}`)
              console.debug('re login from cookie success')
              await dispatch('setCurrentUser', res.data)

              if (res.data.groupname === 'PRESTATAIRE_HORS_RATP' && router.currentRoute.name === 'tickets-list') {
                await router.push({name: 'mobile-station-list'})
              }
            } catch (err) {
              console.log('re login from cookie failed')
            }
          }
        }
      console.log('Check login end...')
    },
    showModalDevisEtValidation: ({state}) => {
      state.showModalDevisEtValidation = true;
    },
    hideModalDevisEtValidation: ({state}) => {
      state.showModalDevisEtValidation = false;
    },
    setVersion: ({state}, version) => {
      state.version = version;
    }
  },
  modules: {
  }
})
