import axios from "axios";
import {DB} from "@/db/db";
import Cookies from "js-cookie";
const mixin = {
  data() {
    return {
      indexDb: null,
      currentDownload: 0,
      maxDownloadFile: 0,

      syncDownloadImageLoading: false,
      syncUploadImageLoading: false,
    }
  },
  computed: {
    premiereSyncOk() {
      return Cookies.get('premiereSyncOk');
    }
  },
  methods: {
    sync() {
      this.syncHandler = DB.sync(process.env.VUE_APP_DB_BASE_URL, {
        live: true,   // real-time replication
        //retry: true,  // reconnect automatically
        batch_size: 10,
        batches_limit: 1,
        style: 'main_only',
        // Exponential backoff with a max value
        back_off_function: (delay) => {
          console.debug('backoff', delay);
          this.dataSyncState = 'reconnecting'
          if (delay === 0) {
            return 100;
          }
          if (delay >= 3200) {
            return 3200;
          }
          return delay * 2;
        },
      });

      // This event fires when the replication is paused, either because
      // a live replication is waiting for changes, or replication has
      // temporarily failed, with `err`, and is attempting to resume.
      this.syncHandler.on('paused', (err) => {
        console.info('paused', err);
        if (err) {
          this.$store.dispatch('setSyncStatus', 'error')
        } else {
          this.$store.dispatch('setSyncStatus', 'ok')
          this.$notify({ type: 'success', title: 'Synchronisation', text: 'Tous les tickets ont été synchronisés' })
        }
      });

      // This event fires when the replication starts actively processing changes;
      // e.g. when it recovers from an error or new changes are available.
      this.syncHandler.on('active', (info) => {
        console.info('active', info);
        this.$store.dispatch('setSyncStatus', 'in_progress')
        this.$notify({ type: 'warn', title: 'Synchronisation', text: 'Synchronisation en cours...' })
      });

      // This event fires when the replication has written a new document.
      //
      // `info` will contain details about the change.
      // `info.docs` will contain the docs involved in that change.
      this.syncHandler.on('change', (info) => {
        const images = [
          'photo1_pre_intervention',
          'photo2_pre_intervention',
          'photo1_post_intervention',
          'photo2_post_intervention',
        ]
        console.info('change', info);
        this.$store.dispatch('setSyncStatus', 'in_progress')
        this.$notify({ type: 'warn', title: 'Synchronisation', text: 'Synchronisation en cours...' })
        if (info.direction === 'pull' && info.change.ok && info.change.docs && info.change.docs.length > 0) {
          for (let i = 0; i < info.change.docs.length; i++) {
            const doc = info.change.docs[i];
            console.debug('Doc received', doc);
            if (doc._id.includes("ticket")) {
            // if (doc._id.includes("ticket") && this.premiereSyncOk) {
              const intervenant = doc["tickets-intervenant"];
              document.dispatchEvent(new Event('replication-new-document'))
              const request = indexedDB.open("ticketprop", 1);
              request.onsuccess = (event) => {
                const db = event.target.result;
                for (let k = 0; k < doc.interventions.length; k++) {
                  const intervention = doc.interventions[k];
                  for (let j = 0; j < images.length; j++) {
                    const image = images[j];
                    if (intervention[image]) {
                      // store info in DB
                      this.storeImageToDownload(intervention[image], intervenant, db)
                      // this.storeImageToDownload(intervention[image], db)
                    }
                  }
                }
              }
            }
          }
        }
      });

      // This event fires when replication is completed or cancelled.
      // In a live replication, only cancelling the replication should
      // trigger this event.
      //
      // `info` will contain details about the replication.
      this.syncHandler.on('complete', (info) => {
        console.info('complete', info);
        this.$store.dispatch('setSyncStatus', 'ok')
        this.$notify({ type: 'success', title: 'Synchronisation', text: 'Tous les tickets ont été synchronisés' })
      });

      // This event fires if a document failed to replicate due to validation
      // or authorization errors.
      this.syncHandler.on('denied', (err) => {
        console.info('denied', err);
        this.$store.dispatch('setSyncStatus', 'error')
        this.$notify({ type: 'error', title: 'Synchronisation authentification', text: 'Erreur durant la synchronisation' })

      });

      // This event is fired when the replication is stopped due to an
      // unrecoverable failure. If `retry` is `false`, this will also fire
      // when the user goes offline or another network error occurs
      // (so you can handle retries yourself, if you want).
      this.syncHandler.on('error', (err) => {
        console.warn('error', err);
        this.$store.dispatch('setSyncStatus', 'error')
        this.$notify({ type: 'error', title: 'Synchronisation erreur', text: 'Erreur durant la synchronisation' })
      });

      this.syncHandler.on('checkpoint', function (info) {
        console.info('checkpoint', info);
      });
    },
    createDatabase() {
      const dbName = "ticketprop";

      const request = indexedDB.open(dbName, 1);

      this.indexDb = request;

      request.onerror = (event) => {
        // Handle errors.
        console.warn('Error during creation of database', event)
      };
      request.onupgradeneeded = async (event) => {
        const db = event.target.result;

        // Create an objectStore to store image
        const objectStoreImage = db.createObjectStore("images");
        const objectStoreImageToUpload = db.createObjectStore("imagesToUpload");
        const objectStoreImageToDownload = db.createObjectStore("imagesToDownload");
        objectStoreImageToDownload.createIndex("intervenant", "intervenant", { unique: false });

        // Use transaction oncomplete to make sure the objectStore creation is
        // finished before adding data into it.
        await Promise.all(
          [
            new Promise((resolve, reject) => {
              objectStoreImage.transaction.oncomplete = (event) => {
                console.debug(`Object store images created`)
                resolve()
              };
            }),
            new Promise((resolve, reject) => {
              objectStoreImageToUpload.transaction.oncomplete = (event) => {
                console.debug(`Object store images to upload created`)
                resolve()
              };
            })
          ]
        )
        console.debug('Database created')
      };
    },
    syncUploadImage() {
      // if (this.syncUploadImageLoading) {
      //   return;
      // }

      // this.syncUploadImageLoading = true

      // read indexDb objectstore "imagesToUpload" and upload each on API, if upload success, remove from indexdb
      const request = indexedDB.open("ticketprop", 1);

      request.onsuccess = (event) => {
        const db = event.target.result;
        const transaction = db.transaction(["imagesToUpload"], "readonly");
        const objectStore = transaction.objectStore("imagesToUpload");
        const request = objectStore.getAllKeys();

        request.onsuccess = async (event) => {
          const keys = request.result
          if (keys.length === 0) {
            console.info('No image waiting to upload')
            return;
          }
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const transaction = db.transaction(["imagesToUpload"], "readwrite");
            const objectStore = transaction.objectStore("imagesToUpload");
            const request = objectStore.get(key);

            request.onsuccess = async (event) => {
              const image = request.result
              let reader = new FileReader();
              reader.readAsText(image);
              reader.onloadend = async () => {
                console.log('sync upload request', image, key)
                try {
                  let formData = new FormData();
                  formData.append("file", reader.result.split(',')[1]);
                  formData.append("type", image.type);
                  formData.append("name", key);

                  const res = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/images/base64`, formData)
                  if (res.status === 200) {
                    const transaction = db.transaction(["imagesToUpload"], "readwrite");
                    const objectStore = transaction.objectStore("imagesToUpload");
                    const request = objectStore.delete(key);
                    request.onsuccess = async (event) => {
                      console.info('delete success')
                    }
                  }
                } catch (e) {
                  console.warn('Error during upload image', e)
                }
              }

            }
          }
          this.$notify({ type: 'success', title: 'Synchronisation', text: 'Toutes les images ont été envoyées' })
        };
      };
    },
    storeImage(imageResponse, imageKey, db, storeObject = "images", type = 'image/png') {
      return new Promise((resolve, reject) => {
        const blob = new Blob([imageResponse], {type: type});
        const transaction = db.transaction([storeObject], "readwrite");
        const objectStore = transaction.objectStore(storeObject);
        const request = objectStore.put(blob, imageKey);
        request.onsuccess = (event) => {
          console.log(`Image ${imageKey} added into ${storeObject}`, )
          resolve();
        };
        request.onerror = (event) => {
          console.log(`Error while adding image ${imageKey} into ${storeObject}`, event)
          reject();
        };
      })
    },
    // storeImageToDownload(imageKey, db, storeObject = "imagesToDownload") {
    storeImageToDownload(imageKey, intervenant, db, storeObject = "imagesToDownload") {
      const transaction = db.transaction([storeObject], "readwrite");
      const objectStore = transaction.objectStore(storeObject);
      // const request = objectStore.add({imageKey}, imageKey);
      const request = objectStore.add({intervenant}, imageKey);
      request.onsuccess = (event) => {
        console.log(`Image ${imageKey} added into ${storeObject}`, )
      };
      request.onerror = (event) => {
        console.log(`Error while adding image ${imageKey} into ${storeObject}`, event)
      };
    },
    syncDownloadImage(force) {
      if (!force && (this.syncDownloadImageLoading || !this.$store.state.currentUser.identifiant)) {
        return;
      }

      this.syncDownloadImageLoading = true

      // read indexDb objectstore "imagesToUpload" and upload each on API, if upload success, remove from indexdb
      const request = indexedDB.open("ticketprop", 1);
      const objectStoreName = 'imagesToDownload'
      request.onsuccess = (event) => {
        const db = event.target.result;
        const transaction = db.transaction([objectStoreName], "readonly");
        const objectStore = transaction.objectStore(objectStoreName);

        const keys = [];
        const intervenant = this.$store.state.currentUser.identifiant;
        const keyRange = IDBKeyRange.only(intervenant);
        const index = objectStore.index('intervenant');
        index.openCursor(keyRange).onsuccess = async (cursorEvent) => {
          const cursor = cursorEvent.target.result;
          if (cursor) {
            keys.push(cursor.primaryKey);
            cursor.continue();
            return;
          }
          if (keys.length === 0) {
            console.log('No image waiting to download')
            return;
          }
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            let transaction = db.transaction([objectStoreName], "readwrite");
            let objectStore = transaction.objectStore(objectStoreName);

            try {
              let imageResponse = await axios.get(`${process.env.VUE_APP_IMAGE_BASE_URL}/${key}`, { responseType: 'blob' })
              let reader = new FileReader();
              reader.readAsDataURL(new Blob([imageResponse.data], {type: imageResponse.headers['content-type']}));
              reader.onloadend = async () => {
                try {
                  await this.storeImage(reader.result, key, db, "images", imageResponse.headers['content-type'])

                  console.log(`Image ${key} has been downloaded`)
                  transaction = db.transaction([objectStoreName], "readwrite");
                  objectStore = transaction.objectStore(objectStoreName);
                  const request = objectStore.delete(key);
                  request.onsuccess = async (event) => {
                    console.log(`Image ${key} has been deleted from imagesToDownload`)
                  }
                } catch (e) {
                  console.log('Error during store image', e)
                }
              }
            } catch (e) {
              console.warn('Impossible de télécharger l\'image', key)
            }
          }
          this.$notify({ type: 'success', title: 'Synchronisation', text: 'Toutes les images ont été téléchargées' })
        };
      };
    },
    async loadImageFromAPI() {
      return new Promise((resolve, reject) => {
        const request = indexedDB.open("ticketprop", 1);

        request.onsuccess = async (event) => {
          const db = event.target.result;
          const listImagesResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/tickets/images`)
          const images = [
            'photo1_pre_intervention',
            'photo2_pre_intervention',
            'photo1_post_intervention',
            'photo2_post_intervention',
          ]
          this.maxDownloadFile = listImagesResponse.data.length
          for (let i = 0; i < listImagesResponse.data.length; i++) {
            let intervention = listImagesResponse.data[i];
            for (let j = 0; j < images.length; j++) {
              const image = images[j];
              if (intervention[image]) {
                const objectKey = intervention[image]
                try {
                  let imageResponse = await axios.get(`${process.env.VUE_APP_IMAGE_BASE_URL}/${intervention[image]}`, { responseType: 'blob' })
                  await new Promise((resolveBis, rejectBis) => {
                    let reader = new FileReader();
                    reader.readAsDataURL(new Blob([imageResponse.data], {type: imageResponse.headers['content-type']}));
                    reader.onloadend = async () => {
                      try {
                        await this.storeImage(reader.result, objectKey, db, "images", imageResponse.headers['content-type'])
                        resolveBis()
                      } catch (e) {
                        console.log('Error during store image', e)
                        rejectBis()
                      }
                    }
                  })
                } catch (e) {
                  console.warn('Error during download image', e);
                }
              }
            }
            this.currentDownload++;
          }
          resolve()

        };
      })
    },
    async getAllKeys(objectStoreName) {
      return new Promise((resolve, reject) => {
        const request = indexedDB.open("ticketprop", 1);

        request.onsuccess = (event) => {
          const db = event.target.result;
          const transaction = db.transaction([objectStoreName], "readonly");
          const objectStore = transaction.objectStore(objectStoreName);
          const request = objectStore.getAllKeys();

          request.onsuccess = (event) => {
            resolve(request.result)
          };
        };
      })
    },
    downloadAllImage() {
      if (!this.internetOk) {
        this.$notify({ type: 'error', title: 'Erreur', text: 'Vous devez être connecté à internet pour synchroniser les images' })
        return
      }

      this.$bvModal.msgBoxConfirm('Souhaitez-vous (re) synchroniser toutes les images ?', {
        title: 'Confirmation',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        cancelVariant: 'danger',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true,
        okTitle: 'Oui',
        cancelTitle: 'Non',
      })
        .then(value => {
          if (value) {
            this.syncDownloadImage(true);
            // this.loadImageFromAPI().then(() => {
            //   this.$notify({ type: 'success', title: 'Synchronisation', text: 'Toutes les images ont été synchronisées' })
            // })
          }
        })
        .catch(err => {
          // An error occurred
        })
    }
  }
}

export default mixin
