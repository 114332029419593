<template>
  <b-container fluid>
    <b-row class="mb-4" no-gutters>
      <b-col>
        <stat-number title="Ticket ouvert" :number="`${nbOuvert}`" color-bg="var(--blanc)" color-number="#c04191" color-text="#c04191"></stat-number>
      </b-col>
      <b-col>
        <stat-number title="Visa Manager" :number="`${nbVisaManager}`" color-bg="var(--blanc)" color-number="#ee8b16" color-text="#ee8b16"></stat-number>
      </b-col>
      <b-col>
        <stat-number title="En cours" :number="`${nbEnCours}`" color-bg="var(--blanc)" color-number="#c3bb00" color-text="#c3bb00"></stat-number>
      </b-col>
      <b-col>
        <stat-number title="NFT" :number="`${nbNft}`" color-bg="var(--blanc)" color-number="#5d981a" color-text="#5d981a"></stat-number>
      </b-col>
      <b-col>
        <stat-number title="Cloturé" :number="`${nbCloture}`" color-bg="var(--blanc)" color-number="#1a8398" color-text="#1a8398"></stat-number>
      </b-col>
      <b-col>
        <stat-number title="Abandon" :number="`${nbAbandon}`" color-bg="var(--blanc)" color-number="var(--abandon-707070)" color-text="var(--abandon-707070)"></stat-number>
      </b-col>
      <b-col class="d-flex">
        <filter-popin ref="filterpopin" class="mr-1"></filter-popin>
        <filter-reset @click="resetSearchForm" :disabled="searchFromEmpty" />
      </b-col>
      <b-col v-if="!isValidationPrestationHf" xs="4" sm="4">
        <btn-new-demande v-if="$store.getters.userIsAdmin || $store.getters.userIsAgentProprete || $store.getters.userIsAgentStation || $store.getters.userIsCoordinateurIdfm || $store.getters.userIsAgentTechnique || $store.getters.userIsManager || $store.getters.userIsAgentPame" />
      </b-col>
      <b-col v-if="isValidationPrestationHf" class="d-flex flex-column" xs="4" sm="4">
        <b-button :disabled="datas.length === 0" @click="exportSelectTicket" size="sm">Exporter la selection</b-button>
        <b-button @click="$store.dispatch('showModalDevisEtValidation')" size="sm">Renseigner les infos devis et valider</b-button>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col>
        <div class="container-custom">
          <b-row>
            <b-col>
              <b-table table-class="w-100" thead-class="table-head" :fields="tableHeader" :items="datas">
                <template #cell(select)="data">
                  <b-form-radio v-model="ticketIdSelected" name="ticket-select" :value="data.item._id"></b-form-radio>
                </template>
                <template #cell(dateCreation)="data">
                  {{ formatDate(data.item.dateCreation) }}
                </template>

                <template #cell(penalite)="data">
                  {{ getPenaliteFromIdfm(data.item.idfm) }}
                </template>

                <template #cell(prestationHf)="data">
                  {{ getPrestationHf(data.item) }}
                </template>

                <template #cell(etat)="data">
                  <ticket-status :ticket-status="data.item.status" />
                </template>
              </b-table>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col cols="12" sm="12" class="ml-md-2" md="4" v-if="ticketIdSelected">
        <demande-sumary id="ticket-sumary" v-if="ticketIdSelected" ref="ticketsumary" @close="ticketIdSelected = null" :ticket-id="ticketIdSelected" />
      </b-col>
    </b-row>

    <modal-devis-et-validation @validateDevis="validateDevis" v-if="$store.state.showModalDevisEtValidation" />
  </b-container>
</template>

<script>
import StatNumber from "@/components/StatNumber";
import StatIcon from "@/components/StatIcon";
import BtnNewDemande from "@/components/BtnNewDemande";
import {DB, initDb} from '@/db/db'
import DemandeSumary from "@/components/DemandeSumary";
import mixin from "@/mixin";
import itemIDFM from "@/db/item_idfm.json";
import TicketStatus from "@/components/TicketStatus";
import axios from "axios";
import FilterReset from "@/components/FilterReset.vue";
import qs from 'query-string';
import FilterPopin from "@/components/FilterPopin.vue";
import { mapState } from 'vuex';
import moment from "moment";
import Cookies from 'js-cookie'
import ModalDevisEtValidation from "@/components/ModalDevisEtValidation.vue";
import {btoa} from "buffer";

export default {
  name: "ListDemande",
  components: {FilterPopin, FilterReset, TicketStatus, DemandeSumary, BtnNewDemande, StatIcon, StatNumber, ModalDevisEtValidation},
  mixins: [mixin],
  data() {
    return {
      pagination: 25,
      ticketIdSelected: null,
      searchFromEmpty: true,
      tableHeader: [
        {
          key: 'select',
          label: '',
          thClass: 'table-th-custom',
          tdClass: 'table-td-custom'
        },
        {
          key: 'dateCreation',
          sortable: true,
          label: 'Date création',
          thClass: 'table-th-custom',
          tdClass: 'table-td-custom'
        },
        {
          key: 'station',
          label: 'Station',
          sortable: false,
          thClass: 'table-th-custom',
          tdClass: 'table-td-custom'
        },
        {
          key: 'penalite',
          sortable: true,
          label: 'Pénalité',
          thClass: 'table-th-custom',
          tdClass: 'table-td-custom'
        },
        {
          key: 'intervenant',
          sortable: false,
          label: 'Intervenant',
          thClass: 'table-th-custom',
          tdClass: 'table-td-custom'
        },
        {
          key: 'sagai',
          sortable: false,
          label: 'N°SAGAI',
          thClass: 'table-th-custom',
          tdClass: 'table-td-custom'
        },
        {
          key: 'ot',
          sortable: false,
          label: 'N°OT',
          thClass: 'table-th-custom',
          tdClass: 'table-td-custom'
        },
        {
          key: 'etat',
          sortable: false,
          label: 'Etat',
          thClass: 'table-th-custom',
          tdClass: 'table-td-custom'
        },
      ],

      datas: [],

      nbOuvert: 0,
      nbVisaManager: 0,
      nbEnCours: 0,
      nbNft: 0,
      nbCloture: 0,
      nbAbandon: 0,

      searchDesktopParams: {}
    }
  },
  beforeMount() {
    if (this.isInStandaloneMode) {
      //initDb()
    }
  },
  async mounted() {
    if (Cookies.get('isPAME')) {
      await this.$store.dispatch('setToken', Cookies.get('token'))
    } else if (!this.$store.getters.getCurrentIdentifiantUser) {
      this.$store.dispatch('checkLogin')
    }

    console.debug('mounted lets try to search ticket...')
    this.searchTicket()
    this.calcStats();
    if (this.isInStandaloneMode) {
      document.addEventListener('replication-new-document', this.onReplicationNewDocument)
    }
  },
  beforeDestroy() {
    if (this.isInStandaloneMode) {
      document.removeEventListener('replication-new-document', this.onReplicationNewDocument)
    }
  },
  computed: {
    ...mapState(['ticketFilter']),
    isValidationPrestationHf() {
      return this.$route.name === 'validation-hf'
    }
  },
  methods: {
    exportSelectTicket() {
      window.open(`${process.env.VUE_APP_API_BASE_URL}/export/ticketsv2?ticketIds=${this.datas.map(item => item.id).join(',')}&params=${Buffer.from(JSON.stringify(this.searchDesktopParams)).toString('base64')}`, '_blank')
    },
    validateDevis(form) {
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/tickets/validate-devis`, {
        ticketIds: this.datas.map(item => item.id).flat(),
        ...form,
        manager: this.$store.getters.getCurrentIdentifiantUser,
        dateVisaManager: new Date(),
        status: 'En cours'
      })
          .then((res) => {
            this.searchTicket()
            this.calcStats();
            this.$store.dispatch('hideModalDevisEtValidation', false)
          })
    },
    getPenaliteFromIdfm(idfm) {
      if (idfm !== null | idfm !== '') {
        let result = itemIDFM.find(item => {
          return item.item === idfm
        })
        if (result) {
          return result.penalite
        }
      }
      return ''
    },
    getCategoryIdfmFromIdfm(idfm) {
      return itemIDFM.find(item => {
        return item.item === idfm
      }).categorie
    },
    getPrestationHf(item) {
      if (item.interventions && item.interventions.length) {
        return item.interventions[0].prestation_hf;
      }
    },
    onReplicationNewDocument() {
      this.calcStats();
      this.searchTicket();
    },
    async searchTicket() {
      if (this.isInStandaloneMode) {
        console.log('searchTicket begging')
        let selector = {type: 'ticket'};
        let usersPAME = []
        if (Cookies.get('isPAME')) {
          usersPAME = await DB.find({
            selector: {type: 'user', groupname: {$eq: 'AGENT_PAME'}},
            fields: ['_id', 'identifiant', 'firstname', 'lastname'],
          })

          const usersPAMEIdentifiant = usersPAME.docs.map(user => user.identifiant)
          selector['tickets-intervenant'] = {$in: usersPAMEIdentifiant};
        }

        if (this.$store.getters.userIsPrestataireRatp || this.$store.getters.userIsPrestataireHorsRatp) {
          selector['tickets-intervenant'] = {$eq: this.$store.state.currentUser.identifiant};
        }

        if (this.ticketFilter.sagai) {
          selector['tickets-n-sagai'] = {$eq: this.ticketFilter.sagai};
        }

        if (this.ticketFilter.ot) {
          selector['tickets-n-ot'] = {$eq: this.ticketFilter.ot};
        }

        let dateCreation = {}
        if (this.ticketFilter.createdAtStart) {
          dateCreation['$gte'] = moment(this.ticketFilter.createdAtStart).unix()
        }

        if (this.ticketFilter.createdAtEnd) {
          dateCreation['$lte'] = moment(this.ticketFilter.createdAtEnd).set('hour', 23).set('minute', 59).set('second', 59).unix()
        }

        if (dateCreation['$gte'] || dateCreation['$lte']) {
          selector['date-creation-ts'] = dateCreation
        }

        if (this.ticketFilter.station) {
          selector['tickets-station'] = {$eq: this.ticketFilter.station};
        }

        if (this.ticketFilter.nature) {
          selector['tickets-nature-demande'] = {$eq: this.ticketFilter.nature};
        }

        if (this.ticketFilter.prestationhf) {
          selector['interventions'] = {$elemMatch: {prestation_hf: this.ticketFilter.prestationhf}}
        }

        if (this.ticketFilter.intervenant) {
          selector['tickets-intervenant'] = {$eq: this.ticketFilter.intervenant}
        }

        if (this.ticketFilter.status) {
          selector['tickets-status'] = {$eq: this.ticketFilter.status}
        }

        let penaliteSelector = {}
        if (this.ticketFilter.penalitede) {
          penaliteSelector['$gte'] = this.ticketFilter.penalitede
        }

        if (this.ticketFilter.penalitea) {
          penaliteSelector['$lte'] = this.ticketFilter.penalitea
        }

        if (penaliteSelector['$gte'] || penaliteSelector['$lte']) {
          selector['tickets-penalite'] = penaliteSelector
        }

        console.log('start search ticket with selector', selector)
        DB.allDocs({
          startkey: 'ticket_',
          include_docs: true,
        }).then(res => {
          console.log('Ticket list result', res)
          this.datas = []
          this.datas.push(...res.rows.filter(item => {
            if (!item.id.includes('ticket_')) {
              return false
            }

            if (this.$store.getters.userIsAgentPame) {
              const usersPAMEIdentifiant = usersPAME.docs.map(user => user.identifiant)
              if (!usersPAMEIdentifiant.includes(item.doc['tickets-intervenant'])) {
                return false
              }
            }

            if (this.$store.getters.userIsPrestataireRatp || this.$store.getters.userIsPrestataireHorsRatp) {
              if (item.doc['tickets-intervenant'] !== this.$store.state.currentUser.identifiant) {
                return false
              }
            }

            if (this.ticketFilter.sagai) {
              if (item.doc['tickets-n-sagai'] !== this.ticketFilter.sagai) {
                return false
              }
            }

            if (this.ticketFilter.ot) {
              if (item.doc['tickets-n-ot'] !== this.ticketFilter.ot) {
                return false
              }
            }

            if (this.ticketFilter.createdAtStart) {
              if (item.doc['date-creation-ts'] < moment(this.ticketFilter.createdAtStart).unix()) {
                return false
              }
            }

            if (this.ticketFilter.createdAtEnd) {
              if (item.doc['date-creation-ts'] > moment(this.ticketFilter.createdAtEnd).set('hour', 23).set('minute', 59).set('second', 59).unix()) {
                return false
              }
            }

            if (this.ticketFilter.station) {
              if (item.doc['tickets-station'] !== this.ticketFilter.station) {
                return false
              }
            }

            if (this.ticketFilter.nature) {
              if (item.doc['tickets-nature-demande'] !== this.ticketFilter.nature) {
                return false
              }
            }

            if (this.ticketFilter.prestationhf) {
              if (!item.doc.interventions.find(intervention => {
                return intervention.prestation_hf === this.ticketFilter.prestationhf
              })) {
                return false
              }
            }

            if (this.ticketFilter.intervenant) {
              if (item.doc['tickets-intervenant'] !== this.ticketFilter.intervenant) {
                return false
              }
            }

            if (this.ticketFilter.status) {
              if (item.doc['tickets-status'] !== this.ticketFilter.status) {
                return false
              }
            }

            if (this.ticketFilter.penalitede) {
              if (item.doc['tickets-penalite'] < this.ticketFilter.penalitede) {
                return false
              }
            }

            if (this.ticketFilter.penalitea) {
              if (item.doc['tickets-penalite'] > this.ticketFilter.penalitea) {
                return false
              }
            }

            return true;
          }).map(item => {
            let ticket = item.doc
            return {
              dateCreation: ticket['date-creation'],
              station: ticket['tickets-station'],
              idfm: ticket['tickets-item-idfm'],
              nature: ticket['tickets-nature-demande'],
              intervenant: ticket['tickets-intervenant'],
              sagai: ticket['tickets-n-sagai'],
              ot: ticket['tickets-n-ot'],
              interventions: ticket['interventions'],
              _id: ticket._id,
              id: ticket.id ? ticket.id : null,
              status: ticket['tickets-status']
            }
          }))
        })
        //DB.find({
        //  selector: selector,
        //  fields: ['_id', 'id', 'date-creation', 'tickets-station', 'tickets-nature-demande', 'tickets-item-idfm', 'tickets-intervenant', 'tickets-n-sagai', 'tickets-status', 'interventions'],
        //}).then(res => {
        //  console.log('Ticket list result', res)
        //  this.datas = []
        //  this.datas.push(...res.docs.map(ticket => {
        //    return {
        //      dateCreation: ticket['date-creation'],
        //      station: ticket['tickets-station'],
        //      idfm: ticket['tickets-item-idfm'],
        //      nature: ticket['tickets-nature-demande'],
        //      intervenant: ticket['tickets-intervenant'],
        //      sagai: ticket['tickets-n-sagai'],
        //      interventions: ticket['interventions'],
        //      _id: ticket._id,
        //      id: ticket.id ? ticket.id : null,
        //      status: ticket['tickets-status']
        //    }
        //  }))
        //})
      } else {
        let queryParams = {}
        if (this.$store.getters.userIsAgentPame) {
          queryParams['tickets-intervenant'] = 'AGENT_PAME'
        }

        if (this.$store.getters.userIsPrestataireRatp || this.$store.getters.userIsPrestataireHorsRatp) {
          queryParams['tickets-intervenant'] = this.$store.state.currentUser.identifiant
        }

        if (this.ticketFilter.sagai) {
          queryParams['tickets-numSagai'] = this.ticketFilter.sagai;
        }

        if (this.ticketFilter.ot) {
          queryParams['tickets-numOt'] = this.ticketFilter.ot;
        }

        if (this.ticketFilter.createdAtStart) {
          queryParams['date-creation-min'] = moment(this.ticketFilter.createdAtStart).unix()
        }

        if (this.ticketFilter.createdAtEnd) {
          queryParams['date-creation-max'] = moment(this.ticketFilter.createdAtEnd).unix()
        }

        if (this.ticketFilter.station) {
          queryParams['tickets-station'] = this.ticketFilter.station;
        }

        if (this.ticketFilter.nature) {
          queryParams['tickets-nature'] = this.ticketFilter.nature;
        }

        if (this.ticketFilter.itemIdfm) {
          queryParams['tickets-itemidfm'] = this.ticketFilter.itemIdfm;
        }

        if (this.ticketFilter.categorieIdfm) {
          queryParams['tickets-categorieidfm'] = this.ticketFilter.categorieIdfm;
        }

        if (this.ticketFilter.prestationhf) {
          queryParams['interventions'] = this.ticketFilter.prestationhf;
        }

        if (this.ticketFilter.intervenant) {
          queryParams['tickets-intervenant'] = this.ticketFilter.intervenant;
        }

        if (this.ticketFilter.penalitede) {
          queryParams['penalite-min'] = this.ticketFilter.penalitede
        }

        if (this.ticketFilter.penalitea) {
          queryParams['penalite-max'] = this.ticketFilter.penalitea
        }

        if (this.ticketFilter.status) {
          queryParams['tickets-status'] = this.ticketFilter.status
        }

        this.searchDesktopParams = queryParams;
        let url = '';
        if (this.$route.query.version === 'v1') {
          url = `${process.env.VUE_APP_API_BASE_URL}/tickets?${qs.stringify(queryParams)}`
        } else {
          url = `${process.env.VUE_APP_API_BASE_URL}/tickets/v2?${qs.stringify(queryParams)}`
        }

        url = `${process.env.VUE_APP_API_BASE_URL}/tickets?${qs.stringify(queryParams)}`

        axios.get(url)
            .then((res) => {
              this.datas = []
              this.datas.push(...res.data.map(ticket => {
                return {
                  dateCreation: ticket['date-creation'],
                  station: ticket['tickets-station'],
                  idfm: ticket['tickets-item-idfm'],
                  nature: ticket['tickets-nature-demande'],
                  intervenant: ticket['tickets-intervenant'],
                  sagai: ticket['tickets-n-sagai'],
                  ot: ticket['tickets-n-ot'],
                  interventions: ticket['interventions'],
                  _id: ticket._id,
                  id: ticket.id ? ticket.id : null,
                  status: ticket['tickets-status']
                }
              }))
            })
      }
    },
    calcStats() {
      if (this.isInStandaloneMode) {
        DB.find({
          selector: {type: 'ticket'},
          fields: ['_id', 'id', 'date-creation', 'tickets-station', 'tickets-nature-demande', 'tickets-item-idfm', 'tickets-intervenant', 'tickets-n-sagai', 'tickets-n-ot', 'tickets-status', 'interventions'],
        }).then(res => {
          this.nbEnCours = res.docs.filter(ticket => ticket['tickets-status'] === 'En cours').length;
          this.nbOuvert = res.docs.filter(ticket => ticket['tickets-status'] === 'Ouvert').length;
          this.nbVisaManager = res.docs.filter(ticket => ticket['tickets-status'] === 'Manager').length;
          this.nbNft = res.docs.filter(ticket => ticket['tickets-status'] === 'NFT').length;
          this.nbCloture = res.docs.filter(ticket => ticket['tickets-status'] === 'Cloturé').length;
          this.nbAbandon= res.docs.filter(ticket => ticket['tickets-status'] === 'Abandon').length;
        })
      } else {
        axios.get(`${process.env.VUE_APP_API_BASE_URL}/tickets/stats`,)
            .then((res) => {
              this.nbEnCours = res.data['En cours'];
              this.nbOuvert = res.data['Ouvert'];
              this.nbVisaManager = res.data['Manager'];
              this.nbNft = res.data['NFT'];
              this.nbCloture = res.data['Cloturé'];
              this.nbAbandon= res.data['Abandon']
            })
      }
    },
    resetSearchForm() {
      console.log("click")
      this.$refs.filterpopin.resetForm()
    }
  },
  watch: {
    ticketFilter: {
      handler: function (val) {
        this.searchTicket()

        this.searchFromEmpty = true;
        Object.keys(val).forEach(key => {
          if (val[key]) {
            this.searchFromEmpty = false;
          }
        })
      },
      deep: true
    }
  }
}
</script>

<style>

@media (max-width: 768px) {
  #ticket-sumary {
    position: fixed;
    right: 0;
    top: 15%;
    width: 250px;
    #height: 80%;
    #overflow-y: auto;
  }
}

</style>
