<template>
  <div>
    <div :class="['icon-number', {'disabled': disabled}]" @click="openFilter = !openFilter">
      <span class="text" :class="{'text-desktop': !isInStandaloneMode}">Filtre</span>
      <div class="icon d-flex justify-content-center align-items-center">
        <font-awesome-icon
            icon="filter"
        />
      </div>
    </div>
    <div :class="['filter-container', {'invisible': !openFilter}, {'visible': openFilter}]">
      <b-form-row>
        <b-col>
          <b-form-group
              id="input-status-label"
              label="Status"
              label-for="input-status"
              label-class="form-label"
          >
            <b-form-select id="input-status" class="ticket-custom-select-filter" v-model="form.status" :options="status"></b-form-select>
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <b-form-group
              id="input-sagai-label"
              label="N°Sagai"
              label-for="input-sagai"
              label-class="form-label"
          >
            <b-form-input
                id="input-sagai"
                v-model="form.sagai"
                type="text"
                placeholder=""
                class="ticket-form-control-filter"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group
              id="input-ot-label"
              label="N°OT"
              label-for="input-ot"
              label-class="form-label"
          >
            <b-form-input
                id="input-ot"
                v-model="form.ot"
                type="text"
                placeholder=""
                class="ticket-form-control-filter"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <b-form-group
              id="input-station-label"
              label="Station"
              label-for="input-station"
              label-class="form-label"
          >
            <b-form-select id="input-station" class="ticket-custom-select-filter" v-model="form.station" :options="stations"></b-form-select>
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <b-form-group
              id="input-naturedemande-label"
              label="Nature demande"
              label-for="input-naturedemande"
              label-class="form-label"
          >
            <b-form-select id="input-naturedemande" class="ticket-custom-select-filter" v-model="form.nature" :options="natureDemande"></b-form-select>
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <b-form-group
              id="input-item-idfm-label"
              label="Item IDFM"
              label-for="input-item-idfm"
              label-class="form-label"
          >
            <b-form-select id="input-item-idfm" class="ticket-custom-select-filter" v-model="form.itemIdfm" :options="itemIdfm"></b-form-select>
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <b-form-group
              id="input-categorie-idfm-label"
              label="Catégorie IDFM"
              label-for="input-categorie-idfm"
              label-class="form-label"
          >
            <b-form-select id="input-categorie-idfm" class="ticket-custom-select-filter" v-model="form.categorieIdfm" :options="categorieIdfm"></b-form-select>
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <b-form-group
              id="input-prestationhf-label"
              label="Prestation HF"
              label-for="input-prestationhf"
              label-class="form-label"
          >
            <b-form-select id="input-prestationhf" class="ticket-custom-select-filter" v-model="form.prestationhf" :options="prestationshf"></b-form-select>
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row v-if="!this.$store.getters.userIsAgentPame && !this.$store.getters.userIsPrestataireHorsRatp && !this.$store.getters.userIsPrestataireRatp">
        <b-col>
          <b-form-group
              id="input-intervenant-label"
              label="Intervenant"
              label-for="input-intervenant"
              label-class="form-label"
          >
            <b-form-select id="input-intervenant" :disabled="this.$store.getters.userIsAgentPame || this.$store.getters.userIsPrestataireHorsRatp || this.$store.getters.userIsPrestataireRatp" class="ticket-custom-select-filter" v-model="form.intervenant" :options="intervenants"></b-form-select>
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <p class="no-margin">Pénalité</p>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <b-form-group
              id="input-penalitede-label"
              label="De"
              label-for="input-penalitede"
              label-class="form-label"
          >
            <b-form-input
                id="input-penalitede"
                v-model="form.penalitede"
                type="text"
                placeholder=""
                class="ticket-form-control-filter"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group
              id="input-penalitea-label"
              label="A"
              label-for="input-penalitea"
              label-class="form-label"
          >
            <b-form-input
                id="input-penalitea"
                v-model="form.penalitea"
                type="text"
                placeholder=""
                class="ticket-form-control-filter"
            ></b-form-input>
          </b-form-group>
      </b-col>
      </b-form-row>

      <b-row class="mt-3">
        <b-col class="text-right">
          <p @click="resetForm" class="no-margin">Effacer <font-awesome-icon
              icon="filter"
          /></p>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import mixin from "@/mixin";

export default {
  name: "FilterPopin",
  mixins: [mixin],
  data() {
    return {
      openFilter: false,
      form: {
        sagai: null,
        ot: null,
        createdAtStart: null,
        createdAtEnd: null,
        station: null,
        nature: null,
        itemIdfm: null,
        categorieIdfm: null,
        prestationhf: null,
        intervenant: null,
        penalitede: null,
        penalitea: null
      }
    }
  },
  watch: {
    form: {
      handler: function (val) {
        this.$store.dispatch('setTicketFilter', val)
      },
      deep: true
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    bgStyle() {
      if (this.colorBg) {
        return {'background-color': this.colorBg}
      }

      if (this.colorBgGradient) {
        return {'background-image': this.colorBgGradient}
      }

      return {}
    },
  },
  mounted() {
    this.loadStation();
    this.loadStatus();
    if (this.$store.getters.userIsPrestataireHorsRatp || this.$store.getters.userIsPrestataireRatp) {
      this.form.intervenant = this.$store.getters.getCurrentIdentifiantUser
    } else {
      this.loadIntervenantTicket()
    }
    this.loadNatureDemande()
    this.loadItemIdfm()
    this.loadCategorieIdfm()
    this.loadPrestationHf()
  },

  methods: {
    resetForm() {
      this.form = {
        status: null,
        sagai: null,
        ot: null,
        createdAtStart: null,
        createdAtEnd: null,
        station: null,
        nature: null,
        itemIdfm: null,
        categorieIdfm: null,
        prestationhf: null,
        intervenant: null,
        penalitede: null,
        penalitea: null
      }
    }
  }
}
</script>

<style scoped>
.icon-number {
  width: 30px;
  height: 44.7px;
  #margin-right: 8px;
  text-align: center;
  padding: 6.4px 2px 2.9px 2px;
  border-radius: 4px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.05);
  background-color: var(--blanc);
}

.icon-number span {
  display: block;
}

.icon-number .text {
  font-size: 8px;
  font-weight: normal;
  text-align: center;
  color: var(--couleur-texte)
}

.icon-number .text-desktop {
  font-size: 10px;
}

.icon-number .icon {
  margin-top: 5px;
}

.disabled .text {
  color: var(--brown-grey);
}

.text {
  color: var(--couleur-texte)
}

.filter-container {
  position: absolute;
  top: 54.7px;
  left: -70px;
  width: 200px;
  background-color: var(--blanc);
  z-index: 100;
  padding: 10px;
  border-radius: 5px;

  border: solid 1px var(--contours);
}

.ticket-form-control-filter, .ticket-custom-select-filter {
  height: 20px;
  padding: 3px;
  font-size: 10px;
}

.ticket-form-control-filter::placeholder {
  font-size: 10px !important;
}

</style>
