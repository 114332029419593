<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <h1>IndexDB</h1>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-button @click="createDatabase">Create DB</b-button>
        <b-button @click="loadImageFromAPI">Load Image from API</b-button>
        <b-button @click="listAllImages">List all images</b-button>
        <b-button @click="getAllKeys">Get All Keys</b-button>
        <b-button @click="showNotif">Show Notif</b-button>

      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import axios from "axios";
import dbMixin from "@/dbMixin";

export default {
  mixins: [dbMixin],
  data() {
    return {
      db: null
    }
  },
  methods: {
    getAllKeys() {
      const request = indexedDB.open("ticketprop", 1);

      request.onsuccess = (event) => {
        const db = event.target.result;
        const transaction = db.transaction(["images"], "readonly");
        const objectStore = transaction.objectStore("images");
        const request = objectStore.getAllKeys();

        request.onsuccess = (event) => {
          console.log('List all keys', request.result)
        };
      };
    },
    createDatabase() {
      const dbName = "ticketprop";

      const request = indexedDB.open(dbName, 1);

      this.db = request;

      request.onerror = (event) => {
        // Handle errors.
        console.error('Error during creation of database', event)
      };
      request.onupgradeneeded = async (event) => {
        const db = event.target.result;

        // Create an objectStore to store image
        const objectStoreImage = db.createObjectStore("images");
        const objectStoreImageToUpload = db.createObjectStore("imagesToUpload");

        // Use transaction oncomplete to make sure the objectStore creation is
        // finished before adding data into it.
        await Promise.all(
            [
                new Promise((resolve, reject) => {
                  objectStoreImage.transaction.oncomplete = (event) => {
                    console.debug(`Object store images created`)
                    resolve()
                  };
                }),
                new Promise((resolve, reject) => {
                  objectStoreImageToUpload.transaction.oncomplete = (event) => {
                    console.debug(`Object store images to upload created`)
                    resolve()
                  };
                })
            ]
        )
        console.info('Database created')
      };
    },

    storeImage(imageResponse, imageKey, db, storeObject = "images", type = 'image/png') {
      return new Promise((resolve, reject) => {
        const blob = new Blob([imageResponse], {type: type});
        const transaction = db.transaction(["images"], "readwrite");
        const objectStore = transaction.objectStore("images");
        const request = objectStore.add(blob, imageKey);
        request.onsuccess = (event) => {
          console.debug(`Image ${imageKey} added into ${storeObject}`, )
          resolve();
        };
        request.onerror = (event) => {
          console.error(`Error while adding image ${imageKey} into ${storeObject}`, event)
          reject();
        };
      })
    },
    listAllImages() {
      const request = indexedDB.open("ticketprop", 1);

      request.onsuccess = (event) => {
        const db = event.target.result;
        const transaction = db.transaction(["images"], "readonly");
        const objectStore = transaction.objectStore("images");
        const request = objectStore.getAll();

        request.onsuccess = (event) => {
          console.log('List all images', request.result)
        };
      };
    },
    showNotif() {
      this.$notify({ type: 'success', title: 'Synchronisation', text: 'Toutes les images ont été synchronisées' })
    }
  }
}
</script>


<style scoped>

</style>
