<template>
  <div class="stat-number" :style="[bgStyle]">
    <span class="text" :class="{'text-desktop': !isInStandaloneMode}" :style="[{'color': colorText}]">{{ title }}</span>
    <span class="number" :style="[{'color': colorNumber}]">{{ number }}</span>
  </div>
</template>

<script>
import mixin from "@/mixin";

export default {
  name: "StatNumber",
  mixins: [mixin],
  props: {
    title: {
      type: String,
      required: true
    },
    number: {
      type: String,
      required: true
    },
    colorText: {
      type: String,
      required: true
    },
    colorNumber: {
      type: String,
      required: true
    },
    colorBg: {
      type: String
    },
    colorBgGradient: {
      type: String
    }
  },
  computed: {
    bgStyle() {
      if (this.colorBg) {
        return {'background-color': this.colorBg}
      }

      if (this.colorBgGradient) {
        return {'background-image': this.colorBgGradient}
      }

      return {}
    }
  }
}
</script>

<style scoped>
.stat-number {
  max-width: 111.9px;
  height: 44.7px;
  margin-right: 8px;
  border-radius: 4px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.05);
  #background-image: linear-gradient(to right, var(--rouge), #6e1401 100%);
}

.stat-number span {
  display: block;
}

.stat-number .text {
  font-size: 8px;
  font-weight: bold;
  padding-top: 5px;
  padding-left: 5px;
}

.stat-number .text-desktop {
  font-size: 10px;
}

.stat-number .number {
  font-size: 20px;
  font-weight: bold;
  text-align: right;
  position: relative;
  bottom: 0;
  right: 10px;
}
</style>
