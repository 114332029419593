import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import Notifications from 'vue-notification'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faClock,
  faPen,
  faUser,
  faBell,
  faBellSlash,
  faFile,
  faFloppyDisk,
  faFileDownload,
  faEye,
  faSpinner,
  faExclamationCircle,
  faTrash,
  faSearch,
  faCalendarCheck,
  faBars,
  faList,
  faFilter,
    faCog,
  faMagnifyingGlass,
    faTicket,
    faPlus,
  faXmark,
  faArrowRight,
  faCircleCheck,
  faCircleXmark,
  faRotateLeft,
  faWifi,
  faTimesCircle,
  faCheck,
  faCircleExclamation,
  faSync,
  faDatabase,
  faImage
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import moment from 'moment'
import Vuelidate from 'vuelidate'

Vue.use(Vuelidate)
Vue.use( CKEditor );
moment.locale('fr')
library.add([faClock, faImage, faSync, faDatabase, faCircleExclamation, faCheck, faTimesCircle, faWifi, faRotateLeft, faCircleCheck, faCircleXmark, faXmark, faPen, faFloppyDisk, faArrowRight, faUser, faCog, faList, faPlus, faBell, faTicket, faBellSlash, faFile, faFileDownload, faEye, faSpinner, faExclamationCircle, faTrash, faSearch, faCalendarCheck, faBars, faFilter, faMagnifyingGlass]);
Vue.use(Notifications)
Vue.component('FontAwesomeIcon', FontAwesomeIcon)

Vue.filter('date', function(date) {
  return moment(date).format('L')
})

Vue.filter('datetime', function(date) {
  return moment(date).format('L') + ' ' + moment(date).format('LTS')
})

Vue.filter('uppercase', function(value) {
  return value.toUpperCase();
})

Vue.filter('amount', function(value) {
  if (value) {
    return '~';
  }
  return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, currencyDisplay: 'symbol' }).format(value)
})

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import App Global SCSS
import './app.scss'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.config.productionTip = false

//Sentry.init({
//  Vue,
//  dsn: "https://19f904a2d0a448958c1a748b1862b5f4@o4504709311299584.ingest.sentry.io/4504709312872448",
//  integrations: [
//    new BrowserTracing({
//      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//      tracePropagationTargets: ["localhost", "ticket-prop.fr", /^\//],
//    }),
//  ],
//  // Set tracesSampleRate to 1.0 to capture 100%
//  // of transactions for performance monitoring.
//  // We recommend adjusting this value in production
//  tracesSampleRate: 1.0,
//});

store.dispatch('setVersion', '1.1.1')

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
