import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/views/Login/LoginView";
import AppLayout from "@/layout/AppLayout";
import Home from "@/views/Home/Home";
import TicketForm from "@/views/Demande/TicketForm";
import ListTicket from "@/views/Demande/ListTicket";
import UserList from "@/views/Utilisateur/UserList";
import UserForm from "@/views/Utilisateur/UserForm";
import TicketStatusList from "@/views/Config/TicketStatusList";
import Debug from "@/views/Debug";
import ExportExcel from "@/views/Export/ExportExcel";
import AttributeConfig from "@/views/Config/AttributeConfig";

import Cookies from 'js-cookie'
import Statistiques from "@/views/Statistique/Statistiques.vue";
import MobileLayout from "@/layout/MobileLayout.vue";
import StationList from "@/views/Mobile/StationList.vue";
import MobileListTicket from "@/views/Mobile/MobileListTicket.vue";
import MobileEditTicket from "@/views/Mobile/MobileEditTicket.vue";
import Test from "@/views/Test.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'login',
        component: Login,
      },
    ]
  },
  {
    path: '/',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'tickets-list',
        component: ListTicket,
        meta: {pageTitle: 'Liste des tickets'}
      },
    ]
  },
  {
    path: '/validation-hf',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'validation-hf',
        component: ListTicket,
        meta: {pageTitle: 'Validation HF'}
      },
    ]
  },
  {
    path: '/home',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'home',
        component: Home,
        meta: {pageTitle: 'Home'}
      }
    ]
  },
  {
    path: '/tickets',
    component: AppLayout,
    children: [
      {
        path: 'creation',
        name: 'tickets-add',
        component: TicketForm,
        meta: {pageTitle: 'Nouveau ticket'}
      },
      {
        path: ':id',
        name: 'tickets-update',
        component: TicketForm,
        meta: {pageTitle: 'Nouveau ticket'}
      }
    ],
  },
  {
    path: '/utilisateurs',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'user-list',
        component: UserList,
        meta: {pageTitle: 'Liste des utilisateurs'}
      },
      {
        path: 'edit/:id',
        name: 'user-edit',
        component: UserForm,
        meta: {pageTitle: 'Edition utilisateur'}
      },
      {
        path: 'new',
        name: 'user-new',
        component: UserForm,
        meta: {pageTitle: 'Ajout d\'un utilisateur'}
      },
    ]
  },
  {
    path: '/tickets-status',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'ticket-status-list',
        component: TicketStatusList,
        meta: {pageTitle: 'Status des tickets'}
      },
    ]
  },
  {
    path: '/debug',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'debug',
        component: Debug,
        meta: {pageTitle: 'Debug'}
      },
    ]
  },
  {
    path: '/attributs',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'list-config',
        component: AttributeConfig,
        meta: {pageTitle: 'Listes paramétrables'}
      },
    ]
  },
  {
    path: '/export',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'export',
        component: ExportExcel,
        meta: {pageTitle: 'Export'}
      },
    ]
  },
  {
    path: '/statistiques',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'statistiques',
        component: Statistiques,
        meta: {pageTitle: 'Statistiques'}
      },
    ]
  },
  {
    path: '/mobile',
    component: MobileLayout,
    children: [
      {
        path: 'login',
        name: 'mobile-login',
        component: Login,
      },
      {
        path: 'station',
        name: 'mobile-station-list',
        component: StationList,
        meta: {pageTitle: 'Choix station'}
      },
      {
        path: 'ticket',
        name: 'mobile-ticket-list',
        component: MobileListTicket,
      },
      {
        path: 'ticket/:ticketId',
        name: 'mobile-ticket-edit',
        component: MobileEditTicket,
      },
    ]
  },
  {
    path: '/indexdb',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'indexdb',
        component: Test,
        meta: {pageTitle: 'indexdb'}
      }
    ]
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.name !== 'login' && to.name !== 'mobile-login' && !Cookies.get('login')) {
    next({name: 'login', replace: true})
  } else {
    next()
  }
})

export default router
