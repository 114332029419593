<template>
  <div @click="$emit('click')" :class="['icon-number', {'disabled': disabled}]">
    <span class="text" :class="{'text-desktop': !isInStandaloneMode}">Effacer</span>
    <div class="icon d-flex justify-content-center align-items-center">
      <font-awesome-icon
          icon="filter"
          class="icon-color"
          :class="{'disabled': disabled}"
      />
      <font-awesome-icon
          icon="xmark"
          class="icon-color cross"
          :class="{'disabled': disabled}"

      />
    </div>
  </div>
</template>

<script>
import mixin from "@/mixin";

export default {
  name: "FilterReset",
  mixins: [mixin],
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style scoped>
.icon-number {
  #width: 30px;
  height: 44.7px;
  #margin-right: 8px;
  text-align: center;
  padding: 6.4px 2px 2.9px 2px;
  border-radius: 4px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.05);
  background-color: var(--blanc);
  #background-image: linear-gradient(to right, var(--rouge), #6e1401 100%);
}

.icon-number span {
  display: block;
}

.icon-number .text {
  font-size: 8px;
  font-weight: normal;
  text-align: center;
  color: var(--couleur-texte)
}

.icon-number .text-desktop {
  font-size: 10px;
}

.icon-number .icon {
  margin-top: 5px;
}

.disabled .text {
  color: var(--brown-grey);
}
.disabled .icon-color {
  color: var(--brown-grey);
}
.cross {
  position: absolute;
  top: 25px;
}
</style>
