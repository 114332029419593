<template>
  <b-modal
      id="modalDevisEtValidation"
      ref="modal"
      title="Devis et validation"
      :visible="$store.state.showModalDevisEtValidation"
      size="lg"
      cancel-title="Fermer"
      ok-title="Valider les devis"
      @ok="validateDevis"
      @hide="$store.dispatch('hideModalDevisEtValidation')"
  >
    <!-- block Devis -->
    <b-row>
      <b-col>
        <div class="form-card">
          <b-row>
            <b-col>
              <b-form-row>
                <b-col>
                  <b-form-group
                      id="input-financement-label"
                      label="Financement"
                      label-for="input-financement"
                      label-class="form-label"
                  >
                    <b-form-input
                        id="input-financement"
                        v-model="form.devisFinancement"
                        type="text"
                        placeholder=""
                        class="ticket-form-control"
                    ></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col>
                  <b-form-group
                      id="input-datenftdemande-label"
                      label="Date NFT demandée"
                      label-for="input-datenftdemande"
                      label-class="form-label"
                  >
                    <b-form-datepicker id="input-datenftdemand" placeholder="Sélectionner une date" locale="fr" v-model="form.devisDateNftDemande"></b-form-datepicker>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col>
                  <b-form-group
                      id="input-referencedevis-label"
                      label="Référence devis"
                      label-for="input-referencedevis"
                      label-class="form-label"
                  >
                    <b-form-input
                        id="input-referencedevis"
                        v-model="form.referenceDevis"
                        type="text"
                        placeholder=""
                        class="ticket-form-control"
                    ></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col>
                  <b-form-group
                      id="input-datedevis-label"
                      label="Date NFT devis"
                      label-for="input-datedevis"
                      label-class="form-label"
                  >
                    <b-form-datepicker id="input-datedevis" placeholder="Sélectionner une date" locale="fr" v-model="form.dateDevis"></b-form-datepicker>
                  </b-form-group>
                </b-col>

                <b-col>
                  <b-form-group
                      id="input-nomemetteurdevis-label"
                      label="Nom émetteur devis"
                      label-for="input-nomemetteurdevis"
                      label-class="form-label"
                  >
                    <b-form-input
                        id="input-nomemetteurdevis"
                        v-model="form.nomEmetteurDevis"
                        type="text"
                        placeholder=""
                        class="ticket-form-control"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col>
                  <b-form-group
                      id="input-montantdevis-label"
                      label="Montant devis"
                      label-for="input-montantdevis"
                      label-class="form-label"
                  >
                    <b-input-group append="€">
                      <b-form-input
                          id="input-montantdevis"
                          v-model="form.montantDevis"
                          type="text"
                          class="ticket-form-control"
                          placeholder=""
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col>
                  <b-form-group
                      id="input-dateprevusnftdevis-label"
                      label="Date prévue NFT devis"
                      label-for="input-dateprevusnftdevis"
                      label-class="form-label"
                  >
                    <b-form-datepicker id="input-dateprevusnftdevis"  placeholder="Sélectionner une date" locale="fr" v-model="form.datePrevueNftDevis"></b-form-datepicker>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <!-- FIN block Devis -->
  </b-modal>
</template>

<script>
export default {
  name: "ModalDevisEtValidation",
  data() {
    return {
      form: {
        devisFinancement: null,
        devisDateNftDemande: null,
        referenceDevis: null,
        dateDevis: null,
        nomEmetteurDevis: null,
        montantDevis: null,
        datePrevueNftDevis: null,
      }
    }
  },
  methods: {
    validateDevis() {
      this.$emit('validateDevis', this.form);
    }
  }
}
</script>

<style scoped>

</style>
