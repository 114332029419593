<template>
  <div :class="['ticket-status', cssClass, 'd-flex justify-content-center align-items-center', {'large': size === 'lg'}, {'text-large': summary && isInStandaloneMode}]">
    {{ ticketStatus }}
  </div>
</template>

<script>
import mixin from "@/mixin";

export default {
  name: "TicketStatus",
  mixins: [mixin],
  computed: {
    cssClass() {
      return this.ticketStatus.toLowerCase().replace(' ', '-')
    }
  },
  props: {
    ticketStatus: {
      type: String,
      required: true
    },
    size: {
      type: String,
    },
    summary: {
      type: Boolean
    }
  }
}
</script>

<style scoped>
.ticket-status {
  height: 14px;
  padding: 3px 10px 2px;
  font-size: 9px;
  border-radius: 25px;
  background-color: #ffffff;
}

.ticket-status.text-large {
  font-size: 12px;
}

.large {
  height: 25px !important;
  font-size: 12px !important;
}
.ouvert {
  border: solid 1px #c04191;
  #background-color: var(--blanc);
  color: #c04191
}

.manager {
  border: solid 1px #ee8b16;
  #background-color: var(--blanc);
  color: #ee8b16
}

.en-cours {
  border: solid 1px #c3bb00;
  #background-color: var(--blanc);
  color: #c3bb00;
}

.nft {
  border: solid 1px #5d981a;
  #background-color: var(--blanc);
  color: #5d981a;
}

.cloturé {
  border: solid 1px #1a8398;
  #background-color: var(--blanc);
  color: #1a8398;
}

.abandon {
  border: solid 1px #707070;
  #background-color: var(--blanc);
  color: #707070;
}
</style>
