<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-form-group
            id="input-firstname-label"
            label="Prénom"
            label-for="input-firstname"
            label-class="form-label"
        >
          <b-form-input
              id="input-firstname"
              v-model="user.firstname"
              type="text"
              placeholder=""
              class="ticket-form-control"
          ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group
            id="input-lastname-label"
            label="Nom"
            label-for="input-lastname"
            label-class="form-label"
        >
          <b-form-input
              id="input-lastname"
              v-model="user.lastname"
              type="text"
              placeholder=""
              class="ticket-form-control"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
            id="input-identifiant-label"
            label="Identifiant"
            label-for="input-identifiant"
            label-class="form-label"
        >
          <b-form-input
              id="input-identifiant"
              v-model="user.identifiant"
              type="text"
              placeholder=""
              :disabled="$route.params.id"
              class="ticket-form-control"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
            id="input-email-label"
            label="Email"
            label-for="input-email"
            label-class="form-label"
        >
          <b-form-input
              id="input-email"
              v-model="user.email"
              type="email"
              placeholder=""
              class="ticket-form-control"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
            id="input-password-label"
            label="Mot de passe"
            label-for="input-password"
            label-class="form-label"
        >
          <b-form-input
              id="input-password"
              v-model="user.password"
              type="password"
              placeholder=""
              class="ticket-form-control"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
            id="input-profil-label"
            label="Profil"
            label-for="input-profil"
            label-class="form-label"
        >
          <b-form-select id="input-profil" class="ticket-custom-select" v-model="user.groupname" :options="userProfils"></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-button @click="save">Sauvegarder</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from "axios";
import mixin from "@/mixin";
export default {
  name: "UserList",
  mixins: [mixin],
  data() {
    return {
      user: {
        firstname: null,
        lastname: null,
        password: null,
        groupname: null,
        email: null,
        identifiant: null,
      },
      userId: null,
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.userId = this.$route.params.id;
      this.getUser()
    }
  },
  methods: {
    getUser() {
      axios.get(`${process.env.VUE_APP_API_BASE_URL}/users/${this.userId}`)
          .then((res) => {
            this.user = {...this.user, ...res.data}
          })
    },
    save() {
      if (this.userId) {
        // update
        axios.put(`${process.env.VUE_APP_API_BASE_URL}/users/${this.userId}`, this.user)
            .then(() => {
              this.$router.push({name: 'user-list'})
            })
      } else {
        // create
        axios.post(`${process.env.VUE_APP_API_BASE_URL}/users`, this.user)
            .then(() => {
              this.$router.push({name: 'user-list'})
            })
      }
    }
  }
}
</script>

<style>

</style>
