<template>
  <div @click="goAddNewTicket" class="btn-new-demande d-flex justify-content-center align-items-center">
    <font-awesome-icon class="icon" icon="plus"  />
    Nouveau Ticket
  </div>
</template>

<script>
export default {
  name: "BtnNewDemande",
  methods: {
    goAddNewTicket() {
      this.$router.push({name: 'tickets-add'})
    }
  }
}
</script>

<style scoped>
  .btn-new-demande {
    cursor: pointer;
    height: 45.4px;
    width: 100%;
    padding: 14px 10px 10.4px 10px;
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(97deg, var(--orange) 0%, var(--rouge) 98%);
    text-transform: uppercase;
    color: var(--blanc);
    font-size: 12px;
    font-weight: bold;
  }

  .btn-new-demande .icon {
    margin-right: 15px;
  }
</style>
