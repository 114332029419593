<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <h1>Statistiques</h1>
      </b-col>
    </b-row>
    <b-tabs>
      <b-tab title="Netteté">
        <b-row class="mt-2">
          <b-col>
            <div class="big-number"><span>{{ netteteStat.ticketNonCloture }}</span> tickets ouverts non cloturés sur les 12 derniers mois</div>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <b-form-group
                id="input-station-label"
                label="Station"
                label-for="input-station"
                label-class="form-label"
            >
              <b-form-select id="input-station" class="ticket-custom-select" v-model="formNettete.station" :options="stations"></b-form-select>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group
                id="input-itemidfm-label"
                label="Item IDFM"
                label-for="input-itemidfm"
                label-class="form-label"
            >
              <b-form-select id="input-itemidfm" class="ticket-custom-select" v-model="formNettete.itemidfm" :options="itemsIdfm"></b-form-select>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group
                id="input-intervenant-label"
                label="Intervenant"
                label-for="input-intervenant"
                label-class="form-label"
            >
              <b-form-select id="input-intervenant" class="ticket-custom-select" v-model="formNettete.intervenant" :options="intervenants"></b-form-select>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group
                id="input-nature-label"
                label="Nature demande"
                label-for="input-nature"
                label-class="form-label"
            >
              <b-form-select multiple id="input-nature" class="ticket-custom-select" v-model="formNettete.nature" :options="natureDemande"></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <Bar
                id="nettete-ouverture"
                :options="chartNetteteOuvertOption"
                :data="chartNetteOuvertData"
            />
          </b-col>
          <b-col>
            <Bar
                id="nettete-cloture"
                :options="chartNetteteClotureOption"
                :data="chartNetteClotureData"
            />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Validation">
        <b-row class="mt-2">
          <b-col>
            <b-table thead-class="table-head" :fields="fieldValidation" :items="validationStats.keyNumbers">
              <template #cell(engage)="data">
                {{ data.item.engage }} €
              </template>
              <template #cell(facture)="data">
                {{ data.item.facture }} €
              </template>
              <template #cell(annuel)="data">
                {{ data.item.annuel }} €
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <b-form-group
                id="input-intervenant-label"
                label="Intervenant"
                label-for="input-intervenant"
                label-class="form-label"
            >
              <b-form-select id="input-intervenant" class="ticket-custom-select" v-model="fomValidation.intervenant" :options="intervenants"></b-form-select>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group
                id="input-nature-label"
                label="Nature demande"
                label-for="input-nature"
                label-class="form-label"
            >
              <b-form-select multiple id="input-nature" class="ticket-custom-select" v-model="fomValidation.nature" :options="natureDemande"></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <ChartLine
                id="validation-engage"
                :options="chartValidationEngageOption"
                :data="chartValidationEngageData"
            />
          </b-col>
          <b-col>
            <ChartLine
                id="validation-facture"
                :options="chartValidationFactureOption"
                :data="chartValidationFactureData"
            />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="IDFM">
        <b-row class="mt-2">
          <b-col>
            <b-form-file
                v-model="statFile"
                placeholder="Sélectionner le fichier excel IDFM ou glisser le ici"
                drop-placeholder="Glisser le fichier ici..."
                browse-text="Parcourir"
            ></b-form-file>
            <b-button @click="uploadStatFile" :disabled="uploadInProgress" class="mt-2" variant="primary">{{ btnStatFileUploadText }}</b-button>
          </b-col>
        </b-row>

        <hr>
        <b-row class="mt-2">
          <b-col>
            <b-form-group
                id="input-station-idfm-label"
                label="Station"
                label-for="input-station-idfm"
                label-class="form-label"
            >
              <b-form-select id="input-station-idfm" class="ticket-custom-select" v-model="formIdfm.station" :options="stationsIdfm"></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <!-- Penalité -->
            <b-row>
              <b-col>
                <h2>Pénalité {{ formIdfm.station ?? "toutes les stations" }}</h2>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-col>
                  <b-form-group
                      id="input-itemidfm-label-idfm"
                      label="Item IDFM"
                      label-for="input-itemidfm-idfm"
                      label-class="form-label"
                  >
                    <b-form-select multiple id="input-itemidfm-idfm" class="ticket-custom-select" v-model="formIdfm.itemidfm" :options="idfmItems"></b-form-select>
                  </b-form-group>
                </b-col>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <ChartLine
                    id="validation-facture"
                    :options="chartIdfmPenaliteOption"
                    :data="chartIdfmPenaliteData"
                />
              </b-col>
            </b-row>
            <!-- END Penalité -->
          </b-col>
        </b-row>

        <b-row>
          <!-- Note -->
          <b-col>
            <b-row>
              <b-col>
                <h2>Note {{ formIdfm.station ?? "toutes les stations" }}</h2>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                    id="input-categorie-label-idfm"
                    label="Catégorie"
                    label-for="input-categorie-idfm"
                    label-class="form-label"
                >
                  <b-form-select id="input-categorie-idfm" class="ticket-custom-select" v-model="formIdfm.categorie" :options="idfmCategorie"></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <ChartLine
                    id="validation-facture"
                    :options="chartIdfmNoteOption"
                    :data="chartIdfmNoteData"
                />
              </b-col>
            </b-row>
          </b-col>
          <!-- END Note -->
        </b-row>

      </b-tab>
    </b-tabs>
  </b-container>
</template>

<script>
import mixin from "@/mixin";
const itemIDFM = require('@/db/item_idfm.json');
import { Bar, Line as ChartLine } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, LinearScale, CategoryScale,
  PointElement,
  LineElement,
  } from 'chart.js'
import axios from "axios";
import {getCustomfieldBySlug} from "@/db/db";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement)
const COLORS_CHART = {
  'NETTOYAGE': 'rgb(255, 159, 64)',
  'LIGNE': 'rgb(83,192,75)',
  'MAINTENANCE': 'rgb(255, 99, 132)',
  'Ligne': 'rgb(83,192,75)',
  'Maintenance': 'rgb(255, 99, 132)',
  'Nettoyage': 'rgb(255, 159, 64)',
  'Global': 'rgb(54, 162, 235)',
}
export default {
  name: "Statistiques",
  mixins: [mixin],
  components: {
    Bar,
    ChartLine
  },
  data() {
    return {
      statFile: null,
      uploadInProgress: false,
      itemsIdfm: [],
      formNettete: {
        nature: null,
        station: null,
        itemidfm: null,
        intervenant: null
      },
      fomValidation: {
        nature: null,
        intervenant: null
      },
      formIdfm: {
        station: null,
        itemidfm: [],
        categorie: null,
      },
      categoriesIdfm: [
        { text: 'Tous', value: null },
        { text: 'Ligne', value: 'Ligne' },
        { text: 'Maintenance', value: 'Maintenance' },
        { text: 'Nettoyage', value: 'Nettoyage' },
      ],
      chartNetteteOuvertOption: {
        plugins: {
          title: {
            display: true,
            text: 'Nb tickets ouvert chaque mois (sur les 12 derniers mois flottants)'
          },
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
            ticks: {
              beginAtZero: true,
              callback: function(value) {if (value % 1 === 0) {return value;}}
            }
          }
        }
      },
      chartNetteteClotureOption: {
        plugins: {
          title: {
            display: true,
            text: 'Nb tickets cloturés chaque mois (sur les 12 derniers mois flottants)'
          },
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
            ticks: {
              beginAtZero: true,
              callback: function(value) {if (value % 1 === 0) {return value;}}
            }
          }
        }
      },
      chartValidationEngageOption: {
        plugins: {
          title: {
            display: true,
            text: 'Montant Engagement ( date validation manager )'
          },
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
            ticks: {
              beginAtZero: true,
              callback: function(value) {if (value % 1 === 0) {return value;}}
            }
          }
        }
      },
      chartValidationFactureOption: {
        plugins: {
          title: {
            display: true,
            text: 'Montant Facturation ( date cloture )'
          },
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
            ticks: {
              beginAtZero: true,
              callback: function(value) {if (value % 1 === 0) {return value;}}
            }
          }
        }
      },
      netteteStat: {
        months: [],
        valuesOuvert: [],
        valuesCloture: [],
        statsOuvert: {},
        statsCloture: {},
        ticketNonCloture: '~'
      },
      validationStats: {
        keyNumbers: [],
        months: [],
        montantEngagement: [],
        montantFacture: [],
      },
      fieldValidation: [
        {
          key: 'categorie',
          label: 'Catégorie',
          thClass: 'table-th-custom',
          tdClass: 'table-td-custom'
        },
        {
          key: 'engage',
          label: 'Montant engagé',
          thClass: 'table-th-custom',
          tdClass: 'table-td-custom'
        },
        {
          key: 'facture',
          label: 'Montant facturé',
          thClass: 'table-th-custom',
          tdClass: 'table-td-custom'
        },
        {
          key: 'annuel',
          label: 'Budget annuel',
          thClass: 'table-th-custom',
          tdClass: 'table-td-custom'
        }
      ],
      idfmNoteStats: {
        months: [],
        moyenneGlobal: 0.00,
        data: {}
      },
      idfmPenaliteStats: {
        months: [],
        sommeGlobal: 0.00,
        data: {}
      },
      stationsIdfm: [
        { value: null, text: 'Station' }
      ],
      idfmItems: [
        { value: null, text: 'Item IDFM' }
      ],
      idfmCategorie: [
        { value: null, text: 'Catégorie IDFM' }
      ]
    }
  },
  mounted() {
    this.loadStation();
    this.loadIntervenantTicket();
    this.loadNatureDemande();
    this.itemsIdfm = itemIDFM.map(item => {
      return { value: item.item, text: item.item }
    })

    this.loadNetteteStat();
    this.loadValidationStats();
    this.loadIdfmStat();
    this.loadStationIdfm();
    this.loadIdfmItem();
    this.loadIdfmCategorie();
  },
  computed: {
    btnStatFileUploadText() {
      if (this.uploadInProgress) {
        return 'Chargement...'
      } else {
        return 'Envoyer le fichier'
      }
    },
    chartNetteOuvertData() {
      return {
        labels: this.netteteStat.months,
        datasets: [
            ...this.netteteStat.valuesOuvert.map(field => {
              return {
                label: field,
                data: this.netteteStat.months.map(month => {
                  return this.netteteStat.statsOuvert[month][field] ?? 0
                }),
                backgroundColor: COLORS_CHART[field],
              }
            })
        ]
      }
    },
    chartNetteClotureData() {
      return {
        labels: this.netteteStat.months,
        datasets: [
          ...this.netteteStat.valuesOuvert.map(field => {
            return {
              label: field,
              data: this.netteteStat.months.map(month => {
                return this.netteteStat.statsCloture[month][field] ?? 0
              }),
              backgroundColor: COLORS_CHART[field],
            }
          })
        ]
      }
    },
    chartValidationEngageData() {
      return {
        labels: this.validationStats.months,
        datasets: [
          ...this.validationStats.months.map(month => {
            return {
              label: month,
              data: this.validationStats.months.map(month => {
                return this.validationStats.montantEngagement[month] ?? 0
              }),
              tension: 0.3,
              borderColor: 'rgb(0,0,0)',
            }
          })
        ]
      }
    },
    chartValidationFactureData() {
      return {
        labels: this.validationStats.months,
        datasets: [
          ...this.validationStats.months.map(month => {
            return {
              label: month,
              data: this.validationStats.months.map(month => {
                return this.validationStats.montantFacture[month] ?? 0
              }),
              tension: 0.3,
              borderColor: 'rgb(0,0,0)',
            }
          })
        ]
      }
    },
    chartIdfmNoteData() {
      return {
        labels: this.idfmNoteStats.months,
        datasets: [
            ...Object.keys(this.idfmNoteStats.data).map(field => {
              return {
                label: field,
                data: this.idfmNoteStats.months.map(month => {
                  return this.idfmNoteStats.data[field][month] ?? 0
                }),
                fill: false,
                borderColor: COLORS_CHART[field],
                backgroundColor: COLORS_CHART[field],
              }
            })
        ]
      }
    },
    chartIdfmNoteOption() {
      return {
        plugins: {
          title: {
            display: true,
            text: this.formIdfm.categorie ? `${this.formIdfm.station ?? ''} ${this.formIdfm.categorie} - Moyenne : ${parseFloat(this.idfmNoteStats.moyenneGlobal).toFixed(2)}%` : `${this.formIdfm.station ?? ''} Ligne 4 complète - Moyenne : ${parseFloat(this.idfmNoteStats.moyenneGlobal).toFixed(2)} %`
          },
        },
        responsive: true,
        scales: {
          y: {
            ticks: {
              callback: function(value) {if (value % 1 === 0) {return value;}},
              suggestedMin: 40
            }
          },
        }
      }
    },
    chartIdfmPenaliteData() {
      return {
        labels: this.idfmPenaliteStats.months,
        datasets: [
          ...Object.keys(this.idfmPenaliteStats.data).map(field => {
            let rgb = this.random_rgb()
            return {
              label: field,
              data: this.idfmPenaliteStats.months.map(month => {
                return this.idfmPenaliteStats.data[field][month] ?? 0
              }),
              fill: false,
              borderColor: rgb,
              backgroundColor: rgb,
            }
          })
        ]
      }
    },
    chartIdfmPenaliteOption() {
      return {
        plugins: {
          title: {
            display: true,
            text: !this.formIdfm.station ? 'Toutes les stations' : this.formIdfm.station
          },
        },
        responsive: true,
        scales: {
          y: {
            min: 15,
            beginAtZero: false,
            ticks: {
              callback: function(value) {if (value % 1 === 0) {return parseFloat(value);}},
            }
          }
        }
      }
    },
  },
  watch: {
    formNettete: {
      handler: function (val, oldVal) {
        this.loadNetteteStat();
      },
      deep: true
    },
    fomValidation: {
      handler: function (val, oldVal) {
        this.loadValidationStats();
      },
      deep: true
    },
    'formIdfm.itemidfm': {
      handler: function (val, oldVal) {
        this.loadIdfmPenaliteStat()
      },
    },
    'formIdfm.categorie': {
      handler: function (val, oldVal) {
        this.loadIdfmNoteStat()
      },
    },
    'formIdfm.station': {
      handler: function (val, oldVal) {
        this.loadIdfmStat();
      },
    },
  },
  methods: {
    uploadStatFile() {
      this.uploadInProgress = true

      let formData = new FormData();
      formData.append("file", this.statFile);
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/stats/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        console.log(response)
        this.loadIdfmStat();
      }).finally(() => {
        this.uploadInProgress = false;
      })
    },
    loadNetteteStat() {
      axios.get(`${process.env.VUE_APP_API_BASE_URL}/stats/nettete`, {
        params: {
          station: this.formNettete.station,
          itemidfm: this.formNettete.itemidfm,
          intervenant: this.formNettete.intervenant,
          nature: this.formNettete.nature
        }
      }).then(response => {
        this.netteteStat = response.data;
        console.log(response.data);
      })
    },
    loadValidationStats() {
      axios.get(`${process.env.VUE_APP_API_BASE_URL}/stats/validation`, {
        params: {
          intervenant: this.fomValidation.intervenant,
          nature: this.fomValidation.nature
        }
      }).then(response => {
        this.validationStats = response.data;
        console.log(response.data);
      })
    },
    loadIdfmNoteStat() {
      axios.get(`${process.env.VUE_APP_API_BASE_URL}/stats/idfm-note`, {
        params: {
          station: this.formIdfm.station,
          categorie: this.formIdfm.categorie
        }
      }).then(response => {
        this.idfmNoteStats = response.data;
        console.log(response.data);
      })
    },
    loadIdfmPenaliteStat() {
      axios.get(`${process.env.VUE_APP_API_BASE_URL}/stats/idfm-penalite`, {
        params: {
          station: this.formIdfm.station,
          item: this.formIdfm.itemidfm
        }
      }).then(response => {
        this.idfmPenaliteStats = response.data;
        console.log(response.data);
      })
    },
    loadIdfmStat() {
      this.loadIdfmNoteStat();
      this.loadIdfmPenaliteStat()
    },

    loadStationIdfm() {
      axios.get(`${process.env.VUE_APP_API_BASE_URL}/stats/idfm/station`).then(res => {
        this.stationsIdfm.push(...res.data.map((item) => {
          return { value: item, text: item }
        }))
      })
    },

    loadIdfmItem() {
      axios.get(`${process.env.VUE_APP_API_BASE_URL}/stats/idfm/item`).then(res => {
        this.idfmItems.push(...res.data.map((item) => {
          return { value: item, text: item }
        }))
      })
    },

    loadIdfmCategorie() {
      axios.get(`${process.env.VUE_APP_API_BASE_URL}/stats/idfm/categorie`).then(res => {
        this.idfmCategorie.push(...res.data.map((item) => {
          return { value: item, text: item }
        }))
      })
    },

    random_rgb() {
      let o = Math.round, r = Math.random, s = 255;
      return 'rgb(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ')';
    }
  }
}
</script>

<style lang="scss">
.big-number {
  font-size: 20px;
  color: var(--rouge);
  span {
    font-size: 30px;
    font-weight: bold;
    color: var(--rouge);
  }
}
</style>
