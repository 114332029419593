<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <h1>Debug</h1>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <p><span class="bold">Nombre ticket MySQL : {{ debugInfo.nbTicketsMySQL }}</span> </p>
        <p><span class="bold">Nombre ticket CouchDB : {{ debugInfo.nbTicketsCouchDB }}</span> </p>
        <p><span class="bold">Nombre non syncrhonisé dans la tablette : {{ debugInfo.ticketsIdsMySQLNotSyncOnCouchDB.length }}</span> </p>
        <p><span class="bold">Nombre non syncrhonisé depuis la tablette : {{ debugInfo.docIdNotSyncInMySQL.length }}</span> </p>



      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-row>
          <h3>Vue MySQL</h3>
        </b-row>
        <b-table thead-class="table-head" :fields="ticketsIdsMySQLFields" :items="debugInfo.ticketsIdsMySQL">
          <template #cell(doc_ic)="data">
            <span v-if="!data.item.doc_ic" class="bg-red color-white">NON SYNC</span>
            <span v-else>{{ data.item.doc_ic }}</span>
          </template>
          <template #cell(actions)="data">
            <b-button size="sm" variant="primary" @click="openTicket(data.item.ticketid)">Ouvrir</b-button>
            <b-button size="sm" :disabled="data.item.doc_ic" variant="primary" @click="syncCouchDBTicket(data.item.ticketid)">Sync Tablette</b-button>
            <b-button size="sm" variant="danger" @click="deleteTicket(data.item.ticketid)">Supprimer</b-button>
            <b-button size="sm" variant="info" @click="displayDiff(data.item.ticketid)">Diff</b-button>

          </template>
        </b-table>
      </b-col>

      <b-col>
        <b-row>
          <h3>Vue CouchDB</h3>
        </b-row>
        <b-table thead-class="table-head" :fields="docIdSyncInCouchDBFields" :items="debugInfo.docIdSyncInCouchDB">
          <template #cell(idMySQL)="data">
            <span v-if="!data.item.idMySQL" class="bg-red color-white">NON SYNC</span>
            <span v-else>{{ data.item.idMySQL }}</span>
          </template>
          <template #cell(actions)="data">
            <b-button size="sm" variant="primary" @click="openTicket(data.item.idMySQL)">Ouvrir</b-button>
            <b-button size="sm" :disabled="data.item.idMySQL" variant="primary" @click="syncCouchDBTicket(data.item.ticketid)">Sync Ordi</b-button>
            <b-button size="sm" variant="danger" @click="deleteTicket(data.item.id)">Supprimer</b-button>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <b-modal ref="ticketidff" hide-footer title="Diff ticket entre MySQL et CouchDB">
      <b-table thead-class="table-head" :items="ticketDiff" :fields="ticketDiffFields">
        <template #cell(status)="data">
          <div v-if="data.item.status === 'OK'" class="alert alert-success">{{ data.item.status }}</div>
          <div v-else class="alert alert-danger">{{ data.item.status }}</div>

        </template>
      </b-table>
      <b-button class="mt-3" variant="outline-danger" block @click="hideModal">Fermer</b-button>
    </b-modal>
  </b-container>
</template>

<script>
import axios from "axios";

export default {
  name: "Debug",
  data() {
    return {
      debugInfo: {
        ticketsIdsMySQL: [],
        ticketsIdsMySQLNotSyncOnCouchDB: [],
        docIdNotSyncInMySQL: [],
        docIdSyncInCouchDB: []
      },

      ticketDiff: [],

      ticketsIdsMySQLFields: [
        {
          key: 'ticketid',
          label: 'Identifiant du ticket (MySQL)',
          thClass: 'table-th-custom',
          tdClass: 'table-td-custom'
        },
        {
          key: 'doc_ic',
          label: 'ID (CouchDB)',
          thClass: 'table-th-custom',
          tdClass: 'table-td-custom'
        },
        {
          key: 'actions',
          label: 'Actions',
          thClass: 'table-th-custom',
          tdClass: 'table-td-custom'
        },
      ],
      docIdSyncInCouchDBFields: [
        {
          key: 'id',
          label: 'ID (CouchDB)',
          thClass: 'table-th-custom',
          tdClass: 'table-td-custom'
        },
        {
          key: 'idMySQL',
          label: 'Identifiant du ticket (MySQL)',
          thClass: 'table-th-custom',
          tdClass: 'table-td-custom'
        },
        {
          key: 'actions',
          label: 'Actions',
          thClass: 'table-th-custom',
          tdClass: 'table-td-custom'
        },
      ],
      ticketDiffFields: [
        {
          key: 'field',
          label: 'Champ',
          thClass: 'table-th-custom',
          tdClass: 'table-td-custom'
        },
        {
          key: 'couchdb',
          label: 'CouchDB',
          thClass: 'table-th-custom',
          tdClass: 'table-td-custom'
        },
        {
          key: 'mysql',
          label: 'MySQL',
          thClass: 'table-th-custom',
          tdClass: 'table-td-custom'
        },
        {
          key: 'status',
          label: 'Status',
          thClass: 'table-th-custom',
          tdClass: 'table-td-custom'
        },
      ]
    }
  },
  mounted() {
    this.loadDebugInfo();
  },
  methods: {
    loadDebugInfo() {
      axios.get(`${process.env.VUE_APP_API_BASE_URL}/debug/info`).then(res => {
        this.debugInfo = res.data
      })
    },
    syncCouchDBTicket(ticketid) {
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/debug/sync`,{
        ticketid: ticketid
      }).then(res => {
        this.loadDebugInfo();
      })
    },
    openTicket(ticketid) {
      this.$router.push(`/tickets/ticket_${ticketid}`);
    },
    deleteTicket(ticketid) {
      axios.delete(`${process.env.VUE_APP_API_BASE_URL}/debug/delete-ticket?ticketid=${ticketid}`).then(res => {
        this.loadDebugInfo();
      })
    },
    displayDiff(ticketid) {
      axios.get(`${process.env.VUE_APP_API_BASE_URL}/debug/diff/${ticketid}`).then(res => {
        this.ticketDiff = res.data
        this.$refs['ticketidff'].show()
      })
    },
    hideModal() {
      this.$refs['ticketidff'].hide()
    }
  }
}
</script>

<style scoped>

</style>
