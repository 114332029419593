<template>
  <div :class="['icon-number', {'disabled': disabled}]" :style="[bgStyle]">
    <span class="text" :style="[{'color': colorText}]">{{ title }}</span>
    <div class="icon d-flex justify-content-center align-items-center">
      <font-awesome-icon
          :icon="icon"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "StatIcon",
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    colorText: {
      type: String,
      required: true
    },
    colorIcon: {
      type: String
    },
    colorBg: {
      type: String
    },
    colorBgGradient: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    bgStyle() {
      if (this.colorBg) {
        return {'background-color': this.colorBg}
      }

      if (this.colorBgGradient) {
        return {'background-image': this.colorBgGradient}
      }

      return {}
    }
  }
}
</script>

<style scoped>
.icon-number {
  width: 30px;
  height: 44.7px;
  #margin-right: 8px;
  text-align: center;
  padding: 6.4px 2px 2.9px 2px;
  border-radius: 4px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.05);
  #background-image: linear-gradient(to right, var(--rouge), #6e1401 100%);
}

.icon-number span {
  display: block;
}

.icon-number .text {
  font-size: 8px;
  font-weight: normal;
  text-align: center;
  color: var(--couleur-texte)
}

.icon-number .icon {
  margin-top: 5px;
}

.disabled .text {
  color: var(--brown-grey);
}

</style>
