  <template>
  <div
    id="menu"
    class="menu"
    :class="[{'menu-collapse': isCollapse}]"
  >
    <div class="menu-logo">
      <img
        :src="logo"
        alt=""
        class="mt-3 mb-2"
        width="160"
      >
    </div>

    <div class="menu-dashboard d-flex justify-content-center align-items-center">
      Tableau de bord
    </div>
    <div
      class="menu-header"
      v-if="$store.getters.userIsAdmin"
    >
      <div class="menu-header-title">
        Paramètres
      </div>
      <div class="menu-header-title-items">
        <router-link
            tag="span"
            :to="{name: 'user-list'}"
            active-class="menu-item-active"
            class="menu-header-title-items-active"
        >
          <font-awesome-icon
              icon="cog"
              class="menu-item-icon"
          />
          Compte utilisateur
        </router-link>
        <!--
        <router-link
          tag="span"
          :to="{name: 'ticket-status-list'}"
          active-class="menu-item-active"
          class="menu-header-title-items-active"
        >
          <font-awesome-icon
              icon="user"
              class="menu-item-icon"
          />
          Status Ticket
        </router-link>
      -->
        <router-link
          tag="span"
          :to="{name: 'list-config'}"
          active-class="menu-item-active"
          class="menu-header-title-items-active"
        >
          <font-awesome-icon
              icon="cog"
              class="menu-item-icon"
          />
          Listes paramétrables
        </router-link>

        <router-link
          tag="span"
          :to="{name: 'debug'}"
          active-class="menu-item-active"
          class="menu-header-title-items-active"
        >
          <font-awesome-icon
              icon="cog"
              class="menu-item-icon"
          />
          Debug logs
        </router-link>

        <span
            class="menu-header-title-items-active"
            @click="downloadExport"
        >
          <font-awesome-icon
              icon="cog"
              class="menu-item-icon"
          />
          Export
        </span>

        <router-link
            tag="span"
            :to="{name: 'statistiques'}"
            active-class="menu-item-active"
            class="menu-header-title-items-active"
        >
          <font-awesome-icon
              icon="cog"
              class="menu-item-icon"
          />
          Statistique
        </router-link>

        <router-link
            tag="span"
            :to="{name: 'validation-hf'}"
            active-class="menu-item-active"
            class="menu-header-title-items-active"
        >
          <font-awesome-icon
              icon="cog"
              class="menu-item-icon"
          />
          Validation HF
        </router-link>


      </div>
    </div>

  </div>
</template>

<script>
const mixin = require('@/mixin')
const logo = require('@/assets/identifiant.png')
export default {
  name: 'NavMenu',
  mixins: [mixin],
  data() {
    return {
      logo
    }
  },
  components: {
  },
  props: {
    isCollapse: {
      type: Boolean
    }
  },
  methods: {
    downloadExport() {
      window.open(`${process.env.VUE_APP_API_BASE_URL}/export/ticketsv2`, '_blank');
    },
    handleDownload(response) {
      let fileURL = window.URL.createObjectURL(new Blob([response.data]));
      let fileLink = document.createElement('a');

      fileLink.href = fileURL;
      let filename = "";
      let disposition = response.headers['content-disposition']
      if (disposition && disposition.indexOf('attachment') !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
      }
      fileLink.setAttribute('download', filename);
      document.body.appendChild(fileLink);

      fileLink.click();
    },
  }
}
</script>

<style lang="scss" scoped>
    .menu {
        flex: 0 1 250px;
        width: 210px;
        min-width: 210px;
        min-height: 100%;
        background-color: #FFFFFF;
        color: #A3A4A6;
        font-size: 14px;
        padding-top: 5px;
        padding-bottom: 8px;

        .menu-dashboard {
          width: 187px;
          height: 31px;
          margin-bottom: 30px;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          color: #fff;
          font-size: 15px;
          background-image: linear-gradient(to right, #01dcc8, #02b5dd);
        }
        .menu-logo {
          display: flex;
          justify-content: center;
        }

        .menu-header {
          .menu-header-title-items {
            cursor: pointer;
          }
          .menu-header-title-items > span {
            display: block;
            padding-top: 7px;
            padding-bottom: 7px;
            margin-top: 5px;
            margin-bottom: 5px;
            padding-left: 27px;
            font-size: 13px;
          }
          .menu-item-icon {
            margin-right: 12px;
          }
          .menu-header-title-items-disabled {
            color: rgba(163, 164, 166, 0.8);
          }
          .menu-header-title-items-active {
            color: rgba(163, 164, 166, 0.8);
          }
        }
        .menu-icon {
          position: absolute;
          left: 50px;
        }
        .menu-header-title {
          color: #01dcc8;
          text-transform: uppercase;
          font-size: 15px;
          position: relative;
          font-weight: bold;
          padding-left: 27px;
          margin-bottom: 5px;
        }
        .menu-item {
          padding-left: 100px;
          padding-top: 13px;
          padding-bottom: 13px;
          font-weight: 300;
        }

        .menu-settings {
          padding-bottom: 20px;
          .menu-settings-title {
            padding-top: 20px;
            padding-left: 30px;
            text-transform: uppercase;
            color: #00A994;
            font-weight: bold;
          }

          .menu-header-title-items {
            padding-left: 65px;
            color: #b4b4b4;
            cursor: pointer;
            span {
              display: block;
              margin-top: 13px;
            }
          }
        }


        .menu-item-active {
          background-color: #01dcc8;
          color: #ffffff !important;
          //border-right: 5px solid #1ABB9C;
        }
        .menu-divider {
          width: 75%;
          border-top: 1px solid #f5f5f5;
        }
        .menu-icon {
          margin-right: 10px;
        }
    }
    .menu-collapse.menu {
      display: none;
    }
    .menu-collapse {
      .menu-logo, .menu-header, .menu-divider, .menu-settings, #menu-legende {
        display: none;
      }
    }
</style>
