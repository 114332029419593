<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-button class="mb-3" @click="$router.push({name: 'user-new'})">Ajouter</b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table table-class="w-100" thead-class="table-head" :fields="tableHeader" :items="users">
          <template #cell(groupname)="data">
            {{ getProfilLabel(data.item.groupname) }}
          </template>

          <template #cell(action)="data">
            <font-awesome-icon @click="$router.push({name: 'user-edit', params: {id: data.item.id}})" icon="pen" />
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from "axios";
import mixin from "@/mixin";
export default {
  name: "UserList",
  mixins: [mixin],
  data() {
    return {
      users: [],
      tableHeader: [
        {
          key: 'id',
          label: '#',
          thClass: 'table-th-custom',
          tdClass: 'table-td-custom'
        },
        {
          key: 'identifiant',
          label: 'Identifiant',
          thClass: 'table-th-custom',
          tdClass: 'table-td-custom'
        },
        {
          key: 'firstname',
          sortable: true,
          label: 'Prénom',
          thClass: 'table-th-custom',
          tdClass: 'table-td-custom'
        },
        {
          key: 'lastname',
          label: 'Nom',
          sortable: false,
          thClass: 'table-th-custom',
          tdClass: 'table-td-custom'
        },
        {
          key: 'email',
          sortable: false,
          label: 'Email',
          thClass: 'table-th-custom',
          tdClass: 'table-td-custom'
        },
        {
          key: 'groupname',
          sortable: true,
          label: 'Groupe',
          thClass: 'table-th-custom',
          tdClass: 'table-td-custom'
        },
        {
          key: 'action',
          sortable: true,
          label: 'Action',
          thClass: 'table-th-custom',
          tdClass: 'table-td-custom'
        },
      ],
    }
  },
  mounted() {
    this.listUsers()
  },
  methods: {
    listUsers() {
      axios.get(`${process.env.VUE_APP_API_BASE_URL}/users`)
          .then((res) => {
            this.users = res.data
          })
    }
  }
}
</script>

<style>

</style>
