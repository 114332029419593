import PouchDB from 'pouchdb-browser'
import PouchDBFind from 'pouchdb-find'

PouchDB.plugin(PouchDBFind);

let DB, DBInit = false, DBInitInProgress = false;
//let DB = new PouchDB('test');

const INDEXES = [
    {
        fields: ['identifiant', 'password'],
        name: 'user_login',
        type: 'json',
        partial_filter_selector: {
            type: { $eq: 'user' }
        }
    },
    {
        fields: ['identifiant'],
        name: 'user_re_login',
        type: 'json',
        partial_filter_selector: {
            type: { $eq: 'user' }
        }
    },
    {
        fields: ['slug', 'name', 'required', 'fieldName', 'defaultValue', 'option'],
        name: 'customFields',
        type: 'json',
        partial_filter_selector: {
            type: { $eq: 'customFields' }
        }
    },
    {
        fields: ['_id', 'date-creation', 'tickets-station', 'tickets-nature-demande','tickets-item-idfm', 'tickets-intervenant', 'tickets-n-sagai'],
        name: 'ticket',
        type: 'json',
        partial_filter_selector: {
            type: { $eq: 'ticket' }
        }
    }
]
console.debug('Database up')

export async function initDb() {
    DBInitInProgress = true;
    console.debug('Start creating DB...')
    if (!DB) {
        DB = new PouchDB('test');
    }
    console.debug('DB creation finish.')
    console.debug('Start creating index...')
    const index = await DB.getIndexes();

    INDEXES.forEach(idx => {
        if (!index.indexes.find(i => i.name === idx.name)) {
            DB.createIndex({index: idx}).then((resultIndexCreation) => {
                console.debug(`${INDEXES[idx].name} index created`, resultIndexCreation)
            }).catch((err) => {
                console.debug(`${INDEXES[idx].name} index creation failed`, err);
            });
        } else {
            console.debug(idx.name + ' index already exist')
        }
    })
    //console.log(index);
    //
    //for (let idx in INDEXES) {
    //    try {
    //        DB.createIndex({
    //            index: INDEXES[idx]
    //        }).then((resultIndexCreation) => {
    //            console.log(`${INDEXES[idx].name} index created`, resultIndexCreation)
    //        }).catch((err) => {
    //            console.log(`${INDEXES[idx].name} index creation failed`, err);
    //        })
    //    } catch (err) {
    //        console.log(`${INDEXES[idx].name} index creation failed`, err);
    //    }
    //}
    console.debug('Index creation finish.')
    DBInit = true;
    DBInitInProgress = false;
}

export async function dropDatabase() {
    return new Promise((resolve, reject) => {
        DB.destroy().then(() => {
            console.debug('DB Destroy')
            resolve()
        }).catch((err) => {
            console.warn('Error during destory DB', err)
            reject(err)
        })
    })

}

export async function listIndex() {
    return DB.getIndexes()
}

export async function listUser() {
    return DB.find({
        selector: {type: 'user'},
        fields: ['_id', 'firstname', 'lastname', 'email'],
    })
}

export function getCustomfieldBySlug(slug) {
    return DB.find({
        selector: {type: 'customFields', slug},
        fields: ['_id', 'slug', 'name', 'required', 'fieldName', 'defaultValue', 'option'],
    })
}

export {DB, DBInit, DBInitInProgress}
