<template>
  <b-container
      fluid
      class="container-flex full-height mb7"
      v-if="$route.name !== 'mobile-login'"
  >
    <!-- UPDATE new version
    <div v-if="updateExists && isInStandaloneMode" class="toaster-maj">
      <b-toast toaster="b-toaster-bottom-right" id="example-toast" solid title="Mise à jour disponible" static no-auto-hide>
        <b-button @click="refreshApp" variant="primary">Mettre à jour</b-button>
      </b-toast>
    </div>
    -->

    <div v-if="$store.state.showImgFullScreen" class="overlay-container">
      <span @click="$store.dispatch('toggleImgFullScreen')" class="overlay-cross">
        <font-awesome-icon size="3x" icon="xmark" />
      </span>
      <img :src="$store.state.imgFullScreen" alt="">
      <div class="overlay"></div>
    </div>

    <notifications position="bottom left" :max="3" :ignoreDuplicates="true" />

    <b-container
        fluid
        class="container-flex full-height flex-column"
    >
      <b-row class="mobile-menu pt-3 pb-3">
        <b-col>
          <b-row>
            <b-col>
              <div @click="logout" class="pl-2">
                <font-awesome-icon icon="user" size="2x" />
                <span class="ml-2">{{ $store.state.currentUser.firstname }} {{ $store.state.currentUser.lastname }} - {{ getProfilLabel($store.state.currentUser.groupname) }}</span>
              </div>
            </b-col>
            <b-col class="text-right" v-if="$route.name !== 'mobile-station-list'">
              <div @click="goBack" class="pr-2">
                <span class="mr-2">Retour</span>
                <font-awesome-icon icon="rotate-left" size="2x" />
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div v-if="isInStandaloneMode" class=" d-flex justify-content-center align-items-center mr-3">
                <font-awesome-icon class="mr-3" @click="downloadAllImage" icon="image" size="2x" />

                  <span @click="doCount" id="database-stat-navbar" class="mr-1">
                      <font-awesome-icon icon="database" size="2x" />
                  </span>
                <font-awesome-icon class="d-block mr-1" v-if="isOnline && this.internetOk" icon="wifi" />
                <font-awesome-icon class="d-block mr-1" v-if="!isOnline || !this.internetOk" icon="times-circle" />
                <span>&nbsp;|&nbsp;</span>
                <b-tooltip triggers="click" target="database-stat-navbar">
                  <p class="no-margin">NB utilisateurs : {{ nbUsers }}</p>
                  <p class="no-margin">NB docs local DB : {{ nbDocs }}</p>
                  <p class="no-margin">NB images local DB : {{ nbImages }}</p>
                  <p class="no-margin">NB images to download : {{ nbImageToDownload }}</p>
                  <p class="no-margin">NB images to upload : {{ nbImageToUpload }}</p>
                  <p class="no-margin">NB images Index DB : {{ nbImagesIndexDB }}</p>
                  <p class="no-margin">Etat syncro
                    <font-awesome-icon v-if="$store.state.syncStatus === 'ok'" icon="check" />
                    <font-awesome-icon v-if="$store.state.syncStatus === 'error'" icon="circle-exclamation" />
                    <font-awesome-icon v-if="$store.state.syncStatus === 'in_progress'" icon="sync" spin />
                  </p>
                </b-tooltip>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col class="text-center">
              <span class="text-uppercase page-title">{{ $route.meta.pageTitle ? $route.meta.pageTitle : $route.params.station }}</span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <div
          id="admin-container"
          class="admin-container"
      >
        <router-view />
        <b-button v-if="false && isInStandaloneMode" v-b-toggle.debug variant="primary">Debug</b-button>
        <b-collapse id="debug">
          <b-button type="danger" @click="dropDatabase">Supprimer la base local</b-button>
          <b-button @click="sync">Start sync</b-button>
          <b-button @click="readDb">Read DB</b-button>
          <b-button @click="listIndexDb">List index</b-button>
        </b-collapse>
      </div>
    </b-container>
  </b-container>
  <div v-else class="min-vh-100 min-vw-100 bg-login d-flex justify-content-center align-items-center">
    <router-view />
  </div>
</template>

<script>
import mixin from '@/mixin'

import {DB, listUser, initDb, DBInitInProgress, DBInit, listIndex} from '@/db/db'
import Cookies from "js-cookie";
import dbMixin from "@/dbMixin";
export default {
  name: "AdminLayout",
  mixins: [mixin, dbMixin],
  data() {
    return {
      isCollapse: false,
      db: null,
      syncHandler: null,
      dataSyncState: null,

      onLine: navigator.onLine,
      errorUserMedia: null,
      nbUsers: '',
      nbDocs: '',
      interval: null,
      internetOk: null,

      nbImages: '',
      nbImagesIndexDB: '',
      nbImageToDownload: '',
      nbImageToUpload: '',
    }
  },
  async beforeMount() {
    if (this.isInStandaloneMode) {
      if (!DBInitInProgress && !DBInit) {
        await initDb()
      }
      this.sync();
      window.addEventListener('online', this.updateOnlineStatus)
      window.addEventListener('offline', this.updateOnlineStatus)
    }
  },
  async mounted() {
    if (this.isInStandaloneMode) {
      this.interval = setInterval(async () => {
        this.internetOk = await this.checkOnlineStatus()
      }, 3000)
    }

    //this.$notify({ type: 'success', title: 'Nouvelle version', text: 'Une nouvelle version a été déployée 🎉' })
    if (this.$store.getters['login/isClient']) {
      this.isCollapse = false;
    }

    this.$bvToast.show('example-toast');
    //var old = console.log;
    //console.log = (message, message2) => {
    //  if (typeof message == 'object') {
    //    this.$store.dispatch('addLog', JSON.stringify(message))
    //  } else {
    //    this.$store.dispatch('addLog', message)
    //  }
//
    //  if (typeof message2 == 'object') {
    //    this.$store.dispatch('addLog', JSON.stringify(message2))
    //  } else {
    //    this.$store.dispatch('addLog', message2)
    //  }
    //}

    if (this.isInStandaloneMode) {
      this.interval = setInterval(async () => {
        this.internetOk = await this.checkOnlineStatus()
        if (this.internetOk) {
          this.syncDownloadImage()
          this.syncUploadImage()
        }
      }, 1000 * 10)
    }

    await this.$store.dispatch('checkLogin')
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    goBack() {
      this.$router.push({ name: 'mobile-station-list' })
    },
    doCount() {
      DB.allDocs({
        include_docs: true,
      }).then(res => {
        this.nbDocs = res.total_rows;
        this.nbUsers = res.rows.filter(d => d.id.includes('user_')).length
        this.nbImages = res.rows.filter(d => d.doc._attachments).length
      })

      this.getAllKeys('images').then(res => {
        this.nbImagesIndexDB = res.length
      })
      this.getAllKeys('imagesToDownload').then(res => {
        this.nbImageToDownload = res.length
      })
      this.getAllKeys('imagesToUpload').then(res => {
        this.nbImageToUpload = res.length
      })
    },
    logout() {
      Cookies.remove('login');
      Cookies.remove('isPAME');
      this.$router.replace({name: 'login'})
    },
    handleCollapse(value) {
      this.isCollapse = value;
    },
    listIndexDb() {
      listIndex().then((res) => {
        console.log(res)
      })
    },
    addDocument() {
      DB.put({
        _id: parseInt(Math.random()*10000).toString(),
        title: 'Heroes'
      }).then(function (response) {
        console.log('document insert in DB !', response)
      }).catch(function (err) {
        console.warn(err);
      });
    },
    readDb() {
      DB.allDocs({
        include_docs: true,
        attachments: true,
      }).then(res => {
        console.log('Read all', res)
      })
    },
    dropDatabase() {
      DB.destroy().then(() => {
        console.info('DB Destroy')
        initDb()
      }).catch((err) => console.warn('Error during destory DB', err))
    },
    async listAllUser() {
      const user = await listUser()
      console.log('Users ', user);
    },
    updateOnlineStatus(e) {
      const { type } = e
      this.onLine = type === 'online'

      if (this.onLine) {
        this.sync()
      }
    },
  }
};
</script>

<style lang="scss">

.mobile-menu {
  background-color: var(--bleu);
  color: white;
}

.page-title {
  font-size: 24px;
  font-weight: bold;
}

.toaster-maj {
  position: absolute !important;
  left: 20px;
  bottom: 20px;
  z-index: 9999;
}

.container-flex {
  display: flex;

  .admin-container {
    padding: 10px;
    background-color: #f8f8f8;
    width: 100%;
    flex-grow: 1;
  }
}
.root-container {
  flex: 1 1 auto;
  height: max-content;
}

.bg-login {
  background-image: linear-gradient(to right, var(--vert), var(--bleu));
}

.overlay-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 70%;
    z-index: 1000;
  }
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000000;
    opacity: 0.8;
  }
  .overlay-cross {
    position: absolute;
    right: 40px;
    top: 40px;
    color: #ffffff;
    z-index: 1;
  }
}
</style>
