<template>
  <b-navbar
    toggleable="lg"
    type="dark"
    variant="info"
    class="nav-bar-bg"
  >
    <b-navbar
    >
      <b-navbar-brand
        href="#"
      >
        <router-link v-if="$route.name != 'tickets-list'" tag="span" :to="{name: 'tickets-list'}" class="mr-3">
          <font-awesome-icon icon="list" size="2x" />
        </router-link>

        <ticket-status v-if="$route.name === 'tickets-update' && $store.state.ticketStatus" size="lg" :ticket-status="$store.state.ticketStatus" class="mr-2" />
        <ticket-status v-if="$route.name === 'tickets-add'" size="lg" ticket-status="Ouvert" class="mr-2" />

        <span class="brand-title" v-if="['tickets-add', 'tickets-update'].includes($route.name)">
          {{ isInStandaloneMode ? 'Correctif' : 'Préventif' }} | {{ $store.state.ticketDateCreation }}
        </span>
        <span v-else class="brand-title">{{ $route.meta.pageTitle }}</span>
      </b-navbar-brand>
    </b-navbar>

    <!--
    <b-navbar-nav>
      <b-nav-text id="burger-icon">
        <font-awesome-icon
          icon="bars"
          @click="collapse"
        />
      </b-nav-text>
    </b-navbar-nav>
    -->

    <!-- Right aligned nav items -->
    <b-navbar-nav class="ml-auto align-items-center mr-3">
      <b-nav-item
        right
      >
        <div class="d-flex justify-content-center align-items-center">
          <div v-if="isInStandaloneMode" class=" d-flex justify-content-center align-items-center mr-3">
            <font-awesome-icon class="mr-3" @click="downloadAllImage" icon="image" size="2x" />

              <span @click="doCount" id="database-stat-navbar" class="mr-1">
                  <font-awesome-icon icon="database" size="2x" />
              </span>
              <font-awesome-icon class="d-block mr-1" v-if="isOnline && this.internetOk" icon="wifi" />
              <font-awesome-icon class="d-block mr-1" v-if="!isOnline || !this.internetOk" icon="times-circle" />
              <span>&nbsp;|&nbsp;</span>
              <b-tooltip triggers="click" target="database-stat-navbar">
                  <p class="no-margin">NB utilisateurs : {{ nbUsers }}</p>
                  <p class="no-margin">NB docs local DB : {{ nbDocs }}</p>
                  <p class="no-margin">NB images local DB : {{ nbImages }}</p>
                  <p class="no-margin">NB images to download : {{ nbImageToDownload }}</p>
                  <p class="no-margin">NB images to upload : {{ nbImageToUpload }}</p>
                  <p class="no-margin">NB images Index DB : {{ nbImagesIndexDB }}</p>
                  <p class="no-margin">Etat syncro
                      <font-awesome-icon v-if="$store.state.syncStatus === 'ok'" icon="check" />
                      <font-awesome-icon v-if="$store.state.syncStatus === 'error'" icon="circle-exclamation" />
                      <font-awesome-icon v-if="$store.state.syncStatus === 'in_progress'" icon="sync" spin />
                  </p>
              </b-tooltip>
            <font-awesome-icon v-if="$store.getters.userIsAdmin" @click="goDb" icon="clock" size="2x" />

          </div>
          <span class="mr-2">{{ $store.state.currentUser.firstname }} {{ $store.state.currentUser.lastname }} - {{ getProfilLabel($store.state.currentUser.groupname) }}</span>
          <span @click="logout()">
          <font-awesome-icon icon="user" size="2x" />
        </span>
        </div>
      </b-nav-item>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import TicketStatus from "@/components/TicketStatus";
import mixin from "@/mixin";
import Cookies from "js-cookie";
import {DB} from "@/db/db";
import dbMixin from "@/dbMixin";
export default {
  name: 'Navbar',
  components: {TicketStatus},
  mixins: [mixin, dbMixin],
  data() {
    return {
      isCollapse: false,
      notifications: [],
      notificationsCount: 0,
      intervalRef: null,
      nbUsers: '',
      nbDocs: '',
      internetOk: null,
      interval: null,
      nbImages: '',
      nbImagesIndexDB: '',
      nbImageToDownload: '',
      nbImageToUpload: '',
    }
  },
  mounted() {
    // this.$emit('collapse', this.isCollapse);
    if (this.isInStandaloneMode) {
      this.interval = setInterval(async () => {
        this.internetOk = await this.checkOnlineStatus()
      }, 3000)
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalRef);
    clearInterval(this.interval);

  },
  methods: {
    goDb() {
      this.$router.push({name: 'indexdb'})
    },
    collapse() {
      this.isCollapse = !this.isCollapse;
      this.$emit('collapse', this.isCollapse);
    },

    logout() {
      Cookies.remove('login');
      Cookies.remove('isPAME');
      this.$router.replace({name: 'login'})
    },
    profile() {
      this.$router.replace({name: 'profile'})
    },
    doCount() {
      DB.allDocs({
        include_docs: true,
        attachments: true,
      }).then(res => {
        this.nbDocs = res.total_rows;
        this.nbUsers = res.rows.filter(d => d.id.includes('user_')).length
        this.nbImages = res.rows.filter(d => d.doc._attachments).length
      })

      this.getAllKeys('images').then(res => {
        this.nbImagesIndexDB = res.length
      })
      this.getAllKeys('imagesToDownload').then(res => {
        this.nbImageToDownload = res.length
      })
      this.getAllKeys('imagesToUpload').then(res => {
        this.nbImageToUpload = res.length
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.nav-bar-bg {
  background-image: linear-gradient(to right, var(--vert), var(--bleu)) !important;
}
  .navbar.bg-info {
   // background-color: #EDEDED !important;
  }
  .navbar.navbar-dark.bg-info{
  // background-color: #ffffff!important;
  }
  .navbar {
    height: 59px;
    padding: 0;
    box-sizing: content-box;
    color: #ffffff;
   // background-color: #ffffff;
    .navbar-brand {
    //  background-color: #ffffff;
      color: var(--couleur-texte);
      font-size: 15px;
      font-weight: 300;
      padding-left: 30px;
      padding-right: 20px;
      height: 59px;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .navbar-brand.menu-collapse {
      width: 70px;
      span {
        display: none;
      }
    }
    .navbar-brand:hover {
      color: var(--couleur-texte);
    }
  }

  #burger-icon {
    margin-left: 15px;
    color: inherit;
  }

  .font-size-120-pourcent {
    font-size: 120%;
  }
  .burger-icon {
    margin-right: 20px;
  }

  .brand-title {
    font-size: 17px;
    font-weight: bold;
    color: #ffffff;
  }
</style>
