<template>
  <b-container fluid>
    <b-row :key="station.value" v-for="station in stations">
      <b-col>
        <div class="station-list-item d-flex justify-content-between" @click="listTicketForStation(station)">
            <p class="no-margin">{{ station.text }}</p>

            <p v-if="isInStandaloneMode" class="no-margin">
                <b-badge variant="success">{{ countTicketByStation(station.value) }}</b-badge>
            </p>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import mixin from "@/mixin";
import {DB} from "@/db/db";

export default {
  name: "StationList",
  mixins: [mixin],
  data() {
    return {
      stations: [],
      stationCountTicket: {},
      tickets: []
    }
  },
  mounted() {
    DB.allDocs({
      include_docs: true,
    }).then(res => {
      res.rows.forEach(doc => {
        if (!doc.id.includes('ticket_')) {
          return;
        }
        this.tickets.push(doc.doc)
      })
    })

    this.loadStation()
  },
  methods: {
    listTicketForStation(station) {
      this.$router.push({ name: 'mobile-ticket-list', query: { station: station.text, stationValue: station.value } })
    },
    countTicketByStation(station) {
      if (!this.stationCountTicket[station]) {
        this.stationCountTicket[station] = 0;
      }

      this.tickets.forEach(doc => {
        if (
          doc['tickets-intervenant'] === this.$store.state.currentUser.identifiant
          && doc['tickets-status'] === 'En cours'
          && doc['tickets-station'] === station
        ) {
          this.stationCountTicket[station]++;
        }
      })

      return this.stationCountTicket[station];
    }
  },
  watch: {
    stations(oldVal, newVal) {
      DB.allDocs({
        include_docs: true,
      }).then(res => {
        res.rows.forEach(doc => {
          if (!doc.id.includes('ticket_')) {
            return;
          }
          let station = doc.doc['tickets-station'];

          if (
            doc.doc['tickets-intervenant'] === this.$store.state.currentUser.identifiant
            && doc.doc['tickets-status'] === 'En cours'
          ) {
            if (!this.stationCountTicket[station]) {
              this.stationCountTicket[station] = 0;
            }
            this.stationCountTicket[station]++;
          }
        })
      })
    }
  }
}
</script>

<style scoped>
  .station-list-item {
    padding: 10px;
    border-top: 1px solid var(--bleu);
    border-left: 1px solid var(--bleu);
    border-right: 1px solid var(--bleu);
    border-bottom: none;
    font-size: 18px;
  }
  .station-list-item p {
    font-size: 18px;
  }
  .station-list-item:last-of-type {
    border-bottom: 1px solid var(--bleu);
  }
</style>
