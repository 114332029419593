<template>
  <b-container
      fluid
      class="container-flex full-height mb7"
      v-if="$route.name !== 'login'"
  >
    <!-- UPDATE new version
    <div v-if="updateExists && isInStandaloneMode" class="toaster-maj">
      <b-toast toaster="b-toaster-bottom-right" id="example-toast" solid title="Mise à jour disponible" static no-auto-hide>
        <b-button @click="refreshApp" variant="primary">Mettre à jour</b-button>
      </b-toast>
    </div>
    -->

    <div v-if="$store.state.showImgFullScreen" class="overlay-container">
      <span @click="$store.dispatch('toggleImgFullScreen')" class="overlay-cross">
        <font-awesome-icon size="3x" icon="xmark" />
      </span>
      <img :src="$store.state.imgFullScreen" alt="">
      <div class="overlay"></div>
    </div>

    <notifications position="bottom left" :max="3" :ignoreDuplicates="true" />
    <nav-menu v-if="!isInStandaloneMode" :is-collapse="isCollapse" />

    <b-container
        fluid
        class="container-flex full-height flex-column"
    >
      <navbar @collapse="handleCollapse" />
      <div
          id="admin-container"
          class="admin-container"
      >
        <router-view />
        <b-button v-if="isInStandaloneMode" v-b-toggle.debug variant="primary">Debug</b-button>
        <b-collapse id="debug">
          <b-button type="danger" @click="dropDatabase">Supprimer la base local</b-button>
          <b-button @click="sync">Start sync</b-button>
          <b-button @click="readDb">Read DB</b-button>
          <b-button @click="listIndexDb">List index</b-button>
          <b-button @click="showStorageSpace">Show storage space</b-button>

        </b-collapse>
      </div>
    </b-container>
  </b-container>
  <!-- Login view -->
  <div v-else class="min-vh-100 min-vw-100 bg-login d-flex justify-content-center align-items-center">
    <router-view />
      <div class="fixed-bottom pl-2" v-if="isInStandaloneMode" @click="doCount">
          <p class="no-margin">NB utilisateurs : {{ nbUsers }}</p>
          <p class="no-margin">NB docs local DB : {{ nbDocs }}</p>
          <p class="no-margin">Storage : {{ storageUsed }} / {{ storageAvailable }}</p>
          <p class="no-margin">Version : {{ $store.state.version }}</p>
      </div>
  </div>
</template>

<script>
import Navbar from "./Navbar";
import NavMenu from "./Menu";
import mixin from '@/mixin'

import {DB, listUser, initDb, DBInitInProgress, DBInit, listIndex} from '@/db/db'
import Cookies from "js-cookie";
import axios from "axios";
import update from "@/mixin/update";
import dbMixin from "@/dbMixin";
export default {
  name: "AdminLayout",
  mixins: [mixin, dbMixin],
  components: {
    Navbar,
    NavMenu,
  },
  data() {
    return {
      isCollapse: false,
      db: null,
      syncHandler: null,
      dataSyncState: null,

      onLine: navigator.onLine,
      errorUserMedia: null,
      nbUsers: null,
      nbDocs: null,
      storageUsed: 0,
      storageAvailable: 0,
    }
  },
  async beforeMount() {
    if (this.isInStandaloneMode) {
      if (!this.db) {
        this.createDatabase()
      }
      if (!DBInitInProgress && !DBInit) {
        await initDb()
      }
      this.sync();
      window.addEventListener('online', this.updateOnlineStatus)
      window.addEventListener('offline', this.updateOnlineStatus)
    }
  },
  async mounted() {
    //this.$notify({ type: 'success', title: 'Nouvelle version', text: 'Une nouvelle version a été déployée 🎉' })
    if (this.$store.getters['login/isClient']) {
      this.isCollapse = false;
    }

    //this.$bvToast.show('example-toast');
    //var old = console.log;
    //console.log = (message, message2) => {
    //  if (typeof message == 'object') {
    //    this.$store.dispatch('addLog', JSON.stringify(message))
    //  } else {
    //    this.$store.dispatch('addLog', message)
    //  }
//
    //  if (typeof message2 == 'object') {
    //    this.$store.dispatch('addLog', JSON.stringify(message2))
    //  } else {
    //    this.$store.dispatch('addLog', message2)
    //  }
    //}

    if (this.isInStandaloneMode) {
      this.interval = setInterval(async () => {
        this.internetOk = await this.checkOnlineStatus()
        if (this.internetOk) {
          this.syncDownloadImage()
          this.syncUploadImage()
        }
      }, 1000 * 10)
    }

    await this.$store.dispatch('checkLogin')
  },
  methods: {
    async showStorageSpace() {
      let result = await navigator.storage.estimate();
      this.storageUsed = result.usage / 1000000 // conversion to megabyte
      this.storageAvailable = result.quota / 1000000;
    },
    handleCollapse(value) {
      this.isCollapse = value;
    },
    listIndexDb() {
      listIndex().then((res) => {
        console.log(res)
      })
    },
    doCount() {
      this.showStorageSpace()
      DB.allDocs({
        include_docs: true,
      }).then(res => {
        this.nbDocs = res.total_rows;
        this.nbUsers = res.rows.filter(d => d.id.includes('user_')).length
      })
    },
    addDocument() {
      DB.put({
        _id: parseInt(Math.random()*10000).toString(),
        title: 'Heroes'
      }).then(function (response) {
        console.log('document insert in DB !', response)
      }).catch(function (err) {
        console.warn(err);
      });
    },
    readDb() {
      DB.allDocs({
        include_docs: true,
        attachments: true,
      }).then(res => {
        console.log('Read all', res)
      })
    },

    dropDatabase() {
      DB.destroy().then(() => {
        console.info('DB Destroy')
        initDb()
      }).catch((err) => console.warn('Error during destory DB', err))
    },
    async listAllUser() {
      const user = await listUser()
      console.log('Users ', user);
    },
    updateOnlineStatus(e) {
      const { type } = e
      this.onLine = type === 'online'

      if (this.onLine) {
        this.sync()
      }
    },
  }
};
</script>

<style lang="scss">
.toaster-maj {
  position: absolute !important;
  left: 20px;
  bottom: 20px;
  z-index: 9999;
}

.container-flex {
  display: flex;

  .admin-container {
    padding: 20px;
    background-color: #f8f8f8;
    width: 100%;
    flex-grow: 1;
  }
}
.root-container {
  flex: 1 1 auto;
  height: max-content;
}

.bg-login {
  background-image: linear-gradient(to right, var(--vert), var(--bleu));
}

.overlay-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 70%;
    z-index: 1000;
  }
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000000;
    opacity: 0.8;
  }
  .overlay-cross {
    position: absolute;
    right: 40px;
    top: 40px;
    color: #ffffff;
    z-index: 1;
  }
}

.progress-bar-container {
  position: absolute;
  bottom: 120px;
  right: 20%;
  left: 20%;
}
</style>
