<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <div class="ticket-item mb-3">
          <p>{{ ticket.datePassageEnCours }}</p>
          <b-row class="mb-2">
            <b-col>
              <div class="d-flex flex-column">
                <b-form-group
                    id="input-localisation-label"
                    label="Localisation"
                    label-for="input-localisation"
                    label-class="ticket-item-label"
                >
                  <b-form-input
                      id="input-localisation"
                      v-model="ticket.interventions[0].localisation"
                      type="text"
                      placeholder=""
                      class="ticket-item-value"
                  ></b-form-input>
                </b-form-group>
              </div>
            </b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col>
              <div class="d-flex flex-column">
                <b-form-group
                    id="input-details-label"
                    label="Détails"
                    label-for="input-details"
                    label-class="ticket-item-label"
                >
                  <b-textarea
                      id="input-details"
                      v-model="ticket.interventions[0].detail"
                      type="text"
                      placeholder=""
                      class="ticket-item-value"
                      rows="3"
                      max-rows="6"
                  ></b-textarea>
                </b-form-group>
              </div>
            </b-col>
          </b-row>

          <b-row class="mb-1">
            <b-col>
              <img v-if="hasPhotoAvant" class="img-fluid ticket-item-image" :src="photosAvant[0].content" width="100%" alt="">
            </b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col>
              <img v-if="hasPhotoAvant" class="img-fluid ticket-item-image" :src="photosAvant[1].content" width="100%" alt="">
            </b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col>
              <span class="w-100 btn-grey">Visite NFT</span>
            </b-col>
          </b-row>

          <b-row class="mb-1">
            <b-col>
              <img :key="forceRender" :ref="`photoPost1`" width="100%" @click="takePictureIntervention('photo1_post_intervention')" class="img-fluid" :src="photoInterventionPost.photo1_post_intervention_base64 ? photoInterventionPost.photo1_post_intervention_base64 : imgPlaceholder" alt="">
              <b-form-file @input="uploadImgIntervention( 'photo1_post_intervention')" :ref="`photo1_post_intervention`" v-show="false" v-model="ticket.interventions[0].photo1_post_intervention" class="mt-3" accept="image/*" :capture="true" plain></b-form-file>
            </b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col>
              <img :key="forceRender" :ref="`photoPost2`" width="100%" @click="takePictureIntervention('photo2_post_intervention')" class="img-fluid" :src="photoInterventionPost.photo2_post_intervention_base64 ? photoInterventionPost.photo2_post_intervention_base64 : imgPlaceholder" alt="">
              <b-form-file @input="uploadImgIntervention( 'photo2_post_intervention')" :ref="`photo2_post_intervention`" v-show="false" v-model="ticket.interventions[0].photo2_post_intervention" class="mt-3" accept="image/*" :capture="true" plain></b-form-file>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col>
              <div class="d-flex flex-column">
                <b-form-group
                    id="input-commentaire-label"
                    label="Votre commentaire"
                    label-for="input-commentaire"
                    label-class="ticket-item-label"
                >
                  <b-textarea
                      id="input-commentaire"
                      v-model="ticket.visiteClotureCommentaire"
                      type="text"
                      placeholder=""
                      class="ticket-item-value"
                      rows="3"
                      max-rows="6"
                  ></b-textarea>
                </b-form-group>
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <div @click="saveDocument('NFT')" class="btn-edit-ticket-mobile-ok d-flex justify-content-center align-items-center">
                <font-awesome-icon size="3x" icon="circle-check" />
              </div>
            </b-col>
            <b-col>
              <div @click="saveDocument('Abandon')" class="ml-auto btn-edit-ticket-mobile-cancel d-flex justify-content-center align-items-center">
                <font-awesome-icon size="3x" icon="circle-xmark" />
              </div>

            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Cookies from "js-cookie";
import {DB} from "@/db/db";
import moment from "moment/moment";
import axios from "axios";
import qs from "query-string";
import imgPlaceholder from '@/assets/place-holder.png'
import mixin from "@/mixin";
import {v4 as uuidv4} from "uuid";

export default {
  name: "MobileEditTicket",
  mixins: [mixin],
  data() {
    return {
      imgPlaceholder,
      datas: [],
      ticketFilter: {
        station: this.$route.params.stationValue,
        status: 'En cours',
      },
      ticket: {
        interventions: [{}]
      },
      photosAvant: [],
      photosApres: [],
      loading: false,
      attachement: {},
      forceRender: 0,
      photoInterventionPost: {
        photo1_post_intervention_base64: '',
        photo2_post_intervention_base64: ''
      }
    }
  },
  computed: {
    hasPhotoAvant() {
      return this.photosAvant.length > 0
    },
    // photoPost1() {
    //   return this.photoInterventionPost['photo1_post_intervention_base64'] ?? imgPlaceholder
    // },
    // photoPost2() {
    //   return this.photoInterventionPost['photo2_post_intervention_base64'] ?? imgPlaceholder
    // }
  },
  methods: {
    async searchTicket() {
      this.loading = true
      if (this.isInStandaloneMode) {
        console.debug('searchTicket begging')
        let selector = {type: 'ticket'};
        let usersPAME = []
        if (Cookies.get('isPAME')) {
          usersPAME = await DB.find({
            selector: {type: 'user', groupname: {$eq: 'AGENT_PAME'}},
            fields: ['_id', 'identifiant', 'firstname', 'lastname'],
          })

          const usersPAMEIdentifiant = usersPAME.docs.map(user => user.identifiant)
          selector['tickets-intervenant'] = {$in: usersPAMEIdentifiant};
        }

        if (this.$store.getters.userIsPrestataireRatp || this.$store.getters.userIsPrestataireHorsRatp) {
          selector['tickets-intervenant'] = {$eq: this.$store.state.currentUser.identifiant};
        }

        if (this.ticketFilter.station) {
          selector['tickets-station'] = {$eq: this.ticketFilter.station};
        }

        if (this.ticketFilter.status) {
          selector['tickets-status'] = {$eq: this.ticketFilter.status}
        }

        console.log('start search ticket with selector', selector)
        DB.allDocs({
          startkey: 'ticket_',
          include_docs: true,
        }).then(res => {
          this.datas = []
          this.datas.push(...res.rows.filter(item => {
            if (!item.id.includes('ticket_')) {
              return false
            }

            if (this.$store.getters.userIsAgentPame) {
              const usersPAMEIdentifiant = usersPAME.docs.map(user => user.identifiant)
              if (!usersPAMEIdentifiant.includes(item.doc['tickets-intervenant'])) {
                return false
              }
            }

            if (this.$store.getters.userIsPrestataireRatp || this.$store.getters.userIsPrestataireHorsRatp) {
              if (item.doc['tickets-intervenant'] !== this.$store.state.currentUser.identifiant) {
                return false
              }
            }

            if (this.ticketFilter.station) {
              if (item.doc['tickets-station'] !== this.ticketFilter.station) {
                return false
              }
            }

            if (this.ticketFilter.intervenant) {
              if (item.doc['tickets-intervenant'] !== this.ticketFilter.intervenant) {
                return false
              }
            }

            if (this.ticketFilter.status) {
              if (item.doc['tickets-status'] !== this.ticketFilter.status) {
                return false
              }
            }

            return true;
          }).map(item => {
            let ticket = item.doc
            return {
              dateCreation: ticket['date-creation'],
              station: ticket['tickets-station'],
              idfm: ticket['tickets-item-idfm'],
              nature: ticket['tickets-nature-demande'],
              intervenant: ticket['tickets-intervenant'],
              sagai: ticket['tickets-n-sagai'],
              interventions: ticket['interventions'],
              _id: ticket._id,
              id: ticket.id ? ticket.id : null,
              status: ticket['tickets-status'],
              photosAvant: [],
              photosApres: [],
            }
          }))
          this.loading = false
          this.datas.forEach((ticket, index) => {
            this.loadTicket(ticket.id, index)
          })
        })
      } else {
        let queryParams = {}
        if (this.$store.getters.userIsAgentPame) {
          queryParams['tickets-intervenant'] = 'AGENT_PAME'
        }

        if (this.$store.getters.userIsPrestataireRatp || this.$store.getters.userIsPrestataireHorsRatp) {
          queryParams['tickets-intervenant'] = this.$store.state.currentUser.identifiant
        }

        if (this.ticketFilter.station) {
          queryParams['tickets-station'] = this.ticketFilter.station;
        }

        if (this.ticketFilter.intervenant) {
          queryParams['tickets-intervenant'] = this.ticketFilter.intervenant;
        }

        if (this.ticketFilter.status) {
          queryParams['tickets-status'] = this.ticketFilter.status
        }

        this.searchDesktopParams = queryParams;
        axios.get(`${process.env.VUE_APP_API_BASE_URL}/tickets?${qs.stringify(queryParams)}`)
            .then((res) => {
              this.datas = []
              this.datas.push(...res.data.map(ticket => {
                return {
                  dateCreation: ticket['date-creation'],
                  station: ticket['tickets-station'],
                  idfm: ticket['tickets-item-idfm'],
                  nature: ticket['tickets-nature-demande'],
                  intervenant: ticket['tickets-intervenant'],
                  sagai: ticket['tickets-n-sagai'],
                  interventions: ticket['interventions'],
                  _id: ticket._id,
                  id: ticket.id ? ticket.id : null,
                  status: ticket['tickets-status'],
                  photosAvant: [],
                  photosApres: [],
                }
              }))

              this.loading = false
              this.datas.forEach((ticket, index) => {
                this.loadTicket(ticket.id, index)
              })
            })
      }
    },
    openImage(img) {
      this.$store.dispatch('setImgFullScreen', img);
      this.$store.dispatch('toggleImgFullScreen');
    },
    loadTicket(ticketId) {
      if (this.isInStandaloneMode) {
        DB.get(ticketId).then(async res => {
          this.ticket = res
          this.ticket.datePassageEnCours = moment(res['tickets-intervenation-date-visa']).format('DD/MM/YY');
          this.ticket.photosAvant = []
          this.ticket.photosApres = []
          this.ticket.visiteClotureCommentaire = null
          const request = indexedDB.open("ticketprop", 1);

          const photosFields = [
            'photo1_pre_intervention',
            'photo2_pre_intervention',
            'photo1_post_intervention',
            'photo2_post_intervention',
          ]
          request.onsuccess = async (event) => {
            const db = event.target.result;
            this.ticket.interventions.forEach(async (intervention, idx) => {
              photosFields.forEach(async key => {
                if (key.includes('pre') && intervention[key]) {
                  this.photosAvant.push({
                    content:  await this.readImage(intervention[key], db)
                  })
                  intervention[key + '_base64'] = await this.readImage(intervention[key], db)
                } else if (key.includes('post') && intervention[key]) {
                  this.photosApres.push({
                    content:  await this.readImage(intervention[key], db)
                  })
                  intervention[key + '_base64'] = await this.readImage(intervention[key], db)

                  if (idx === 0) {
                    this.$set(this.photoInterventionPost, key + '_base64', await this.readImage(intervention[key], db))
                  }
                }
              })
            });
          }
        })
      } else {
        axios.get(`${process.env.VUE_APP_API_BASE_URL}/tickets/${ticketId}`)
            .then((res) => {
              this.ticket = res.data
              this.ticket.datePassageEnCours = moment(res.data['tickets-intervenation-date-visa']).format('DD/MM/YY');
              this.ticket.photosAvant = []
              this.ticket.photosApres = []
              this.ticket.visiteClotureCommentaire = null
              res.data.interventions.forEach(intervention => {
                if (intervention['photo1_pre_intervention']) {
                  this.photosAvant.push({
                    content: `${process.env.VUE_APP_IMAGE_BASE_URL}/${intervention['photo1_pre_intervention']}`
                  })
                }
                if (intervention['photo2_pre_intervention']) {
                  this.photosAvant.push({
                    content: `${process.env.VUE_APP_IMAGE_BASE_URL}/${intervention['photo2_pre_intervention']}`
                  })
                }

                if (intervention['photo1_post_intervention']) {
                  this.photosApres.push({
                    content: `${process.env.VUE_APP_IMAGE_BASE_URL}/${intervention['photo1_post_intervention']}`
                  })
                }
                if (intervention['photo2_post_intervention']) {
                  this.photosApres.push({
                    content: `${process.env.VUE_APP_IMAGE_BASE_URL}/${intervention['photo2_post_intervention']}`
                  })
                }
              })
            })
      }
    },
    takePictureIntervention(ref) {
      this.$refs[ref].$el.click()
    },
    async uploadImgIntervention(field) {
      let reader = new FileReader();
      let extension = 'jpeg';
      // let extension = this.ticket.interventions[0][field].name.split('.').pop();
      const newImage = await resizeAndConvertToJPEG(this.ticket.interventions[0][field], 1024*1024, 0.7);
      this.ticket.interventions[0][field] = newImage;
      reader.readAsDataURL(newImage);
      reader.onload = () => {
        let interventions = this.ticket.interventions;
        this.$set(this.photoInterventionPost, `${field}_base64`, reader.result)

        if (field === 'photo1_post_intervention') {
          this.$refs.photoPost1.src = reader.result;
        } else {
          this.$refs.photoPost2.src = reader.result;
        }
        let imgUuid = uuidv4();
        interventions[0][`${field}_uuid`] = imgUuid + '.' + extension;
        interventions[0][field] = imgUuid + '.' + extension;
        //this.form.interventions[index][field] = null;
        this.$set(this.attachement, `${imgUuid}.${extension}`, {
          content_type: 'image/jpeg',
          // content_type: interventions[0][field].type,
          data: reader.result,
          uuid: imgUuid
        })
        this.$set(this.ticket, 'interventions', interventions)
        this.forceRender++;
      }
    },
    saveDocument(status) {
      if (this.isInStandaloneMode) {
        DB.get(this.$route.params.ticketId, {attachments: true}).then(res => {
          return DB.put({
            ...res,
            _id: res._id,
            _rev: res._rev,
            interventions: this.ticket.interventions,
            //_attachments: this.attachement,
            'tickets-visite-cloture-commentaire': this.ticket.visiteClotureCommentaire,
            'tickets-visite-cloture-agent': this.$store.getters.getCurrentIdentifiantUser,
            'tickets-date-nft-realise': status === 'NFT' ? new Date() : '',
            'tickets-status': status,
            'tickets-visite-cloture-valide': status === 'NFT' ? "1" : '0'
          });
        }).then(async res => {
          const request = indexedDB.open("ticketprop", 1);

          request.onsuccess = async (event) => {
            const db = event.target.result;

            for (let key in this.attachement) {
              if (this.attachement.hasOwnProperty(key)) {
                const element = this.attachement[key];

                await Promise.all([
                  this.storeImage(element.data, key, db, "images", element.content_type),
                  this.storeImage(element.data, key, db, "imagesToUpload", element.content_type)
                ])
              }
            }
            this.$router.push({name: 'mobile-station-list'})
          }
        })
      } else {
        axios.get(`${process.env.VUE_APP_API_BASE_URL}/tickets/${this.$route.params.ticketId}`)
            .then(async (res) => {
              if (res.data.interventions[0]) {
                if (res.data.interventions[0].photo1_pre_intervention) {
                  this.ticket.interventions[0]['photo1_pre_intervention'] = res.data.interventions[0].photo1_pre_intervention
                }

                if (res.data.interventions[0].photo2_pre_intervention) {
                  this.ticket.interventions[0]['photo2_pre_intervention'] = res.data.interventions[0].photo2_pre_intervention
                }
              }

              if (this.ticket.interventions[0].photo1_post_intervention instanceof File) {
                let formData = new FormData();
                formData.append("file", this.ticket.interventions[0].photo1_post_intervention);
                formData.append("type", this.ticket.interventions[0].photo1_post_intervention.type);
                let imageUpload = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/images`, formData)
                this.ticket.interventions[0]['photo1_post_intervention'] = imageUpload.data.file
              }

              if (this.ticket.interventions[0].photo2_post_intervention instanceof File) {
                let formData = new FormData();
                formData.append("file", this.ticket.interventions[0].photo2_post_intervention);
                formData.append("type", this.ticket.interventions[0].photo2_post_intervention.type);
                let imageUpload = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/images`, formData)
                this.ticket.interventions[0]['photo2_post_intervention'] = imageUpload.data.file
              }

              axios.put(`${process.env.VUE_APP_API_BASE_URL}/tickets/${this.$route.params.ticketId}`, {
                ...res.data,
                interventions: this.ticket.interventions,
                'tickets-visite-cloture-commentaire': this.ticket.visiteClotureCommentaire,
                'tickets-visite-cloture-agent': this.$store.getters.getCurrentIdentifiantUser,
                'tickets-date-nft-realise': status === 'NFT' ? new Date() : '',
                'tickets-status': status,
                'tickets-visite-cloture-valide': status === 'NFT' ? "1" : '0'
              })
                  .then((res) => {
                    this.$router.push({name: 'mobile-station-list'})
                  })
            })

      }
    }
  },
  mounted() {
    this.loadTicket(this.$route.params.ticketId)
  }
}

// Define a function to resize and convert the image to JPEG
function resizeAndConvertToJPEG(file, maxSizeInBytes, quality) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      const img = new Image();
      img.src = reader.result;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const maxSize = Math.sqrt(maxSizeInBytes);

        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxSize) {
            height *= maxSize / width;
            width = maxSize;
          }
        } else {
          if (height > maxSize) {
            width *= maxSize / height;
            height = maxSize;
          }
        }

        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const fileName = file.name;
            const fileType = 'image/jpeg'; // You can change this if needed
            const modifiedFile = new File([blob], fileName, { type: fileType });
            resolve(modifiedFile);
          },
          'image/jpeg',
          quality
        );
      };

      img.onerror = (error) => {
        reject(error);
      };
    };

    reader.onerror = (error) => {
      reject(error);
    };
  });
}
</script>

<style>

.btn-edit-ticket-mobile-ok, .btn-edit-ticket-mobile-cancel {
  width: 50%;
  height: 80px;
  border-radius: 10px;
  color: #fff;
}

.btn-edit-ticket-mobile-ok {
  background-color: #01DC05FF;
}

.btn-edit-ticket-mobile-cancel {
  background-color: var(--couleur-texte);
}

.ticket-item {
  border-radius: 10px;
  border: 2px solid var(--brown-grey);
  padding: 10px;
}
.ticket-item-label {
  color: var(--brown-grey);
  margin-bottom: 2px;
}
.ticket-item-value {
  border-radius: 5px !important;
  border: 2px solid var(--brown-grey) !important;
  padding: 4px !important;
  font-size: 14px !important;
}

.ticket-item-image {
  border-radius: 10px;
}

.btn-grey {
  display: block;
  border-radius: 3px;
  background-color: var(--stif-noir);
  border: none;
  cursor: pointer;
  line-height: 1;
  font-size: 18px;
  padding: 7px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
}
</style>
