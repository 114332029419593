<template>
  <b-container fluid>
    <b-row v-if="loading">
      <b-col class="text-center">
        <font-awesome-icon icon="spinner" size="2x" spin />
      </b-col>
    </b-row>
    <div class="d-flex w-100 ticket-list-container">
      <div class="min-w-100" :key="ticket.id" v-for="ticket in datas">
        <div class="ticket-item mr-3">
            <b-row>
                <b-col>
                    <p>{{ ticket.datePassageEnCours }}</p>
                </b-col>
                <b-col class="text-right">
                    <span @click="$router.push({name: 'mobile-ticket-edit', params: {ticketId: ticket._id}})" class="w-100 btn-orange">Editer</span>
                </b-col>
            </b-row>
          <b-row class="mb-2">
            <b-col>
              <div class="d-flex flex-column">
                <p class="mb-0 ticket-item-label">Localisation</p>
                <span class="ticket-item-value">{{ ticket.interventions[0].localisation }}</span>
              </div>
            </b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col>
              <div class="d-flex flex-column">
                <p class="mb-0 ticket-item-label">Détails</p>
                <span class="ticket-item-value">{{ ticket.interventions[0].detail }}
                </span>
              </div>
            </b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col>
              <img v-if="ticket.photosAvant[0]" @click="openImage(ticket.photosAvant[0].content)" class="img-fluid ticket-item-image" :src="ticket.photosAvant[0].content" width="100%" alt="">
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <b-row v-if="datas.length === 0 && !loading">
      <b-col>
        <p class="text-center">Pas de ticket pour cette station</p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Cookies from "js-cookie";
import {DB} from "@/db/db";
import moment from "moment/moment";
import axios from "axios";
import qs from "query-string";
import mixin from "@/mixin";

export default {
  name: "MobileListTicket",
  mixins: [mixin],
  data() {
    return {
      datas: [],
      ticketFilter: {
        station: this.$route.query.stationValue,
        status: 'En cours',
        'tickets-intervenant': this.$store.state.currentUser.identifiant
      },
      loading: false,
    }
  },
  methods: {
    async searchTicket() {
      this.loading = true
      if (this.isInStandaloneMode) {
        console.debug('searchTicket begging')
        let selector = {type: 'ticket'};
        let usersPAME = []
        if (Cookies.get('isPAME')) {
          usersPAME = await DB.find({
            selector: {type: 'user', groupname: {$eq: 'AGENT_PAME'}},
            fields: ['_id', 'identifiant', 'firstname', 'lastname'],
          })

          const usersPAMEIdentifiant = usersPAME.docs.map(user => user.identifiant)
          selector['tickets-intervenant'] = {$in: usersPAMEIdentifiant};
        }

        selector['tickets-intervenant'] = {$eq: this.$store.state.currentUser.identifiant};

        if (this.$store.getters.userIsPrestataireRatp || this.$store.getters.userIsPrestataireHorsRatp) {
          selector['tickets-intervenant'] = {$eq: this.$store.state.currentUser.identifiant};
        }

        if (this.ticketFilter.station) {
          selector['tickets-station'] = {$eq: this.ticketFilter.station};
        }

        if (this.ticketFilter.status) {
          selector['tickets-status'] = {$eq: this.ticketFilter.status}
        }

        console.debug('start search ticket with selector', selector)
        DB.allDocs({
          startkey: 'ticket_',
          include_docs: true,
        }).then(res => {
          this.datas = []
          this.datas.push(...res.rows.filter(item => {
            if (!item.id.includes('ticket_')) {
              return false
            }

            if (this.$store.getters.userIsAgentPame) {
              const usersPAMEIdentifiant = usersPAME.docs.map(user => user.identifiant)
              if (!usersPAMEIdentifiant.includes(item.doc['tickets-intervenant'])) {
                return false
              }
            }

            if (this.$store.getters.userIsPrestataireRatp || this.$store.getters.userIsPrestataireHorsRatp) {
              if (item.doc['tickets-intervenant'] !== this.$store.state.currentUser.identifiant) {
                return false
              }
            }

            if (this.ticketFilter.station) {
              if (item.doc['tickets-station'] !== this.ticketFilter.station) {
                return false
              }
            }

            if (this.ticketFilter.intervenant) {
              if (item.doc['tickets-intervenant'] !== this.ticketFilter.intervenant) {
                return false
              }
            }

            if (this.ticketFilter.status) {
              if (item.doc['tickets-status'] !== this.ticketFilter.status) {
                return false
              }
            }

            return true;
          }).slice(0, 10).map(item => {
            let ticket = item.doc
            return {
              dateCreation: ticket['date-creation'],
              datePassageEnCours: moment(ticket['tickets-intervenation-date-visa']).format('DD/MM/YY'),
              station: ticket['tickets-station'],
              idfm: ticket['tickets-item-idfm'],
              nature: ticket['tickets-nature-demande'],
              intervenant: ticket['tickets-intervenant'],
              sagai: ticket['tickets-n-sagai'],
              interventions: ticket['interventions'],
              _id: ticket._id,
              id: ticket.id ? ticket.id : null,
              status: ticket['tickets-status'],
              photosAvant: [],
              photosApres: [],
            }
          }))
          this.loading = false
          this.datas.forEach((ticket, index) => {
            this.loadTicket(ticket._id, index)
          })
        })
      } else {
        let queryParams = {}
        if (this.$store.getters.userIsAgentPame) {
          queryParams['tickets-intervenant'] = 'AGENT_PAME'
        }

        if (this.$store.getters.userIsPrestataireRatp || this.$store.getters.userIsPrestataireHorsRatp) {
          queryParams['tickets-intervenant'] = this.$store.state.currentUser.identifiant
        }

        if (this.ticketFilter.station) {
          queryParams['tickets-station'] = this.ticketFilter.station;
        }

        if (this.ticketFilter.intervenant) {
          queryParams['tickets-intervenant'] = this.ticketFilter.intervenant;
        }

        if (this.ticketFilter.status) {
          queryParams['tickets-status'] = this.ticketFilter.status
        }

        this.searchDesktopParams = queryParams;
        axios.get(`${process.env.VUE_APP_API_BASE_URL}/tickets?${qs.stringify(queryParams)}`)
            .then((res) => {
              this.datas = []
              this.datas.push(...res.data.slice(0, 10).map(ticket => {
                return {
                  dateCreation: ticket['date-creation'],
                  datePassageEnCours: moment(ticket['tickets-intervenation-date-visa']).format('DD/MM/YY'),
                  station: ticket['tickets-station'],
                  idfm: ticket['tickets-item-idfm'],
                  nature: ticket['tickets-nature-demande'],
                  intervenant: ticket['tickets-intervenant'],
                  sagai: ticket['tickets-n-sagai'],
                  interventions: ticket['interventions'],
                  _id: ticket._id,
                  id: ticket.id ? ticket.id : null,
                  status: ticket['tickets-status'],
                  photosAvant: [],
                  photosApres: [],
                }
              }))

              this.loading = false
              this.datas.forEach((ticket, index) => {
                this.loadTicket(ticket.id, index)
              })
            })
      }
    },
    openImage(img) {
      this.$store.dispatch('setImgFullScreen', img);
      this.$store.dispatch('toggleImgFullScreen');
    },
    loadTicket(ticketId, index) {
      if (this.isInStandaloneMode) {
        DB.get(ticketId, {attachments: true}).then(res => {
          const ticket = res
          const request = indexedDB.open("ticketprop", 1);

          const photosFields = [
            'photo1_pre_intervention',
            'photo2_pre_intervention',
            'photo1_post_intervention',
            'photo2_post_intervention',
          ]
          request.onsuccess = async (event) => {
            const db = event.target.result;

            ticket.interventions.forEach(async intervention => {
              if (intervention['photo1_pre_intervention']) {
                this.datas[index].photosAvant.push({
                  content:  await this.readImage(intervention['photo1_pre_intervention'], db)
                })
              }
            })
          }
        })
      } else {
        axios.get(`${process.env.VUE_APP_API_BASE_URL}/tickets/${ticketId}`)
            .then((res) => {
              res.data.interventions.forEach(intervention => {
                if (intervention['photo1_pre_intervention']) {
                  this.datas[index].photosAvant.push({
                    content: `${process.env.VUE_APP_IMAGE_BASE_URL}/${intervention['photo1_pre_intervention']}`
                  })
                }
                if (intervention['photo2_pre_intervention']) {
                  this.datas[index].photosAvant.push({
                    content: `${process.env.VUE_APP_IMAGE_BASE_URL}/${intervention['photo2_pre_intervention']}`
                  })
                }

                if (intervention['photo1_post_intervention']) {
                  this.datas[index].photosApres.push({
                    content: `${process.env.VUE_APP_IMAGE_BASE_URL}/${intervention['photo1_post_intervention']}`
                  })
                }
                if (intervention['photo2_post_intervention']) {
                  this.datas[index].photosApres.push({
                    content: `${process.env.VUE_APP_IMAGE_BASE_URL}/${intervention['photo2_post_intervention']}`
                  })
                }
              })
            })
      }
    },
  },
  mounted() {
    this.searchTicket()
  }
}
</script>

<style scoped>
.min-w-100 {
  min-width: 100%;
}

.ticket-list-container {
  overflow-x: scroll;
  overflow-y: hidden;
}

.ticket-item {
  border-radius: 10px;
  border: 2px solid var(--brown-grey);
  padding: 10px;
}
.ticket-item-label {
  color: var(--brown-grey);
}
.ticket-item-value {
  border-radius: 5px;
  border: 2px solid var(--brown-grey);
  padding: 4px;
}

.ticket-item-image {
  border-radius: 10px;
}

.btn-orange {
  display: block;
  border-radius: 3px;
  background-image: linear-gradient(99deg, var(--orange) 0%, var(--rouge) 98%);
  border: none;
  cursor: pointer;
  line-height: 1;
  font-size: 18px;
  padding: 7px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
}
</style>
