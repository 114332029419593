<template>
  <b-card no-body>
    <b-card-header class="ticket-summary-card-header d-flex justify-content-between">
      <div v-if="isMobile">
        <span class="mr-2">{{ ticket['tickets-origine'] }} | {{ dateCreation }}</span>
        <router-link tag="span" class="btn-editer-ticket" :to="{name: 'tickets-update', params: {id: ticketId}}">Editer le ticket</router-link>
      </div>
      <div v-else>
        <span class="mr-2">{{ ticket['tickets-origine'] }} | {{ dateCreation }}</span>
        <a :href="ticketUpdateUrl" target="_blank" class="btn-editer-ticket">Editer le ticket</a>
      </div>
      <div @click="closeEvent" class="text-right">
        <font-awesome-icon icon="xmark" />
      </div>
    </b-card-header>
    <b-card-body class="ticket-summary-card-body">
      <b-row class="ticket-summary-title">
        <b-col>
          <h1>Identifiant</h1>
        </b-col>
      </b-row>
      <div class="bg-blue mb-3">
        <b-row>
          <b-col>
            <span class="ticket-summary-station-title">{{ ticket['tickets-station'] }}</span>
          </b-col>
          <b-col sm="5">
            <ticket-status summary :ticket-status="ticket['tickets-status']" />
          </b-col>
        </b-row>
        <b-row class="mb-2 mt-1">
          <b-col>
            <div class="border-bottom w-100"></div>
          </b-col>
        </b-row>
        <div class="container-item-idfm mb-2">
          <b-row>
            <b-col>
              <span class="ticket-summary-nature">{{ ticket['tickets-nature-demande'] }}</span>
            </b-col>
            <b-col class="text-right">
              <span class="ticket-summary-nature mr-2 text-right">{{ getIdfmCategorie }}</span>
              <span class="ticket-summary-penalite">{{ getPenalite }}</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <span class="ticket-summary-item-idfm mb-1">{{ ticket['tickets-item-idfm'] }}</span>
            </b-col>
          </b-row>
        </div>

        <b-row class="mb-2">
          <b-col>
            <span class="ticket-summary-identifiant-title">Intervenant : </span>
            <span class="ticket-summary-identifiant-value">{{ ticket['tickets-intervenant'] }}</span>
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col>
            <span class="ticket-summary-identifiant-title">Localisation : </span><span class="ticket-summary-identifiant-value">{{ ticket.interventions && ticket.interventions[0] && ticket.interventions[0].localisation }}</span>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col>
            <span class="ticket-summary-identifiant-title">Détail intervention : </span>
            <span class="ticket-summary-identifiant-value">{{ ticket.interventions && ticket.interventions[0] && ticket.interventions[0].detail }}</span>
          </b-col>
        </b-row>
      </div>

      <b-row class="ticket-summary-title mb-1">
        <b-col>
          <h1>SAGAI</h1>
        </b-col>
      </b-row>

      <div class="bg-blue sagai-container mb-3">
        <b-row>
          <b-col>
            <span class="ticket-summary-identifiant-value sagai">{{ ticket['tickets-n-sagai'] }}</span>
          </b-col>
          <b-col>
            <span class="ticket-summary-identifiant-value sagai">{{ dateSagai }}</span>
          </b-col>
        </b-row>
        <b-row class="mb-2 mt-1">
          <b-col>
            <div class="border-bottom w-100"></div>
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col>
            <span class="ticket-summary-identifiant-title">Code Mire : </span>
            <span class="ticket-summary-identifiant-value">{{ ticket['tickets-code-mire-lieu'] }}</span>
          </b-col>
          <b-col>
            <span class="ticket-summary-identifiant-title">N°OT : </span>
            <span class="ticket-summary-identifiant-value">{{ ticket['tickets-n-ot'] }}</span>
          </b-col>
        </b-row>
      </div>

      <b-row class="ticket-summary-title mb-1">
        <b-col>
          <h1>Prestation HF</h1>
        </b-col>
      </b-row>

      <div class="bg-blue prestationhf-container mb-3">
        <b-row>
          <b-col>
            <span class="ticket-summary-identifiant-value prestationhf">{{ ticket.interventions && ticket.interventions[0] && ticket.interventions[0].prestation_hf }}</span>
          </b-col>
        </b-row>
        <b-row class="mb-2 mt-1">
          <b-col>
            <div class="border-bottom w-100"></div>
          </b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col>
            <span class="ticket-summary-identifiant-title">Surface (m2) : </span>
            <span class="ticket-summary-identifiant-value">{{ ticket.interventions && ticket.interventions[0] && ticket.interventions[0].surface }}</span>
          </b-col>
          <b-col>
            <span class="ticket-summary-identifiant-title">Hauteur (m) : </span>
            <span class="ticket-summary-identifiant-value">{{ ticket.interventions && ticket.interventions[0] && ticket.interventions[0].hauteur }}</span>
          </b-col>
        </b-row>
      </div>

      <b-row class="ticket-summary-title mb-1">
        <b-col>
          <h1>VISUELS AVANT</h1>
        </b-col>
      </b-row>

      <b-row class="mb-3">
        <b-col>
          <div class="d-flex overflow-scrolling-touch overflow-auto">
            <b-img @click="openImage(photo.content)" v-for="photo in photosAvant" class="mr-2 img-placeholder-sumary" :src="photo.content"></b-img>
          </div>
        </b-col>
      </b-row>

      <b-row class="ticket-summary-title mb-1">
        <b-col>
          <h1>VISUELS APRÈS</h1>
        </b-col>
      </b-row>

      <b-row class="mb-3">
        <b-col>
          <div class="d-flex overflow-scrolling-touch overflow-auto">
            <b-img @click="openImage(photo.content)" v-for="photo in photosApres" class="mr-2 img-placeholder-sumary" :src="photo.content"></b-img>
          </div>
        </b-col>
      </b-row>

      <!--
      <b-row class="hide-mobile">
        <b-col class="text-right">
          <b-button class="btn-editer-ticket-desktop" :to="{name: 'tickets-update', params: {id: ticketId}}">Editer le ticket</b-button>
        </b-col>
      </b-row>
      -->
    </b-card-body>
  </b-card>
</template>

<script>
import TicketStatus from "@/components/TicketStatus";
import {DB} from '@/db/db';
import mixin from "@/mixin";
import moment from "moment";
import itemIDFM from "@/db/item_idfm.json";
import axios from "axios";
import imgPlaceholder from '@/assets/place-holder.png'
export default {
  name: "DemandeSumary",
  components: {
    TicketStatus
  },
  mixin: [mixin],
  data() {
    return {
      ticket: {
        'tickets-status': ''
      },
      photosAvant: [],
      photosApres: [],
      ticketIdentifiant: null,
      imgPlaceholder
    }
  },
  mixins: [mixin],
  watch: {
    ticketId : {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.ticketIdentifiant = newVal
          this.loadTicket()
        }
      }
    }
  },
  methods: {
    closeEvent() {
      this.$emit('close')
    },
    openImage(img) {
      this.$store.dispatch('setImgFullScreen', img);
      this.$store.dispatch('toggleImgFullScreen');
    },
    loadTicket() {
      this.ticket = {
        'tickets-status': ''
      }
      this.photosApres = [];
      this.photosAvant = [];

      if (this.isInStandaloneMode) {
        DB.get(this.ticketId).then(res => {
          this.ticket = res
          const request = indexedDB.open("ticketprop", 1);

          const photosFields = [
              'photo1_pre_intervention',
              'photo2_pre_intervention',
              'photo1_post_intervention',
              'photo2_post_intervention',
          ]
          request.onsuccess = async (event) => {
            const db = event.target.result;
            this.ticket.interventions.forEach(async intervention => {
              photosFields.forEach(async key => {
                if (key.includes('pre') && intervention[key]) {
                  this.photosAvant.push({
                    content:  await this.readImage(intervention[key], db)
                  })
                } else if (key.includes('post') && intervention[key]) {
                  this.photosApres.push({
                    content:  await this.readImage(intervention[key], db)
                  })
                }
              })
            })
          }
        })
      } else {
        axios.get(`${process.env.VUE_APP_API_BASE_URL}/tickets/${this.ticketId}`)
            .then((res) => {
              this.ticket = res.data
              res.data.interventions.forEach(intervention => {
                if (intervention['photo1_pre_intervention']) {
                  this.photosAvant.push({
                    content: `${process.env.VUE_APP_IMAGE_BASE_URL}/${intervention['photo1_pre_intervention']}`
                  })
                }
                if (intervention['photo2_pre_intervention']) {
                  this.photosAvant.push({
                    content: `${process.env.VUE_APP_IMAGE_BASE_URL}/${intervention['photo2_pre_intervention']}`
                  })
                }

                if (intervention['photo1_post_intervention']) {
                  this.photosApres.push({
                    content: `${process.env.VUE_APP_IMAGE_BASE_URL}/${intervention['photo1_post_intervention']}`
                  })
                }
                if (intervention['photo2_post_intervention']) {
                  this.photosApres.push({
                    content: `${process.env.VUE_APP_IMAGE_BASE_URL}/${intervention['photo2_post_intervention']}`
                  })
                }
              })
            })
      }
    },
  },
  mounted() {
    this.loadTicket()
  },
  props: {
    ticketId: {
      required: true,
      type: String
    }
  },
  computed: {
    isMobile() {
      return Math.min(window.screen.width, window.screen.height) < 768 || navigator.userAgent.indexOf("Mobi") > -1;
    },
    ticketUpdateUrl() {
      return this.$router.resolve({ name: 'tickets-update', params: { id: this.ticketId } }).href;
    },
    dateCreation() {
      if (this.ticket['date-creation']) {
        return moment(this.ticket['date-creation']).format('DD/MM/YY')
      }
      return '';
    },
    getPenalite() {
      if (this.ticket['tickets-item-idfm']) {
        return itemIDFM.find(item => {
          return item.item === this.ticket['tickets-item-idfm']
        }).penalite
      } else {
        return ''
      }
    },
    getIdfmCategorie() {
      if (this.ticket['tickets-item-idfm']) {
        return itemIDFM.find(item => {
          return item.item === this.ticket['tickets-item-idfm']
        }).categorie.toLowerCase()
      } else {
        return ''
      }
    },

    dateSagai() {
      if (this.ticket['tickets-date-sagai']) {
        return moment(this.ticket['tickets-date-sagai']).format('DD/MM/YYYY')
      }
      return ''
    },
    getTicketId() {
      if (this.ticket['id']) {
        return this.ticket['id'];
      } else {
        return 'Attente synchro'
      }
    }
  }
}
</script>

<style scoped>
.ticket-summary-card-header {
  background-image: linear-gradient(to right, var(--vert), var(--bleu));
  height: 32px;
  padding: 8px;
  color: #FFFFFF;
}

.ticket-summary-card-header span {
  font-size: 12px;
}
.ticket-summary-card-body {
  padding: 10px;
}

.ticket-summary-title h1 {
  text-transform: uppercase;
  color: var(--bleu);
  font-size: 14px;
  margin-bottom: 0;
}
.ticket-summary-station-title {
  font-size: 14px;
  font-weight: bold;
  color: var(--blanc);
}

.ticket-summary-station-title-item-idfm {
  font-size: 12px;
  font-weight: bold;
  color: var(--couleur-texte);
}

.ticket-summary-penalite {
  font-weight: bold;
  font-size: 14px;
  color: var(--rouge);
}
.ticket-summary-item-idfm {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--couleur-texte);
}
.ticket-summary-nature {
  font-size: 12px;
  font-weight: bold;
  color: var(--couleur-texte);
}
.ticket-summary-identifiant-title {
  font-size: 12px;
  font-weight: 300;
  letter-spacing: normal;
  color: var(--blanc);
  margin-bottom: 0;
}

.ticket-summary-identifiant-value {
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--blanc);
}

.sagai-container .sagai {
  font-size: 14px;
}

.prestationhf-container .prestationhf {
  font-size: 14px;
}

.btn-editer-ticket-desktop {
  border-radius: 3px;
  background-image: linear-gradient(99deg, var(--orange) 0%, var(--rouge) 98%);
  border: none;
  cursor: pointer;
}

.btn-editer-ticket {
  border-radius: 3px;
  background-image: linear-gradient(99deg, var(--orange) 0%, var(--rouge) 98%);
  border: none;
  cursor: pointer;
  color: #FFFFFF;
  line-height: 1;
  font-size: 12px;
  padding: 5px;
}

.img-placeholder-sumary {
  height: 88px;
}

.bg-blue {
  background-color: var(--bleu);
  padding: 8px;
}
.border-bottom {
  border-bottom: solid 1px var(--blanc);
}

.container-item-idfm {
  border-radius: 3px;
  background-color: var(--blanc);
  padding: 7px;
}

.show-mobile {
  display: none;
}

@media (max-width: 768px) {
  .hide-mobile {
    display: none;
  }
  .show-mobile {
    display: inline-block;
  }
}
</style>
