import moment from "moment";
import {DB, getCustomfieldBySlug} from "@/db/db";
import axios from "axios";
import itemIDFM from "@/db/item_idfm.json";
import Cookies from "js-cookie";
const mixin = {
    computed: {
        isInStandaloneMode() {
            return (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://');
        },
        isOnline() {
            return window.navigator.onLine
        }
    },
    methods: {
        formatDate(date) {
            return moment(date).format('DD/MM/YY')
        },
        async checkOnlineStatus() {
            try {
                const online = await fetch("https://api.ipify.org?format=json");
                return online.status >= 200 && online.status < 300; // either true or false
            } catch (err) {
                return false; // definitely offline
            }
        },
        getProfilLabel(profil) {
            let val = this.userProfils.find(item => item.value === profil)
            if (val) {
                return val.text
            } else {
                return profil
            }
        },
        loadStation() {
            let slug = 'tickets-station';
            if (this.isInStandaloneMode) {
                getCustomfieldBySlug(slug).then((res) => {
                    this.stations.push(...res.docs[0].option.map((item) => {
                        return { value: item, text: item }
                    }))
                })
            } else {
                axios.get(`${process.env.VUE_APP_API_BASE_URL}/customField/${slug}/option`).then(res => {
                    this.stations.push(...res.data.map((item) => {
                        return { value: item, text: item }
                    }))
                })
            }
        },
        loadNatureDemande() {
            let slug = 'tickets-nature-demande'
            if (this.isInStandaloneMode) {
                getCustomfieldBySlug(slug).then((res) => {
                    this.natureDemande.push(...res.docs[0].option.map((item) => {
                        return { value: item, text: item }
                    }))
                })
            } else {
                axios.get(`${process.env.VUE_APP_API_BASE_URL}/customField/${slug}/option`).then(res => {
                    this.natureDemande.push(...res.data.map((item) => {
                        return { value: item, text: item }
                    }))
                })
            }
        },
        loadItemIdfm() {
            this.itemIdfm.push(...itemIDFM.map(item => {
                return { value: item.item, text: item.item }
            }))
        },
        loadCategorieIdfm() {
            itemIDFM.forEach(item => {
                const exists = this.categorieIdfm.some(categorie => categorie.value === item.categorie);
                if (!exists) {
                    this.categorieIdfm.push({ value: item.categorie, text: item.categorie });
                }
            });
        },
        loadPrestationHf() {
            let slug = 'tickets-prestation-hf';
            if (this.isInStandaloneMode) {
                getCustomfieldBySlug(slug).then((res) => {
                    this.prestationshf.push(...res.docs[0].option.map((item) => {
                        let data = JSON.parse(Buffer.from(item, 'base64').toString('utf-8'))
                        return { ...data, value: data.value, text: data.value, }
                    }))
                })
            } else {
                axios.get(`${process.env.VUE_APP_API_BASE_URL}/customField/${slug}/option`).then(res => {
                    this.prestationshf.push(...res.data.map((item) => {
                        let data = JSON.parse(Buffer.from(item, 'base64').toString('utf-8'))
                        return { ...data, value: data.value, text: data.value }
                    }))
                })
            }
        },
        loadIntervenant() {
            let slug = 'tickets-intervenant';
            if (this.isInStandaloneMode) {
                getCustomfieldBySlug(slug).then((res) => {
                    this.intervenants.push(...res.docs[0].option.map((item) => {
                        return { value: item, text: item }
                    }))
                })
            } else {
                axios.get(`${process.env.VUE_APP_API_BASE_URL}/customField/${slug}/option`).then(res => {
                    this.intervenants.push(...res.data.map((item) => {
                        return { value: item, text: item }
                    }))
                })
            }
        },
        loadIntervenantTicket() {
            if (this.isInStandaloneMode) {
                DB.find({
                    selector: {type: 'user', groupname: {$in: ['AGENT_PAME', 'PRESTATAIRE_RATP', 'PRESTATAIRE_HORS_RATP']}},
                    fields: ['_id', 'firstname', 'lastname', 'identifiant', 'groupname'],
                }).then((res) => {
                    this.intervenants.push(...res.docs.map((item) => {
                        return { value: item.identifiant, text: `${item.identifiant} (${item.firstname} ${item.lastname})` }
                    }))
                })
            } else {
                axios.get(`${process.env.VUE_APP_API_BASE_URL}/users/groupname/AGENT_PAME`).then(res => {
                    this.intervenants.push(...res.data.map((item) => {
                        return { value: item.identifiant, text: `${item.identifiant} (${item.firstname} ${item.lastname})` }
                    }))
                })
                axios.get(`${process.env.VUE_APP_API_BASE_URL}/users/groupname/PRESTATAIRE_RATP`).then(res => {
                    this.intervenants.push(...res.data.map((item) => {
                        return { value: item.identifiant, text: `${item.identifiant} (${item.firstname} ${item.lastname})` }
                    }))
                })
                axios.get(`${process.env.VUE_APP_API_BASE_URL}/users/groupname/PRESTATAIRE_HORS_RATP`).then(res => {
                    this.intervenants.push(...res.data.map((item) => {
                        return { value: item.identifiant, text: `${item.identifiant} (${item.firstname} ${item.lastname})` }
                    }))
                })
            }
        },
        loadStatus() {
            let slug = 'tickets-status';
            if (this.isInStandaloneMode) {
                getCustomfieldBySlug(slug).then((res) => {
                    this.status.push(...res.docs[0].option.map((item) => {
                        return { value: item, text: item }
                    }))
                })
            } else {
                axios.get(`${process.env.VUE_APP_API_BASE_URL}/customField/${slug}/option`).then(res => {
                    this.status.push(...res.data.map((item) => {
                        return { value: item, text: item }
                    }))
                })
            }
        },
        storeImage(imageResponse, imageKey, db, storeObject = "images", type = 'image/png') {
            return new Promise((resolve, reject) => {
                const blob = new Blob([imageResponse], {type: type});

                const transaction = db.transaction([storeObject], "readwrite");
                const objectStore = transaction.objectStore(storeObject);
                const request = objectStore.add(blob, imageKey);
                request.onsuccess = (event) => {
                    console.info(`Image ${imageKey} added into ${storeObject}`, )
                    resolve();
                };
                request.onerror = (event) => {
                    console.warn(`Error while adding image ${imageKey} into ${storeObject}`, event)
                    reject();
                };
            })
        },
        readImage(imageKey, db, storeObject = "images") {
            console.debug(`Reading image ${imageKey} from ${storeObject}`)
            return new Promise((resolve, reject) => {
                const transaction = db.transaction([storeObject], "readonly");
                const objectStore = transaction.objectStore(storeObject);
                const request = objectStore.get(imageKey);
                request.onsuccess = async (event) => {
                    let blob = request.result;
                    if (!blob) {
                        resolve(`${process.env.VUE_APP_IMAGE_BASE_URL}/${imageKey}`);
                        return;
                    }
                    let reader = new FileReader();
                    reader.onload = () => {
                        resolve(reader.result);
                    }
                    reader.onerror = (event) => {
                        reject();
                    }
                    reader.readAsText(blob);
                };
                request.onerror = (event) => {
                    reject();
                };
            })
        },
        blobToBase64(blob) {
            return new Promise((resolve, _) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.readAsDataURL(blob);
            });
        }
    },
    data() {
        return {
            internetOk: null,
            stations: [
                { value: '', text: 'Sélectionner une station' }
            ],

            intervenants: [
                { value: '', text: 'Intervenant' },
            ],

            prestationshf: [
                { value: '', text: 'Prestation HF' },
            ],

            natureDemande: [
                { value: '', text: 'Nature demande' },
            ],
            itemIdfm: [
                { value: '', text: 'Item IDFM' },
            ],
            categorieIdfm: [
                { value: '', text: 'Catégorie IDFM' },
            ],
            status: [
                { value: '', text: 'Sélectionner un statut' },
            ],


            userProfils: [
                { value: null, text: 'Choisir un profil' },
                { value: 'ADMIN', text: 'Admin' },
                { value: 'CONSULTANT', text: 'Consultant' },
                { value: 'AGENT_STATION', text: 'Agent station' },
                { value: 'AGENT_PROPRETE', text: 'Agent propreté' },
                { value: 'AGENT_PAME', text: 'Agent PAME' },
                { value: 'COORDINATEUR_IDFM', text: 'Coordinateur IDFM' },
                { value: 'AGENT_TECHNIQUE', text: 'Agent technique' },
                { value: 'MANAGER', text: 'Manager' },
                { value: 'PRESTATAIRE_RATP', text: 'Prestataire RATP' },
                { value: 'PRESTATAIRE_HORS_RATP', text: 'Prestataire Hors RATP' },
            ],
        }
    }
}

export default mixin
