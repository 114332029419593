<template>
  <b-container fluid>
    <b-tabs content-class="mt-3" v-model="index">
      <b-tab v-for="(field) in configFields" :key="field.value" :title="field.title">
        <b-button class="mb-3" variant="primary" @click="addValue(field)">Ajouter</b-button>
        <b-table table-class="w-100" thead-class="table-head" :fields="tableHeader" per-page="1000" :items="itemProvider" :id="`table-${field.value}`" :ref="`table-${field.value}`" :api-url="field.value">
          <template #cell(action)="data">
            <font-awesome-icon @click="editValue(field, data.item)" icon="pen" />
          </template>

          <template #cell(value)="data">
            {{ data.value }}
          </template>
        </b-table>
      </b-tab>
    </b-tabs>
    <b-modal @ok="doAddValue" @cancel="clearModalForm" @close="clearModalForm" id="addvalue" title="Ajout d'une valeur à la liste personnalisée" ok-title="Ajouter" cancel-title="Annuler">
      <b-form-group
          id="input-addvalue-label"
          label="Valeur"
          label-for="input-addvalue"
          label-class="form-label"
      >
        <b-form-input
            id="input-addvalue"
            v-model="modaladdvalue.value"
            type="text"
            placeholder=""
            class="ticket-form-control"
        ></b-form-input>
      </b-form-group>

      <b-form-group
          id="input-budgetannuel-label"
          label="Budget annuel"
          label-for="input-budgetannuel"
          label-class="form-label"
          v-if="index === 2"
      >
        <b-form-input
            id="input-budgetannuel"
            v-model="modaladdvalue.budgetannuel"
            type="text"
            placeholder=""
            class="ticket-form-control"
        ></b-form-input>
      </b-form-group>

      <b-form-group
          id="input-financement-label"
          label="Financement"
          label-for="input-financement"
          label-class="form-label"
          v-if="index === 2"
      >
        <b-form-input
            id="input-financement"
            v-model="modaladdvalue.financement"
            type="text"
            placeholder=""
            class="ticket-form-control"
        ></b-form-input>
      </b-form-group>
    </b-modal>
    <b-modal @ok="doEditValue" @cancel="clearModalForm"  @close="clearModalForm" id="editvalue" title="Edition d'une valeur personnalisée" ok-title="Modification" cancel-title="Annuler">
      <b-form-group
          id="input-editvalue-label"
          label="Valeur"
          label-for="input-editvalue"
          label-class="form-label"
      >
        <b-form-input
            id="input-editvalue"
            v-model="modaladdvalue.value"
            type="text"
            placeholder=""
            class="ticket-form-control"
        ></b-form-input>
      </b-form-group>

      <b-form-group
          id="input-budgetannuel-label"
          label="Budget annuel"
          label-for="input-budgetannuel"
          label-class="form-label"
          v-if="index === 2"
      >
        <b-form-input
            id="input-budgetannuel"
            v-model="modaladdvalue.budgetannuel"
            type="text"
            placeholder=""
            class="ticket-form-control"
        ></b-form-input>
      </b-form-group>

      <b-form-group
          id="input-financement-label"
          label="Financement"
          label-for="input-financement"
          label-class="form-label"
          v-if="index === 2"
      >
        <b-form-input
            id="input-financement"
            v-model="modaladdvalue.financement"
            type="text"
            placeholder=""
            class="ticket-form-control"
        ></b-form-input>
      </b-form-group>
    </b-modal>
  </b-container>
</template>

<script>
import axios from "axios";
export default {
  name: "AttributeConfig",
  data() {
    return {
      index: 0,
      rows: [
          [],
          [],
          []
      ],
      modaladdvalue: {
        field: null,
        value: null,
        oldvalue: null,
        budgetannuel: null,
        financement: null
      },
      configFields: [
        {
          title: 'Station',
          value: 'tickets-station'
        },
        {
          title: 'Nature demande',
          value: 'tickets-nature-demande'
        },
        {
          title: 'Prestation HF',
          value: 'tickets-prestation-hf'
        }
      ]
    }
  },
  mounted() {
  },
  computed: {
    tableHeader() {
      if (this.index === 2) {
        return [
          { key: 'value', label: 'Valeur', thClass: 'table-th-custom', tdClass: 'table-td-custom' },
          { key: 'budgetannuel', label: 'Budget annuel', thClass: 'table-th-custom', tdClass: 'table-td-custom' },
          { key: 'financement', label: 'Financement', thClass: 'table-th-custom', tdClass: 'table-td-custom' },
          { key: 'action', label: 'Action', thClass: 'table-th-custom', tdClass: 'table-td-custom' }
        ]
      } else {
        return [
          { key: 'value', label: 'Valeur', thClass: 'table-th-custom', tdClass: 'table-td-custom' },
          { key: 'action', label: 'Action', thClass: 'table-th-custom', tdClass: 'table-td-custom' }
        ]
      }

    }
  },
  methods: {
    itemProvider(ctx, callback) {
      axios.get(`${process.env.VUE_APP_API_BASE_URL}/customField/${ctx.apiUrl}/option`).then(res => {
        callback([...res.data.map((item) => {
          if (item.substring(0, 3) === 'eyJ') {
            const buf = Buffer.from(item, 'base64')
            return {...JSON.parse(buf.toString('utf8'))}
          } else {
            return { value: item }
          }
        })])
      })
    },
    addValue(field) {
      this.modaladdvalue.field = field.value
      this.$bvModal.show('addvalue')
    },
    doAddValue() {
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/customField/${this.modaladdvalue.field}/add`, this.buildValue()).then(res => {
        let ref = `table-${this.modaladdvalue.field}`;
        this.$root.$emit('bv::refresh::table', ref)
        this.clearModalForm()
      })
    },
    editValue(field, oldValue) {
      this.modaladdvalue.field = field.value
      if (this.index === 2) {
        this.modaladdvalue.value = oldValue.value
        this.modaladdvalue.budgetannuel = oldValue.budgetannuel
        this.modaladdvalue.financement = oldValue.financement
        this.modaladdvalue.oldvalue = Buffer.from(JSON.stringify(oldValue)).toString('base64')
      } else {
        this.modaladdvalue.value = oldValue.value
        this.modaladdvalue.oldvalue = oldValue.value
      }

      this.$bvModal.show('editvalue')
    },
    buildValue() {
      if (this.index === 2) {
        return {
          value: Buffer.from(JSON.stringify({
            value: this.modaladdvalue.value,
            budgetannuel: this.modaladdvalue.budgetannuel,
            financement: this.modaladdvalue.financement
          }), 'utf8').toString('base64'),
          oldValue: this.modaladdvalue.oldvalue
        }
      } else {
        return {
          value: this.modaladdvalue.value,
          oldValue: this.modaladdvalue.oldvalue
        }
      }
    },
    doEditValue() {
      axios.put(`${process.env.VUE_APP_API_BASE_URL}/customField/${this.modaladdvalue.field}/edit`, this.buildValue()).then(res => {
        let ref = `table-${this.modaladdvalue.field}`;
        this.$root.$emit('bv::refresh::table', ref)
        this.clearModalForm()
      })
    },
    clearModalForm() {
      this.modaladdvalue.field = null;
      this.modaladdvalue.value = null;
      this.modaladdvalue.oldvalue = null;
      this.modaladdvalue.budgetannuel = null;
      this.modaladdvalue.financement = null;
    }
  }
}
</script>

<style>

</style>
